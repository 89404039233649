const trainingsKeysDTO = {
  ID: "id",
  TITLE: "title",
  DESCRIPTION: "description",
  DATE_ASSIGNED: "dateAssigned",
  DATE_COMPLETED: "dateCompleted",
  STATUS: "status",
  REMINDER_SENT: "reminderSent",
  LEARNERS: "learners",
  LEARNERS_REGISTERED: "learnersRegistered",
  LEARNERS_COMPLETED: "learnersCompleted",
  ACTION: "action",
  DETAILS: "details"
};

const trainingsKeysAPIDTO = {
  ID: "id",
  COURSE_ID: "course_id",
  NAME: "name",
  DESCRIPTION: "description",
  ASSIGNED_AT: "assigned_at",
  DATE_COMPLETED: "date_completed",
  STATUS: "status",
  REMINDER_SENT: "reminder_sent",
  LEARNERS: "learners",
  LEARNERS_REGISTERED: "learners_registered",
  LEARNERS_COMPLETED: "learners_completed",
  THIRD_PARTY_ID: "third_party_id",
  INCLUDE_SUMMARY: "include_summary"
};

const trainingLearnersKeysDTO = {
  ID: "id",
  NAME: "name",
  EMAIL: "email",
  JOB_TITLE: "jobTitle",
  DATE_ASSIGNED: "dateAssigned",
  DATE_COMPLETED: "dateCompleted",
  STATUS: "status",
  REMINDER_SENT: "reminderSent",
  FIRST_NAME: "firstName",
  LAST_NAME: "lastName",
  LANGUAGE: "language",
  THIRD_PARTY_ID: "thirdPartyId",
  SELECT: "select"
};

const trainingLearnersKeysAPIDTO = {
  ID: "id",
  NAME: "name",
  EMAIL: "email",
  JOB_TITLE: "job_title",
  DATE_ASSIGNED: "date_assigned",
  DATE_COMPLETED: "date_completed",
  STATUS: "status",
  REMINDER_SENT: "reminder_sent",
  FIRST_NAME: "first_name",
  LAST_NAME: "last_name",
  PREFERRED_LANGUAGE: "preferred_language",
  EMAIL_ADDRESS: "email_address",
  THIRD_PARTY_COURSE_ID: "third_party_course_id",
  THIRD_PARTY_ID: "third_party_id"
};

const trainingWidgetTypes = {
  TRAINING: "training",
  PRODUCT_KPI: "Product_KPI"
};

const trainingWidgetKeys = {
  TOTAL_COURSE_REGISTRATION_COUNT: "total_course_registration_count"
};

const trainingChartKeysAPIDTO = {
  TOTAL: "total",
  TOTAL_COUNT: "total_count",
  STATUS: "status",
  STATUSES: "statuses",
  COUNT: "count",
  LABEL: "label"
};

export {
  trainingsKeysDTO,
  trainingsKeysAPIDTO,
  trainingLearnersKeysDTO,
  trainingLearnersKeysAPIDTO,
  trainingWidgetKeys,
  trainingWidgetTypes,
  trainingChartKeysAPIDTO
};
