<template>
  <div class="filter-section">
    <BaseText
      tag="h3"
      text="Filters"
      :theme="themes.ETHIXBASE_PRIMARY"
      :size="typographySize.HEADING_THREE"
      class="filter-section__title"
    />
    <ul class="filter-section__filter-list">
      <li
        v-for="{
          availableFilterOptions,
          availableFilterError,
          availableFilterValueComponentOptions,
          availableFilterValueOptionsComponent,
          id
        } in filterOptions"
        :key="`filter-${id}`"
        class="filter-section__filter-list-item"
      >
        <Select
          :id="`filter-${id}`"
          :label="`Select filter ${id}`"
          name="Select filter"
          :options="availableFilterOptions"
          :error="availableFilterError"
          placeholder="Select filter"
          isLabelHidden
          fullWidth
          class="filter-section__filter-list-search-input"
          @change="
            $emit(DOMEvents.CHANGE, {
              type: filterEvents.VALUES_UPDATED,
              id,
              event: $event
            })
          "
        />
        <component
          :is="availableFilterValueOptionsComponent"
          v-bind="availableFilterValueComponentOptions"
          class="filter-section__filter-list-search-input"
        />
        <CallToAction
          v-bind="removeFilterOptions"
          @click="
            $emit(DOMEvents.CLICK, {
              type: genericEvents.REMOVE,
              id,
              event: $event
            })
          "
        />
      </li>
    </ul>
    <div class="filter-section__add-filter">
      <CallToAction
        v-bind="addFilterOptions"
        @click="
          $emit(DOMEvents.CLICK, {
            type: genericEvents.ADD,
            event: $event
          })
        "
      />
    </div>
    <div class="filter-section__actions">
      <CallToAction
        value="Clear all"
        @click="
          $emit(DOMEvents.CLICK, {
            type: genericEvents.CLEAR_ALL,
            event: $event
          })
        "
        :theme="themes.ETHIXBASE_PRIMARY_INVERSE"
      />
      <CallToAction
        value="Apply"
        @click="
          $emit(DOMEvents.CLICK, { type: genericEvents.APPLY, event: $event })
        "
        :theme="themes.ETHIXBASE_PRIMARY_INVERSE"
      />
    </div>
  </div>
</template>

<script>
import Select from "@/molecules/Select/Select";
import CallToAction from "@/atoms/CallToAction/CallToAction";
import {
  direction,
  DOMEvents,
  filterEvents,
  genericEvents,
  icons,
  shapes,
  space,
  themes,
  typographySize
} from "@/constants";
import BaseText from "@/atoms/BaseText/BaseText";

export default {
  name: "FilterSection",
  components: { BaseText, CallToAction, Select },
  props: {
    filterOptions: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      DOMEvents,
      genericEvents,
      icons,
      direction,
      themes,
      typographySize,
      shapes,
      space,
      filterEvents,
      addFilterOptions: {
        value: "Add",
        icon: icons.PLUS,
        hasIconWithText: true,
        iconWithTextDirection: direction.HORIZONTAL,
        theme: themes.ETHIXBASE_PRIMARY,
        iconSize: 16,
        textSize: typographySize.BODY_TEXT_SMALL,
        hasLinkStyle: true
      },
      removeFilterOptions: {
        value: "Remove",
        icon: icons.TIMES,
        hasIconWithText: true,
        iconWithTextDirection: direction.HORIZONTAL,
        theme: themes.ETHIXBASE_PRIMARY,
        iconSize: 16,
        textSize: typographySize.BODY_TEXT_SMALL,
        hasLinkStyle: true
      }
    };
  }
};
</script>

<style scoped lang="scss">
.filter-section {
  width: 100%;
  position: relative;
  background: $trace-blue;
  border-top-left-radius: $border-radius8;
  border-top-right-radius: $border-radius8;
  padding: 5px $spacing16 $spacing16;
  z-index: 4;

  &__title {
    padding-top: $spacing24;
    text-align: left;
    margin-bottom: $spacing16;
  }

  &__filter-list {
    list-style: none;
    padding: 0;
    margin-bottom: 0;

    &-item {
      display: flex;
      gap: $spacing16;
      align-items: flex-start;
      margin-bottom: $spacing16;
    }

    &-search-input {
      width: 35%;
      margin-bottom: 0;
    }
  }

  &__add-filter {
    text-align: left;
  }

  &__actions {
    display: flex;
    gap: $spacing16;
    justify-content: flex-end;
  }
}
</style>
