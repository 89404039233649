import { thirdPartyArchive } from "@/constants";

export const getters = {
  isThirdPartyArchived: (state) =>
    Number.parseInt(state.thirdPartyDetails.is_archived) ===
    thirdPartyArchive.ARCHIVED,
  isThirdPartyDnbEnriched: (state) =>
    !!(state.thirdPartyDetails.duns_number && state.thirdPartyDetails.enriched),
  isThirdPartyIDDOrdered: (state) =>
    !!(Number.parseInt(state.thirdPartyDetails?.risk_level) || 0),
  isThirdPartyIDDPlusOrdered: (state) =>
    !!(Number.parseInt(state.thirdPartyDetails?.idd_color) || 0)
};
