import { HTTP, makeAuthorizationHeader } from "@/utils";
import { endpoints } from "@/constants";

const fetchWidgetList = async ({ tenantId, companyId }) => {
  const { data = [] } = await HTTP(
    "post",
    endpoints.FETCH_WIDGET_LIST,
    {
      company_id: companyId
    },
    makeAuthorizationHeader({ tenantId })
  );

  return data;
};

export const widgetsService = {
  fetchWidgetList
};
