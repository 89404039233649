const getterName = {
  PLATFORM_SETTINGS: {
    HAS_PUBLIC_PLATFORM_SETTINGS: "platformSettings/hasPublicPlatformSettings",
    HAS_PLATFORM_SETTINGS: "platformSettings/hasPlatformSettings",
    GET_PLATFORM_USER_LIST_OPTIONS:
      "platformSettings/getPlatformUserListOptions",
    GET_PRODUCT_VERSIONS_LIST: "platformSettings/getProductVersionsList"
  },
  USER: {
    IS_USER_ADMIN: "user/isUserAdmin",
    IS_USER_SUPER_ADMIN: "user/isUserSuperAdmin",
    IS_USER_SUPER_ADMIN_OR_ADMIN: "user/isUserSuperAdminOrAdmin",
    IS_USER_THIRD_PARTY: "user/isUserThirdParty",
    IS_USER_NOT_UPDATED: "user/isUserNotUpdated",
    HAS_NO_USER_DATA: "user/hasNoUserData",
    IS_LOGGED_IN_VIA_THE_PLATFORM: "user/isLoggedInViaThePlatform",
    GET_COMPANY_NAME: "user/getCompanyName",
    GET_FULL_NAME: "user/getFullName",
    PERMITTED_LANDING_PAGE: "user/permittedLandingPage"
  },
  USER_SETTINGS: {
    HAS_PLATFORM_PAGE_AND_PAGE_LIST: "userSettings/hasHomePageAndPageList"
  },
  ACTIONS: {
    GET_SYSTEM_ACTIONS: "actions/getSystemActions",
    GET_ACTION_OPTIONS: "actions/getActionOptions",
    GET_CUSTOM_ACTION_OPTIONS: "actions/getCustomActionOptions",
    GET_ACTION_BASED_ON_ID: "actions/getActionBasedOnId"
  },
  EXPECTED_DATA: {
    GET_EXPECTED_DATA_ENTITIES: "expectedData/getExpectedDataEntities",
    GET_EXPECTED_DATA_DDQ_TASKS: "expectedData/getExpectedDataDDQTasks",
    GET_EXPECTED_DATA_RISK_RATINGS: "expectedData/getExpectedDataRiskRatings",
    GET_EXPECTED_DATA_STRING_LIST: "expectedData/getExpectedDataStringLists",
    GET_EXPECTED_DATA_PRIMITIVE_TYPES:
      "expectedData/getExpectedDataPrimitiveTypes",
    GET_EXPECTED_DATA_GROUPED_OPTIONS:
      "expectedData/getExpectedDataGroupedOptions",
    GET_EXPECTED_DATA_MAP_OPTIONS: "expectedData/getExpectedDataMapOptions"
  },
  AUTH: {
    IS_USER_AUTHENTICATED: "auth/isUserAuthenticated"
  },
  ROLES: {
    GET_ID_TO_ROLE_NAME_MAP: "roles/getIdToRoleNameMap",
    GET_IDS_TO_ROLE_NAMES: "roles/getIdsToRoleNames",
    GET_ALL_ROLES_AS_MULTISELECT_OPTIONS:
      "roles/getAllRolesAsMultiselectOptions"
  },
  ESG: {
    GET_LATEST_ESG_REPORT_SUMMARY: "esg/getLatestESGReportSummary"
  },
  USER_EMAIL_TEMPLATES: {
    GET_DESTINATION_BASE_OPTIONS:
      "userEmailTemplates/getDestinationBaseOptions",
    GET_ROLES_DESTINATION_OPTIONS:
      "userEmailTemplates/getRolesDestinationOptions",
    GET_USERS_DESTINATION_OPTIONS:
      "userEmailTemplates/getUsersDestinationOptions",
    GET_USER_EMAIL_TEMPLATES_OPTIONS:
      "userEmailTemplates/getUserEmailTemplatesOptions",
    GET_USER_EMAIL_TEMPLATE_BASED_BY_ID:
      "userEmailTemplates/getUserEmailTemplateBasedById"
  },
  COMPANY: {
    IS_CONTACT_NAME_AND_EMAIL_REQUIRED: "company/isContactNameAndEmailRequired",
    IS_DNB_FEATURE_ENABLED: "company/isDNBFeatureEnabled",
    HAS_PREMIUM_CORPORATE_DATA_PRODUCTS:
      "company/hasPremiumCorporateDataProducts",
    HAS_UBO_FEATURE: "company/hasUBOFeature",
    IS_OPEN_CORPORATE_DATA_ENABLED: "company/isOpenCorporateDataEnabled",
    IS_ORBIA_ONBOARDING_FORM_ENABLED: "company/isOrbiaOnboardingFormEnabled",
    GET_COMPANY_VIEWS_WITH_PERMISSION: "company/getCompanyViewsWithPermission"
  },
  COUNTRY: {
    GET_COUNTRY_CODE_BASED_ON_COUNTRY_NAME:
      "country/getCountryCodeBasedOnCountryName",
    GET_STATE_NAME_BASED_ON_STATE_CODE: "country/getStateNameBasedOnStateCode"
  },
  PERMISSIONS: {
    APP_ROUTE_PERMISSIONS: "permissions/appRoutePermissions"
  },
  TASK_TEMPLATES: {
    GET_TASK_TEMPLATES_OPTIONS: "taskTemplates/getTaskTemplatesOptions",
    GET_TASK_TEMPLATE_BASED_BY_ID: "taskTemplates/getTaskTemplateBasedById"
  },
  THIRD_PARTY: {
    IS_THIRD_PARTY_ARCHIVED: "thirdParty/isThirdPartyArchived",
    IS_THIRD_PARTY_DNB_ENRICHED: "thirdParty/isThirdPartyDnbEnriched",
    IS_THIRD_PARTY_IDD_ORDERED: "thirdParty/isThirdPartyIDDOrdered",
    IS_THIRD_PARTY_IDD_PLUS_ORDERED: "thirdParty/isThirdPartyIDDPlusOrdered"
  },
  TASKS: {
    GET_IS_TASK_REOPEN_ALLOWED: "tasks/getIsTaskReopenAllowed",
    GET_IS_TASK_EDIT_ALLOWED: "tasks/getIsTaskEditAllowed",
    GET_IS_TASK_COMPLETE_ALLOWED: "tasks/getIsTaskCompleteAllowed"
  },
  LANGUAGES: {
    GET_LANGUAGE_OPTIONS: "languages/getLanguageOptions"
  },
  PRODUCT: {
    GET_UNIQUE_AVAILABLE_PRODUCTS: "product/getUniqueAvailableProducts",
    GET_UNIQUE_AVAILABLE_PRODUCTS_OPTIONS:
      "product/getUniqueAvailableProductsOptions"
  }
};

export { getterName };
