<template>
  <div class="base-chart-widget">
    <div v-if="chartTypeOptions.length" class="base-chart-widget__cta-wrapper">
      <DropdownMenuWithCallToAction
        :CTAOptions="{
          iconSize: 16,
          icon: 'cogs',
          'data-test-id': 'base-chart-widget__dropdown-cta'
        }"
        :dropdown-width="120"
        :menu-options="makeDropdownMenuOptions"
      />
    </div>
    <BaseText
      v-if="chartTitle"
      class="base-chart-widget__title"
      :text="chartTitle"
      data-test-id="base-chart-widget__title"
    />
    <template v-if="isEmpty(chartOptions)">
      <slot>
        <BaseText
          data-test-id="base-chart-widget__no-data"
          text="Data is not available"
        />
      </slot>
    </template>
    <slot v-else name="chart">
      <BaseChart
        :highcharts-component-options="highchartsComponentOptions"
        :chart-options="chartOptions"
      />
    </slot>
  </div>
</template>

<script>
import BaseChart from "@/atoms/BaseChart/BaseChart";
import BaseText from "@/atoms/BaseText/BaseText";
import DropdownMenuWithCallToAction from "@/molecules/DropdownMenuWithCallToAction/DropdownMenuWithCallToAction";
import { isEmpty } from "lodash";
import { icons, typographySize, DOMEvents } from "@/constants";
import DropdownMenuItem from "@/molecules/DropdownMenuItem/DropdownMenuItem";

export default {
  name: "BaseChartWidget",
  components: {
    BaseText,
    BaseChart,
    DropdownMenuWithCallToAction
  },
  data() {
    return {
      icons
    };
  },
  props: {
    chartTitle: {
      required: true,
      validator: (prop) => typeof prop === "string" || prop === undefined
    },
    chartOptions: {
      required: true,
      type: Object
    },
    highchartsComponentOptions: {
      type: Object,
      default: () => ({})
    },
    chartTypeOptions: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    makeDropdownMenuOptions() {
      return [
        {
          listOptions:
            this.chartTypeOptions?.map(({ text, value }, id) => ({
              component: DropdownMenuItem,
              options: {
                id,
                textOptions: {
                  text,
                  size: typographySize.BODY_TEXT_SMALL
                },
                onClick: () => {
                  this.$emit(DOMEvents.CHANGE, value);
                }
              }
            })) || []
        }
      ];
    }
  },
  methods: {
    isEmpty
  }
};
</script>

<style lang="scss" scoped>
.base-chart-widget {
  padding: 12px;
  border: 1px solid rgba($black, 0.125);
  border-radius: 3px;

  &:deep(.base-chart-widget__title) {
    @include body-text-bold;
    text-align: center;
    margin: 0px;
  }

  &__cta-wrapper {
    text-align: right;
  }
}
</style>
