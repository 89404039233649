<template>
  <div class="third-party-details">
    <div>
      <b-overlay :show="isLoadingPage" rounded="sm">
        <b-alert
          style="margin: 0 0 1px 0"
          v-model="showCreditsAlert"
          variant="warning"
        >
          <b style="color: black">
            You do not have sufficient credits to order the selected Profile
            Tasks. please contact <a href="#">ethixbase support</a> for
            assistance
          </b>
        </b-alert>
        <ErrorPageTemplate
          class="third-party-details__body--screen-height"
          v-if="isErrorPresent"
          v-bind="errorPageOptions"
        />
        <b-row v-else>
          <!-- 8 column left div start -->
          <b-col class="third-party-details__body--screen-height" cols="9">
            <!-- card enity Details start-->
            <Accordion
              ref="entity-details-accordion"
              class="third-party-details__entity-details"
              title="Entity details"
              :collapsed="collapse.isEntityDetails"
              :iconOptions="infoIcon"
              @click="navigateToURL(knowledgeCentreLinks.ENTITY_DETAILS)"
            >
              <div class="third-party-details__validation-message">
                <BaseText
                  text="All fields marked with"
                  hasDefaultSpacingRemoved
                />
                <BaseText
                  text="*"
                  :theme="themes.ERROR_INVERSE"
                  hasDefaultSpacingRemoved
                />
                <BaseText text="are required" hasDefaultSpacingRemoved />
              </div>
              <b-row
                v-if="
                  isFetchResultsDisabled &&
                  entityTypeSelect === thirdPartyType.INDIVIDUAL
                "
              >
                <b-col cols="3">
                  <p class="m-0">Entity Name</p>
                  <b>{{ form.entityName }}</b>
                </b-col>
              </b-row>
              <b-row v-else>
                <b-col cols="3">
                  <b-form-group>
                    <div class="third-party-details__label-container">
                      <label>Entity type</label>
                      <BaseText
                        text="*"
                        :theme="themes.ERROR_INVERSE"
                        hasDefaultSpacingRemoved
                      />
                    </div>
                    <b-form-select
                      :disabled="
                        isFetchResultsDisabled ||
                        isEditModeEnabled ||
                        showCreditsAlert ||
                        isVerifiedEntity
                      "
                      v-model="entityTypeSelect"
                      :options="optionsTypes"
                      @change="resetEntityDetails"
                      data-test-id="third-party-details__entity-type-input"
                    ></b-form-select>
                  </b-form-group>
                </b-col>
                <b-col class="d-flex pl-0" cols="5">
                  <b-form-group
                    v-if="entityTypeSelect == thirdPartyType.ORGANIZATION"
                    class="w-100"
                  >
                    <div class="third-party-details__entity-name">
                      <div class="third-party-details__label-container">
                        <label class="third-party-details__entity-name-label">
                          Entity legal name
                        </label>
                        <BaseText
                          text="*"
                          :theme="themes.ERROR_INVERSE"
                          hasDefaultSpacingRemoved
                        />
                      </div>
                      <BaseBadge
                        v-if="isVerifiedEntity"
                        class="third-party-details__entity-verified-icon"
                        v-bind="pillIcons.VERIFIED_PILL"
                      />
                    </div>
                    <b-form-input
                      :disabled="isEntityNameInputDisabled"
                      v-model="v$.form.entityName.$model"
                      :state="
                        v$.form.entityName.$dirty
                          ? !v$.form.entityName.$error
                          : null
                      "
                      id="entityName-live-feedback"
                      placeholder="Entity legal name"
                      :readonly="isVerifiedEntity"
                      @input="checkEntityName"
                      @change="callOpenCorporateTableApi"
                      @blur="handleShowSavedEntitiesSection"
                      data-test-id="third-party-details__entity-name-input"
                    ></b-form-input>
                    <b-form-invalid-feedback id="entityName-live-feedback"
                      >A Required field has no value.</b-form-invalid-feedback
                    >
                  </b-form-group>
                  <b-form-group v-else class="w-100">
                    <div class="third-party-details__label-container">
                      <label>Entity legal name</label>
                      <BaseText
                        text="*"
                        :theme="themes.ERROR_INVERSE"
                        hasDefaultSpacingRemoved
                      />
                    </div>
                    <b-form-input
                      :disabled="isEntityNameInputDisabled"
                      v-model="v$.form.firstName.$model"
                      :state="
                        v$.form.firstName.$dirty
                          ? !v$.form.firstName.$error
                          : null
                      "
                      placeholder="Entity legal name"
                      @change="callOpenCorporateTableApi"
                      @blur="handleShowSavedEntitiesSection"
                      data-test-id="third-party-details__first-name-input"
                    ></b-form-input>
                    <b-form-invalid-feedback id="firstname-live-feedback"
                      >A Required field has no value.</b-form-invalid-feedback
                    >
                  </b-form-group>
                </b-col>
                <b-col cols="4" class="pl-0">
                  <b-form-group
                    v-if="entityTypeSelect === thirdPartyType.ORGANIZATION"
                    data-test-id="third-party-details__registered-in-wrapper"
                  >
                    <div class="third-party-details__label-container">
                      <label>Registered in</label>
                      <BaseText
                        text="*"
                        :theme="themes.ERROR_INVERSE"
                        hasDefaultSpacingRemoved
                      />
                    </div>
                    <v-select
                      :disabled="
                        isFetchResultsDisabled ||
                        isEditModeEnabled ||
                        showCreditsAlert ||
                        isVerifiedEntity
                      "
                      placeholder="Select country"
                      :maxHeight="220"
                      v-model="v$.form.country.$model"
                      :state="
                        v$.form.country.$dirty ? !v$.form.country.$error : null
                      "
                      :class="[
                        'custom-dropdown-toggle-class',
                        v$.form.country.$error && 'form-control is-invalid p-0'
                      ]"
                      label="name"
                      track-by="name"
                      :options="countries"
                      @option:selected="handleShowSavedEntitiesSection"
                      @update:modelValue="
                        callOpenCorporateTableApi($event, 'country')
                      "
                    >
                      <template slot="option" slot-scope="option">
                        <country-flag
                          v-if="option.icon"
                          :country="option.icon"
                          size="small"
                          data-test-id="third-party-details__registered-in-country--flag"
                        />
                        <span
                          data-test-id="third-party-details__registered-in-country--name"
                          >{{ "&nbsp;" + option.name }}</span
                        >
                      </template>
                    </v-select>
                    <b-form-invalid-feedback id="countrySelect"
                      >There are no selected actions.</b-form-invalid-feedback
                    >
                  </b-form-group>
                  <b-form-group v-else class="w-100">
                    <div class="third-party-details__label-container">
                      <label>Registered in</label>
                      <BaseText
                        text="*"
                        :theme="themes.ERROR_INVERSE"
                        hasDefaultSpacingRemoved
                      />
                    </div>
                    <b-form-select
                      :disabled="
                        isFetchResultsDisabled ||
                        isEditModeEnabled ||
                        showCreditsAlert
                      "
                      v-model="v$.form.searchAssociatedCompaniesValue.$model"
                      :state="
                        v$.form.searchAssociatedCompaniesValue.$dirty
                          ? !v$.form.searchAssociatedCompaniesValue.$error
                          : null
                      "
                      data-test-id="third-party-details__search-associate-companies-input"
                    >
                      <b-form-select-option :value="null"
                        >Search Associated Companies</b-form-select-option
                      >
                      <b-form-select-option value="yes"
                        >Yes</b-form-select-option
                      >
                      <b-form-select-option value="no">No</b-form-select-option>
                    </b-form-select>
                    <b-form-invalid-feedback id="firstname-live-feedback"
                      >There are no selected actions.</b-form-invalid-feedback
                    >
                  </b-form-group>
                </b-col>
              </b-row>

              <BaseInfoBox
                v-if="showEntityDuplicateWarningMessage"
                class="third-party-details__info-box-spacing"
                :text="`The entity you are creating already exists in the platform. To view this entity <a target='_blank' href='${duplicateEntityURL}'>click here</a>. Alternatively update the details and continue onboarding.`"
                :theme="themes.WARNING"
                :icon-options="warningIconOptions"
              />

              <!-- card Previously Saved Entites start-->
              <div
                v-if="existingItems.length && !isEditModeEnabled"
                class="third-party-details__previously-saved-entities"
              >
                <BaseText
                  class="third-party-details__previously-saved-entities__header-title"
                  text="Previously Saved Entities"
                  :size="typographySize.BODY_TEXT_BOLD"
                  hasDefaultSpacingRemoved
                />

                <div
                  class="third-party-details__previously-saved-entities__content"
                >
                  <div>
                    <div>
                      <b-row>
                        <b-col cols="12" class="d-flex">
                          <p>
                            The below similar entities are saved to your
                            account, if you would like to use one of these
                            please select below.
                          </p>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col cols="12" class="table-col">
                          <div v-if="isLoadingPreviouslySavedEntities">
                            <b-skeleton-table
                              :rows="5"
                              :columns="3"
                              :table-props="{ bordered: true, striped: true }"
                            ></b-skeleton-table>
                          </div>
                          <div v-else>
                            <b-table
                              :aria-disabled="isFetchResultsDisabled"
                              thead-class="thead-blue-color"
                              ref="table"
                              sticky-header
                              bordered
                              id="existing-items-table"
                              :busy.sync="isLoadingPreviouslySavedEntities"
                              :fields="makePreviouslySavedEntitiesFields"
                              :items="existingItems"
                              :sort-by="sortBy"
                              :sort-desc="sortDesc"
                              responsive="sm"
                              small
                              outlined
                              :per-page="perPageExistingItems"
                              :current-page="currentPageExistingItems"
                              :filter="filterExistingItems"
                            >
                              <template v-slot:cell(select)="data">
                                <b-form-radio
                                  v-model="selectedThirdParty"
                                  @change="
                                    showConfirmModal(parseInt(data.item.tpi_id))
                                  "
                                  :value="data.index"
                                  class="text-center"
                                  name="some-radios"
                                ></b-form-radio>
                              </template>
                              <template #cell(registered_country)="data">
                                <div
                                  v-if="
                                    getCountryNames(
                                      data.item.jurisdiction_code
                                    ) == -1
                                  "
                                >
                                  <p>
                                    <span>{{
                                      "&nbsp;" +
                                      getJurisdicationCountryName(
                                        data.item.jurisdiction_code
                                      ).name
                                    }}</span>
                                  </p>
                                </div>
                                <div v-else>
                                  <div>
                                    <p>
                                      <span
                                        >{{
                                          "&nbsp;" +
                                          OCStatesData[
                                            getCountryNames(
                                              data.item.jurisdiction_code
                                            )
                                          ].state
                                        }}
                                        ({{
                                          getJurisdicationCountryName(
                                            data.item.jurisdiction_code.substring(
                                              0,
                                              2
                                            )
                                          ).name
                                        }})</span
                                      >
                                    </p>
                                  </div>
                                </div>
                              </template>
                            </b-table>
                            <div
                              v-if="existingItems.length == 0"
                              class="text-center"
                            >
                              <p>No Results Found</p>
                            </div>
                            <b-row v-else>
                              <b-col cols="6" v-if="fromRowsExistingItems">
                                <p class="m-0">
                                  Showing Result From
                                  <b>{{ fromRowsExistingItems }}</b> to
                                  <b>{{ toRowsExistingItems }}</b>
                                </p>
                              </b-col>
                              <b-col cols="6">
                                <b-pagination
                                  class="float-right"
                                  v-model="currentPageExistingItems"
                                  :total-rows="totalRowsExistingItems"
                                  :per-page="perPageExistingItems"
                                  hide-ellipsis
                                  limit="3"
                                  first-text="First"
                                  prev-text="Prev"
                                  next-text="Next"
                                  last-text="Last"
                                  aria-controls="existing-items-table"
                                ></b-pagination>
                              </b-col>
                            </b-row>
                          </div>
                        </b-col>
                      </b-row>
                    </div>
                  </div>
                </div>
              </div>
              <!-- card Previously Saved Entites end-->

              <div
                v-if="entityTypeSelect === thirdPartyType.ORGANIZATION"
                class="col-4 p-0"
              >
                <b-form-group>
                  <label>Alias</label>
                  <b-form-input
                    v-model="form.alias"
                    data-test-id="third-party-details__alias-input"
                  />
                </b-form-group>
              </div>

              <b-row>
                <b-col cols="12" class="mt-2">
                  <b-form-group>
                    <label>Address</label>
                    <b-form-textarea
                      :disabled="showCreditsAlert"
                      v-model="additionalDetailForm.address"
                      data-test-id="third-party-details__address"
                    ></b-form-textarea>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="4">
                  <b-form-group>
                    <label>City</label>
                    <b-form-input
                      :disabled="showCreditsAlert"
                      v-model="additionalDetailForm.city"
                      data-test-id="third-party-details__additional-details-city"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col cols="4">
                  <b-form-group
                    :state="!isStateErrorRequired"
                    invalid-feedback="Required for entity verification"
                  >
                    <div class="third-party-details__label-container">
                      <label>State</label>
                      <BaseText
                        v-if="isStateErrorRequired"
                        text="*"
                        :theme="themes.ERROR_INVERSE"
                        hasDefaultSpacingRemoved
                      />
                    </div>
                    <v-select
                      v-if="hasDropdownOptionsForState"
                      id="state"
                      placeholder="Select state"
                      v-model="additionalDetailForm.state"
                      :maxHeight="220"
                      :disabled="showCreditsAlert || isVerifiedEntity"
                      label="state"
                      track-by="state"
                      :options="stateOptions"
                      @update:modelValue="setSelectedState"
                    >
                      <template slot="option" slot-scope="option">
                        <span
                          data-test-id="third-party-details__additional-details-state-option"
                          >{{ option.state }}</span
                        >
                      </template>
                    </v-select>
                    <b-form-input
                      v-else
                      id="state"
                      :disabled="showCreditsAlert"
                      v-model="additionalDetailForm.state"
                      data-test-id="third-party-details__additional-details-state"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col cols="4">
                  <b-form-group>
                    <label>Postal/Zip Code</label>
                    <b-form-input
                      :disabled="showCreditsAlert"
                      v-model="additionalDetailForm.postal_code"
                      onkeydown="return event.keyCode !== 69"
                      data-test-id="third-party-details__additional-details-postal-code"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="4">
                  <b-form-group>
                    <label>Registration Number</label>
                    <b-form-input
                      :disabled="showCreditsAlert || isVerifiedEntity"
                      v-model="form.registrationNumber"
                      data-test-id="third-party-details__registration-number"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="4">
                  <b-form-group>
                    <label>Website URL</label>
                    <b-form-input
                      type="url"
                      v-model="form.entityThirdPartyUrl"
                      :state="
                        !form.entityThirdPartyUrl
                          ? null
                          : form.entityThirdPartyUrl && invalidUrlDomain
                          ? false
                          : true
                      "
                      @change="is_url"
                      data-test-id="third-party-details__url-input"
                    ></b-form-input>
                    <b-form-invalid-feedback
                      v-if="form.entityThirdPartyUrl && invalidUrlDomain"
                    >
                      {{ invalidUrlDomainValue }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
              <div
                v-if="showConfirmVerifiedNextStepsSection && form.entityName"
                data-test-id="third-party-details__confirm-verified-next-steps"
              >
                <hr />
                <BaseInfoBox
                  class="third-party-details__info-box-spacing"
                  :text="`Due diligence was previously conducted on '${originalEntityName}'. You have now confirmed the correct entity name is '${form.entityName}'. We have updated the entity details to '${form.entityName}'.`"
                  :theme="themes.WARNING"
                  :icon-options="warningIconOptions"
                />
                <BaseText
                  text="Confirm next steps"
                  :size="typographySize.BODY_TEXT_BOLD"
                />
                <BaseInfoBox
                  v-if="confirmVerifiedNextStepsQuestionError"
                  :text="confirmVerifiedNextStepsQuestionError"
                  class="third-party-details__info-box-spacing"
                  :theme="themes.ERROR"
                  :icon-options="defaultIcons.HIGH"
                  ref="confirmNextStepsError"
                />
                <div class="third-party-details__confirm-next-steps-option">
                  <BaseText
                    :text="`Would you like to re-order due diligence screening against '${form.entityName}'? This action will be chargeable and use available credits.`"
                    has-default-spacing-removed
                  />
                  <BaseRadio
                    :label="`Would you like to re-order due diligence screening against '${form.entityName}'? This action will be chargeable and use available credits.`"
                    :options="confirmVerifiedNextStepsQuestionOptions"
                    name="confirm-verified-next-steps"
                    :direction="direction.HORIZONTAL"
                    :size="sizes.DEFAULT"
                    :change-method="
                      onConfirmVerificationNextStepsQuestionChange
                    "
                  />
                </div>
                <BaseText
                  :text="`Yes: Re-order and switch ongoing monitoring to '${form.entityName}'.`"
                />
                <BaseText
                  :text="`No: Continue monitoring the existing entity, '${originalEntityName}'.`"
                />
              </div>
              <div v-if="!isVerifiedEntity" ref="entity-verification">
                <hr />
                <div
                  v-if="entityTypeSelect === thirdPartyType.ORGANIZATION"
                  class="third-party-details__entity-verification"
                >
                  <div class="third-party-details__entity-verification-heading">
                    <BaseText
                      :size="typographySize.BODY_TEXT_BOLD"
                      has-default-spacing-removed
                      text="Verify entity (optional)"
                    />
                  </div>
                  <BaseInfoBox
                    v-if="!isEntityVerificationPerformed"
                    :text="infoMessages.thirdParty.verifyEntityOptional"
                    :icon-options="infoIconOptions"
                    :theme="themes.INFO"
                  />
                  <div
                    v-if="
                      isEntityVerificationPerformed &&
                      entityVerificationData.length
                    "
                  >
                    <BaseInfoBox
                      :text="infoMessages.thirdParty.successSearch"
                      :icon-options="successIconOptions"
                      :theme="themes.SUCCESS"
                    />
                    <BaseInfoBox
                      class="third-party-details__info-box-spacing"
                      :text="infoMessages.thirdParty.subscriptionMatch"
                      :icon-options="infoIconOptions"
                      :theme="themes.INFO"
                    />
                  </div>
                  <BaseInfoBox
                    v-if="
                      isEntityVerificationPerformed &&
                      !entityVerificationData.length
                    "
                    :text="infoMessages.thirdParty.noDNBCoverage"
                    :icon-options="infoIconOptions"
                    :theme="themes.INFO"
                  />
                  <div>
                    <b-skeleton-table
                      v-if="isLoadingCountryData"
                      class="mt-4"
                      :rows="3"
                      :columns="7"
                      :table-props="{ bordered: true, striped: true }"
                    />
                    <div v-else-if="entityVerificationListItems.length">
                      <StickyTable
                        :table-headers="entityVerificationTableHeaders"
                        :table-rows="entityVerificationTableRows"
                      />
                      <div
                        v-if="showEntityVerificationMatchConfidenceButton"
                        class="third-party-details__entity-verification-show-more"
                      >
                        <CallToAction
                          value="Show more matches"
                          :theme="themes.ETHIXBASE_PRIMARY_INVERSE"
                          :size="typographySize.BODY_TEXT_SMALL"
                          hasLinkStyle
                          @click="handleShowMoreEntitiesClick"
                        />
                      </div>
                      <div
                        class="third-party-details__entity-verification-actions"
                      >
                        <CallToAction
                          value="Confirm"
                          :is-disabled="entityVerificationSelectedItemIndex < 0"
                          :theme="themes.SECONDARY"
                          :is-success="verifyEntitySuccessState"
                          @click="
                            onConfirmEntityVerification({
                              entityVerificationListItem:
                                entityVerificationListItems[
                                  entityVerificationSelectedItemIndex
                                ],
                              isBasicVerificationOnly: true
                            })
                          "
                          data-test-id="third-party-details__entity-verification-verify-button"
                        />
                        <CallToAction
                          v-if="hasPremiumCorporateDataProducts"
                          value="Confirm & get Premium Corporate Data"
                          :is-disabled="entityVerificationSelectedItemIndex < 0"
                          :is-loading="isLoadingAssociatesTable"
                          :is-success="verifyAndEnrichEntitySuccessState"
                          :is-error="verifyAndEnrichEntityErrorState"
                          @click="
                            onConfirmEntityVerification({
                              entityVerificationListItem:
                                entityVerificationListItems[
                                  entityVerificationSelectedItemIndex
                                ]
                            })
                          "
                          data-test-id="third-party-details__entity-verification-verify-button"
                        />
                      </div>
                    </div>
                    <div
                      v-else
                      class="third-party-details__entity-verification-footer"
                    >
                      <CallToAction
                        value="Start verification"
                        :is-disabled="
                          form.entityName == null || form.country == null
                        "
                        @click="fetchCompanyOrganizations"
                        data-test-id="third-party-details__entity-verification-verify-button"
                      />
                    </div>
                  </div>
                </div>
                <div
                  v-if="
                    form.searchAssociatedCompaniesValue == 'yes' &&
                    entityTypeSelect == 'P'
                  "
                  style="padding-top: 5px"
                >
                  <div v-if="isLoadingForIndividual">
                    <b-skeleton-table
                      :rows="5"
                      :columns="4"
                      :table-props="{ bordered: true, striped: true }"
                    ></b-skeleton-table>
                  </div>
                  <div :style="isLoadingForIndividual ? 'display: none;' : ''">
                    <b-row>
                      <b-col cols="10" class="d-flex">
                        <p>
                          The companies listed below have an associated
                          individual named "{{ form.firstName }}" listed within
                          that companies online registry records.
                        </p>
                      </b-col>
                      <b-col cols="2">
                        <b-btn
                          v-if="totalRowsIndividual != 0"
                          @click="
                            verificationTableCollapseThree =
                              !verificationTableCollapseThree
                          "
                          variant="outline-primary"
                          style="float: right"
                          size="sm"
                          :disabled="form.firstName == null ? true : false"
                          data-test-id="third-party-details__entity-verification-table-toggle"
                          >{{
                            verificationTableCollapseThree ? "Hide" : "Show me"
                          }}</b-btn
                        >
                      </b-col>
                    </b-row>
                    <b-collapse v-model="verificationTableCollapseThree">
                      <b-row>
                        <b-col cols="12" class="table-col">
                          <div>
                            <div
                              :class="
                                tableWithIndividualItems.length ? '' : 'd-none'
                              "
                            >
                              <b-table
                                :aria-disabled="isFetchResultsDisabled"
                                thead-class="thead-blue-color"
                                sticky-header="calc(100vh - 200px)"
                                ref="table"
                                id="data-other-location-table"
                                :busy.sync="isLoadingForIndividual"
                                :fields="openCorporateIndividualFields"
                                :items="getOpenCorporatesCompanyIndividual"
                                :sort-by="sortByIndividual"
                                :sort-desc="sortDescIndividual"
                                responsive="sm"
                                bordered
                                small
                                outlined
                                :per-page="perPageIndividual"
                                :current-page="currentPageIndividual"
                                :filter="filterIndividual"
                              >
                                <template v-slot:cell(select)="data">
                                  <b-form-radio
                                    :disabled="
                                      isFetchResultsDisabled ||
                                      isEditModeEnabled ||
                                      showCreditsAlert
                                    "
                                    @change="setOpenCorporateData(data)"
                                    v-model="selectedIndividualRowCountry"
                                    data-test-id="third-party-details__entity-verification-select"
                                    :value="data.index"
                                    class="text-center"
                                    name="some-radios"
                                  ></b-form-radio>
                                </template>
                                <template v-slot:cell(name)="data">
                                  {{ data.item.company.name }}
                                </template>
                                <template #cell(registered_country)="data">
                                  <div
                                    v-if="
                                      getCountryNames(
                                        data.item.jurisdiction_code
                                      ) == -1
                                    "
                                  >
                                    <p>
                                      <span>{{
                                        "&nbsp;" +
                                        getJurisdicationCountryName(
                                          data.item.jurisdiction_code
                                        ).name
                                      }}</span>
                                    </p>
                                  </div>
                                  <div v-else>
                                    <div>
                                      <p>
                                        <span
                                          >{{
                                            "&nbsp;" +
                                            OCStatesData[
                                              getCountryNames(
                                                data.item.jurisdiction_code
                                              )
                                            ].state
                                          }}
                                          ({{
                                            getJurisdicationCountryName(
                                              data.item.jurisdiction_code.substring(
                                                0,
                                                2
                                              )
                                            ).name
                                          }})</span
                                        >
                                      </p>
                                    </div>
                                  </div>
                                </template>
                                <template #cell(registration_status)="data">
                                  {{ data.item.position }}
                                </template>
                              </b-table>
                              <div
                                v-if="tableWithIndividualItems.length == 0"
                                class="text-center"
                              >
                                <p>No Results Found</p>
                              </div>
                              <b-row v-else>
                                <b-col cols="2"> </b-col>
                                <b-col cols="5" v-if="fromRowsIndividual">
                                  <p class="text-center">
                                    Showing Result From
                                    <b>{{ fromRowsIndividual }}</b> to
                                    <b>{{ toRowsIndividual }}</b>
                                  </p>
                                </b-col>
                                <b-col>
                                  <b-pagination
                                    :disabled="
                                      isFetchResultsDisabled ||
                                      isEditModeEnabled ||
                                      showCreditsAlert
                                    "
                                    @input="resetPaginationIndividual"
                                    class="float-right"
                                    v-model="currentPageIndividual"
                                    :total-rows="totalPaginationIndividual"
                                    :per-page="perPageIndividual"
                                    hide-ellipsis
                                    limit="3"
                                    first-text="First"
                                    prev-text="Prev"
                                    next-text="Next"
                                    last-text="Last"
                                    aria-controls="data-other-location-table"
                                  ></b-pagination>
                                </b-col>
                              </b-row>
                            </div>
                          </div>
                        </b-col>
                      </b-row>
                    </b-collapse>
                  </div>
                </div>
              </div>
            </Accordion>
            <!-- card enity Details end-->

            <b-row>
              <b-col>
                <!-- Associate screenings card start -->
                <Accordion
                  class="third-party-details__accordion-spacing"
                  title="Associate entities"
                  @click="
                    navigateToURL(knowledgeCentreLinks.ASSOCIATE_ENTITIES)
                  "
                  :collapsed="collapse.AssociateEntities"
                  :iconOptions="infoIcon"
                >
                  <div>
                    <p>
                      You may add up to
                      <span style="text-transform: lowercase">{{
                        getNumberWord(headerConfigData?.maximumAssociate)
                      }}</span>
                      associated entities below to be searched in conjunction
                      with
                      <b>{{ form.entityName }}</b
                      >.
                    </p>

                    <p v-if="platformSupportEmail">
                      Should you wish to add more associated entities, please
                      contact
                      <a :href="platformSupportEmail">
                        {{ platformSupportEmail.split(":")[1] }}</a
                      >.
                    </p>
                    <b-row
                      v-for="(users, associatesUsersIndex) in associatesUsers"
                      :key="associatesUsersIndex"
                    >
                      <b-col cols="4">
                        <b-form-group>
                          <!-- in disabled remove isEditModeEnabled when doing associates funtionality work -->
                          <b-form-select
                            :disabled="showCreditsAlert"
                            v-model="users.entityType"
                            @input="
                              onSelectEntityType($event, associatesUsersIndex)
                            "
                            :state="users.validationStateEntityType"
                            size="sm"
                            data-test-id="third-party-details__associate-entities-type"
                          >
                            <b-form-select-option
                              :value="thirdPartyType.ORGANIZATION"
                              >Company</b-form-select-option
                            >
                            <b-form-select-option
                              :value="thirdPartyType.INDIVIDUAL"
                              >Individual</b-form-select-option
                            >
                          </b-form-select>
                          <p
                            v-if="users.validationStateEntityType == false"
                            class="text-feedback"
                          >
                            {{ "Please Select Entity Type" }}
                          </p>
                        </b-form-group>
                      </b-col>

                      <b-col cols="5" class="p-0">
                        <b-form-group class="ml-1">
                          <b-form-input
                            :disabled="showCreditsAlert"
                            size="sm"
                            v-model="users.assoName"
                            placeholder="Full name"
                            @input="
                              onInputAssosName($event, associatesUsersIndex)
                            "
                            :state="users.validationStateAssosName"
                            data-test-id="third-party-details__associate-entities-input"
                          ></b-form-input>
                          <p
                            v-if="users.validationStateAssosName == false"
                            class="text-feedback"
                          >
                            {{ "Please Enter Associate Name" }}
                          </p>
                        </b-form-group>
                      </b-col>
                      <b-col cols="3" class="pr-0">
                        <b-button
                          :disabled="showCreditsAlert"
                          @click="
                            removeAssociateRow(associatesUsersIndex, users)
                          "
                          variant="outline-danger"
                          size="sm"
                          v-if="associatesUsersIndex > 0"
                          data-test-id="third-party-details__associate-entities-remove"
                        >
                          <font-awesome-icon icon="trash" />
                        </b-button>

                        <b-button
                          :disabled="showCreditsAlert"
                          @click="
                            removeFirstRowAssociateRow(
                              associatesUsersIndex,
                              users
                            )
                          "
                          variant="outline-danger"
                          size="sm"
                          v-show="
                            associatesUsersIndex == 0
                              ? associatesUsers[0].assoName
                                ? true
                                : associatesUsers[0].entityType
                                ? true
                                : false
                              : false
                          "
                          data-test-id="third-party-details__associate-entities-remove"
                        >
                          <font-awesome-icon icon="trash" />
                        </b-button>
                        <span
                          v-if="
                            users.assoName &&
                            headerConfigData?.maximumAssociate !=
                              associatesUsers.length
                          "
                        >
                          <b-button
                            :disabled="
                              showCreditsAlert ||
                              headerConfigData?.maximumAssociate ==
                                associatesUsers.length
                            "
                            class="ml-2"
                            size="sm"
                            variant="outline-success"
                            @click="addAssociateRow(associatesUsersIndex)"
                            v-show="
                              associatesUsersIndex == associatesUsers.length - 1
                            "
                            data-test-id="third-party-details__associate-entities-add"
                          >
                            <font-awesome-icon icon="plus" />
                          </b-button>
                        </span>
                      </b-col>
                    </b-row>
                    <b-alert
                      v-model="isChangeInAssociates"
                      v-if="isChangeInAssociates && isEditModeEnabled"
                      variant="warning"
                    >
                      Selected actions are now invalidated and should be rerun
                    </b-alert>
                    <b-overlay :show="isAssoLoading">
                      <div v-if="showingAssociatesTable" class="table-col">
                        <div v-if="isAssoLoading">
                          <b-skeleton-table
                            :rows="5"
                            :columns="4"
                            :table-props="{ bordered: true, striped: true }"
                          ></b-skeleton-table>
                        </div>
                        <!-- Verification Data Table -->
                        <div
                          :style="
                            isLoadingAssociatesTable ? 'display: none;' : ''
                          "
                        >
                          <div
                            :class="
                              openCorporatesCompanyOfficersItems.length
                                ? ''
                                : 'd-none'
                            "
                          >
                            <b-table
                              :aria-disabled="isFetchResultsDisabled"
                              ref="table"
                              id="associated-table"
                              :fields="openCorporatesCompanyOfficersFields"
                              :items="openCorporatesCompanyOfficersItems"
                              :sort-by.sync="associatedSortBy"
                              :sort-desc.sync="associatedSortDesc"
                              responsive="sm"
                              small
                              outlined
                              :per-page="50"
                              :current-page="associatedCurrentPage"
                            >
                              <template v-slot:cell(select)="data">
                                <Checkbox
                                  class="third-party-details__associate-entities-select"
                                  :disabled="
                                    showCreditsAlert ||
                                    (!selectedCompanyOfficers[data?.index] &&
                                      headerConfigData?.maximumAssociate ==
                                        associatesUsers.length)
                                  "
                                  @update:modelValue="
                                    selectAssociatedUsersData($event, data)
                                  "
                                  v-model="selectedCompanyOfficers[data?.index]"
                                  label="associates"
                                  labelHidden
                                />
                              </template>
                              <template #cell(jobTitles)="data">
                                {{ data?.item?.jobTitles?.[0]?.title }}
                              </template>
                            </b-table>
                            <div
                              v-if="
                                openCorporatesCompanyOfficersItems.length == 0
                              "
                              class="text-center"
                            >
                              <p>
                                Suggested associate data is not available for
                                the selected entity
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </b-overlay>
                  </div>
                </Accordion>
                <!-- Associate screenings card end  -->

                <!-- card Additional Details start-->
                <Accordion
                  class="third-party-details__accordion-spacing"
                  title="Additional details"
                  :collapsed="collapse.AdditionalDetails"
                >
                  <!-- additonal details form fields -->
                  <div ref="third-party-details__msq-wrapper">
                    <!-- if any MSQ is active -->
                    <div v-if="checkIsMsqActive()">
                      <b-form-group>
                        <label> Contact Name </label>
                        <b-form-input
                          :disabled="showCreditsAlert"
                          id="contact-person-feedback"
                          required
                          placeholder="---Required---"
                          v-model="v$.additionalDetailForm.contactName.$model"
                          :state="
                            v$.additionalDetailForm.contactName.$dirty
                              ? !v$.additionalDetailForm.contactName.$error
                              : null
                          "
                          data-test-id="third-party-details__additional-details-contact-name"
                        ></b-form-input>
                        <b-form-invalid-feedback id="contact-person-feedback">
                          A Required field has no value.
                        </b-form-invalid-feedback>
                      </b-form-group>
                      <b-form-group>
                        <label> Contact Email </label>
                        <b-form-input
                          type="email"
                          placeholder="---Required---"
                          @input="isExistingContactEmail = false"
                          :disabled="showCreditsAlert"
                          id="contact-email-feedback"
                          v-model="v$.additionalDetailForm.contactEmail.$model"
                          :state="
                            isExistingContactEmail
                              ? false
                              : v$.additionalDetailForm.contactEmail.$dirty
                              ? !v$.additionalDetailForm.contactEmail.$error
                              : null
                          "
                          data-test-id="third-party-details__additional-details-contact-email"
                        ></b-form-input>
                        <b-form-invalid-feedback v-if="isExistingContactEmail">
                          {{ existingContactEmailMessage }}
                        </b-form-invalid-feedback>
                        <b-form-invalid-feedback
                          v-if="v$.additionalDetailForm.contactEmail.$error"
                          id="contact-email-feedback"
                          >Please enter a valid email</b-form-invalid-feedback
                        >
                      </b-form-group>
                      <b-form-group
                        class="third-party-details__contact-language"
                        description="In order to update the contact language, you'll need to have a contact name and contact email present"
                      >
                        <label> Contact Language </label>
                        <v-select
                          :disabled="showCreditsAlert"
                          placeholder="---Required---"
                          v-model="
                            v$.additionalDetailForm.contactLanguage.$model
                          "
                          :state="
                            v$.additionalDetailForm.contactLanguage.$dirty
                              ? !v$.additionalDetailForm.contactLanguage.$error
                              : null
                          "
                          :class="{
                            'form-control is-invalid p-0':
                              v$.additionalDetailForm.contactLanguage.$error
                          }"
                          :options="formsLanguagesList"
                          @update:modelValue="setContactLanguage"
                          label="languages"
                          :clearable="false"
                          data-test-id="third-party-details__additional-details-contact-language"
                        >
                        </v-select>
                        <b-form-invalid-feedback id="contact-language-feedback"
                          >A Required field has no
                          value.</b-form-invalid-feedback
                        >
                      </b-form-group>
                    </div>

                    <!-- else -->
                    <div v-else>
                      <!-- here -->
                      <b-form-group>
                        <label> Contact Name </label>
                        <b-form-input
                          :placeholder="getPlaceholderText"
                          :disabled="isFetchResultsDisabled || showCreditsAlert"
                          id="contact-person-feedback"
                          :required="true"
                          v-model="additionalDetailForm.contactName"
                          :state="!getContactNameErrorMessage"
                          data-test-id="third-party-details__additional-details-contact-name"
                        />
                        <b-form-invalid-feedback
                          v-if="getContactEmailErrorMessage"
                          id="contact-person-feedback"
                        >
                          A Required field has no value.
                        </b-form-invalid-feedback>
                      </b-form-group>
                      <b-form-group>
                        <label> Contact Email </label>
                        <b-form-input
                          :placeholder="getPlaceholderText"
                          type="email"
                          @input="isExistingContactEmail = false"
                          :required="true"
                          :disabled="isFetchResultsDisabled || showCreditsAlert"
                          id="contact-email-feedback"
                          v-model="v$.additionalDetailForm.contactEmail.$model"
                          :state="!getContactEmailErrorMessage"
                          data-test-id="third-party-details__additional-details-contact-email"
                        />
                        <b-form-invalid-feedback id="contact-email-feedback">
                          {{ getContactEmailErrorMessage }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                      <b-form-group
                        class="third-party-details__contact-language"
                        description="In order to update the contact language, you'll need to have a contact name and contact email present"
                      >
                        <label> Contact Language </label>
                        <v-select
                          class="custom-dropdown-toggle-class"
                          :disabled="showCreditsAlert"
                          label="languages"
                          v-model="additionalDetailForm.contactLanguage"
                          :options="formsLanguagesList"
                          @update:modelValue="setContactLanguage"
                          :clearable="false"
                          data-test-id="third-party-details__additional-details-contact-language"
                        >
                        </v-select>
                      </b-form-group>
                    </div>
                    <b-form-group>
                      <label> Contact Number </label>
                      <b-form-input
                        :disabled="showCreditsAlert"
                        type="number"
                        v-model="additionalDetailForm.contactPhone"
                        onkeydown="return event.keyCode !== 69"
                        data-test-id="third-party-details__additional-details-contact-number"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group v-if="getSystemOwnerListOptions.length">
                      <label
                        class="third-party-details__system-owner-label-wrapper"
                      >
                        <BaseText text="System Owner" tag="span" />
                        <Tooltip
                          class="third-party-details__system-owner-tooltip"
                          text="System Owner is the user who will receive default notifications from the platform"
                        />
                      </label>
                      <v-select
                        id="tp-system-owner"
                        name="tp-system-owner"
                        label="text"
                        full-width
                        v-model="selectedSystemOwner"
                        :options="getSystemOwnerListOptions"
                        @update:modelValue="onChangeSystemOwner"
                        :searchable="true"
                      />
                    </b-form-group>
                    <ThirdPartyCustomAttributes
                      v-if="Object.keys(customAttributesValues).length"
                      :attributes="dynamicCustomAttributesList"
                      :values="customAttributesValues"
                    />
                  </div>
                </Accordion>
                <!-- card Additional Details end-->
                <Accordion
                  class="third-party-details__accordion-spacing"
                  title="Premium Corporate Data"
                  :collapsed="collapse.isPremiumCorporateData"
                >
                  <BaseLoader v-if="isLoadingAssociatesTable" />
                  <PremiumCorporateData
                    v-else
                    :premium-corporate-data="premiumCorporateData"
                    :has-premium-corporate-data-products="
                      hasPremiumCorporateDataProducts
                    "
                  />
                </Accordion>
                <!-- card Results start -->
                <Accordion
                  class="third-party-details__accordion-spacing"
                  v-if="showingReviewResult"
                  title="Results"
                >
                  <div v-if="showingReviewResult">
                    <b-overlay :show="isLoadingResultData" rounded="sm">
                      <b-row class="mt-1" v-if="!isLoadingResultData">
                        <b-col class="mt-2" cols="12">
                          <h6 v-if="hideSubmitButton" class="text-center">
                            Your request(s) have been executed.
                          </h6>
                        </b-col>
                        <b-col
                          class="mt-2"
                          cols="12"
                          v-if="
                            searchReportData &&
                            searchReportData.shield_vendor_infos &&
                            searchReportData.shield_vendor_infos.risk_level &&
                            searchReportData.shield_vendor_infos.risk_level != 0
                          "
                        >
                          <!-- result component -->
                          <ReviewResult
                            :OCStatesData="OCStatesData"
                            :searchReportData="searchReportData"
                            :tpDnbData="tpDnbData"
                            :tpDnbSelectedAltaresTableItemData="
                              tpDnbSelectedAltaresTableItemData
                            "
                            :beneficialOwnershipRegistrationData="
                              beneficialOwnershipRegistrationData
                            "
                            :showNoteText="showNoteText"
                          />
                        </b-col>
                      </b-row>
                    </b-overlay>
                  </div>
                </Accordion>
                <!-- card Results end -->
              </b-col>
            </b-row>
          </b-col>
          <!-- 8 column div end -->

          <!-- 4 column div start -->

          <!-- List view -->
          <b-col
            :class="[
              showCreditsAlert
                ? 'cols-4-with-voucheralert-height-styles'
                : 'third-party-details__body--screen-height',
              'third-party-details__actions-container'
            ]"
            cols="3"
          >
            <!-- Card Available Actions start  -->
            <div class="third-party-details__actions-card">
              <!-- Card Available Actions header-->
              <div class="third-party-details__actions-card-header">
                <BaseText
                  text="Available actions"
                  :size="typographySize.BODY_TEXT_BOLD"
                  hasDefaultSpacingRemoved
                />
                <Link
                  :anchor-options="{
                    href: knowledgeCentreLinks.AVAILABLE_ACTIONS
                  }"
                  :icon-options="{
                    icon: icons.QUESTION,
                    shape: shapes.CIRCLE,
                    size: 12,
                    theme: themes.ETHIXBASE_PRIMARY_INVERSE
                  }"
                />
              </div>
              <!-- Card Available Actions content-->
              <div class="third-party-details__actions-card-content">
                <b-list-group class="third-party-details__actions-list">
                  <b-list-group-item
                    :disabled="
                      (showingReviewResult && !isEditModeEnabled) ||
                      isThirdPartyArchived
                    "
                    @click="onSelectExtraActionOccur(action)"
                    v-for="(action, index) in extraActionsBtn"
                    :key="index"
                    size="sm"
                    :class="[
                      'third-party-details__actions-item',
                      action.isActive &&
                        'third-party-details__actions-item--active',
                      'third-party-details__actions-item--clickable'
                    ]"
                    :data-test-id="
                      getActionDataTestId(action.btnText, 'available-action')
                    "
                  >
                    <div class="third-party-details__actions-item-container">
                      <div>
                        <BaseIcon v-bind="getSelectedIcon(action.isActive)" />
                      </div>
                      <div>
                        <div v-if="action.actionType == 'edd'">
                          <div @click="onSelectExtraActionOccurEdd(action)">
                            <b
                              class="d-flex"
                              :style="{
                                color: isThirdPartyArchived ? 'lightgray' : ''
                              }"
                            >
                              {{ action.btnText }}
                            </b>
                          </div>
                          <b-form-group v-if="checkIsEddActive()">
                            <v-select
                              class="input-select"
                              :disabled="isThirdPartyArchived"
                              placeholder="-- EDD Order Types --"
                              :maxHeight="100"
                              v-model="v$.selecteditem.$model"
                              :state="
                                v$.selecteditem.$dirty
                                  ? !v$.selecteditem.$error
                                  : null
                              "
                              :class="{
                                'form-control is-invalid p-0':
                                  v$.selecteditem.$error
                              }"
                              label="name"
                              track-by="name"
                              :options="listOfEDD"
                              @update:modelValue="setSelectedItem($event)"
                              :clearable="false"
                            >
                            </v-select>
                            <b-form-invalid-feedback id="ordertype"
                              >There are no selected
                              actions.</b-form-invalid-feedback
                            >
                          </b-form-group>
                          <b-form-group v-else class="w-100">
                            <v-select
                              class="input-select"
                              :disabled="isThirdPartyArchived"
                              placeholder="-- EDD Order Types --"
                              :maxHeight="100"
                              v-model="selecteditem"
                              label="name"
                              track-by="name"
                              :options="listOfEDD"
                              @update:modelValue="setSelectedItem($event)"
                              :clearable="false"
                            >
                              <template slot="option" slot-scope="option">
                                <span>{{ "&nbsp;" + option.name }}</span>
                              </template>
                            </v-select>
                          </b-form-group>
                          <b-overlay
                            :show="isLoadingElements || isLoadingPackages"
                          >
                            <b-list-group flush>
                              <b-list-group-item
                                v-for="element in allElements"
                                :key="element.id"
                                style="
                                  display: flex;
                                  padding: 0.35rem 1rem !important;
                                "
                              >
                                <font-awesome-icon
                                  class="m-2"
                                  :icon="
                                    element.package_products_id > 0
                                      ? 'check'
                                      : 'times'
                                  "
                                  :style="{
                                    color:
                                      element.package_products_id > 0
                                        ? '#28a745'
                                        : '#CBCFD1'
                                  }"
                                />
                                <p
                                  class="m-0"
                                  :style="{
                                    color:
                                      element.package_products_id > 0
                                        ? ''
                                        : '#CBCFD1'
                                  }"
                                >
                                  <span style="font-size: 14px !important">{{
                                    element.product_name
                                  }}</span>
                                </p>
                              </b-list-group-item>
                            </b-list-group>
                            <div
                              class="third-party-details__actions mb-1"
                              v-if="
                                parseInt(
                                  selectedItemArray.client_packages_tat
                                ) > 0
                              "
                            >
                              <font-awesome-icon
                                class="m-1"
                                icon="user-friends"
                              />
                              <div
                                style="display: flex; flex-direction: column"
                              >
                                <p v-if="isMaximumLimitOvercomedByAssociates">
                                  {{ associatesUsers.length }} associates are
                                  indicated on the third party record.
                                  Additional fees may apply
                                </p>
                              </div>
                            </div>
                            <div
                              class="third-party-details__actions mb-1"
                              v-if="
                                parseInt(
                                  selectedItemArray.client_packages_tat
                                ) > 0
                              "
                            >
                              <font-awesome-icon class="m-1" icon="clock" />
                              <p
                                v-if="
                                  selectedItemArray.client_packages_tat_max > 0
                                "
                              >
                                {{ selectedItemArray.client_packages_tat }} -
                                {{ selectedItemArray.client_packages_tat_max }}
                                Days Turn Around Time
                              </p>
                              <p v-else>
                                {{ selectedItemArray.client_packages_tat }} Days
                                Turn Around Time
                              </p>
                            </div>
                          </b-overlay>
                          <a
                            href="/edd-report-types"
                            target="_blank"
                            style="text-decoration: underline"
                            >What is in each report type?</a
                          >
                        </div>
                        <div
                          v-else-if="
                            action.actionType ===
                            thirdPartyActionType.T_CERTIFICATION
                          "
                        >
                          <div @click="getActionType(action)">
                            <p
                              :class="
                                isThirdPartyArchived
                                  ? 'third-party-details__tcertification-text--archived'
                                  : 'third-party-details__tcertification-text--bold'
                              "
                            >
                              {{ action.btnText }}
                            </p>
                          </div>

                          <Select
                            v-if="!isLoadingOptions"
                            id="t-certification"
                            name="t-certification"
                            :isRequired="action?.isActive"
                            :options="tCertificationOptions"
                            @change="onChangeTCertItems"
                          />
                          <BaseText
                            v-if="isTCertActive(action)"
                            text="Please select any item from list"
                            theme="error-inverse"
                            :size="typographySize.BODY_TEXT_SMALL"
                          />
                        </div>
                        <div
                          v-else-if="
                            action.actionType ===
                            thirdPartyActionType.ESG_METRIC
                          "
                        >
                          <div @click="getActionType(action)">
                            <p
                              :class="
                                isThirdPartyArchived
                                  ? 'third-party-details__tcertification-text--archived'
                                  : 'third-party-details__tcertification-text--bold'
                              "
                            >
                              {{ action.btnText }}
                            </p>
                          </div>

                          <Select
                            v-if="!isLoadingOptions"
                            id="t-certification"
                            name="t-certification"
                            :isRequired="action?.isActive"
                            :options="ESGMetricsOptions"
                            @change="onChangeEsgmItems"
                          />
                          <BaseText
                            v-if="isEsgmActive(action)"
                            text="Please select any item from list"
                            theme="error-inverse"
                            :size="typographySize.BODY_TEXT_SMALL"
                          />
                        </div>
                        <BaseText
                          v-else
                          :text="action.btnText"
                          hasDefaultSpacingRemoved
                          :size="typographySize.BODY_TEXT_BOLD"
                          :style="{
                            color: isThirdPartyArchived ? 'lightgray' : ''
                          }"
                        />
                      </div>
                    </div>
                  </b-list-group-item>
                </b-list-group>
              </div>
            </div>
            <!-- Card Available Actions end  -->

            <!-- Card Previously Performed Actions start  -->
            <div class="third-party-details__actions-card">
              <!-- Card Previously Performed Actions header-->
              <div class="third-party-details__actions-card-header">
                <BaseText
                  text="Previously performed actions"
                  :size="typographySize.BODY_TEXT_BOLD"
                  hasDefaultSpacingRemoved
                />
                <Link
                  :anchor-options="{
                    href: knowledgeCentreLinks.PREVIOUSLY_PERFORMED_ACTIONS
                  }"
                  :icon-options="{
                    icon: icons.QUESTION,
                    shape: shapes.CIRCLE,
                    size: 12,
                    theme: themes.ETHIXBASE_PRIMARY_INVERSE
                  }"
                />
              </div>
              <div class="third-party-details__actions-card-content">
                <!-- Card Previously Performed Actions content-->
                <BaseText
                  v-if="!isEditModeEnabled && !showingReviewResult"
                  text="No actions have occurred on this entity"
                  :size="typographySize.BODY_TEXT_SMALL"
                  hasDefaultSpacingRemoved
                />
                <div v-if="showingReviewResult || isEditModeEnabled">
                  <b-overlay :show="isLoadingResultData" rounded="sm">
                    <b-list-group
                      v-if="!isLoadingResultData"
                      class="third-party-details__actions-list"
                    >
                      <b-list-group-item
                        v-for="(action, index) in completedActions"
                        :key="index"
                        size="sm"
                        class="third-party-details__actions-item"
                        :style="{
                          backgroundColor:
                            action.imgName == 'ok' ||
                            action.imgName == 'RemediatedOK'
                              ? '#d0f2d2'
                              : action.imgName == 'HighAlert' ||
                                action.imgName == 'remediatedHighAlert'
                              ? '#f9d1ce'
                              : action.imgName == 'inprogress'
                              ? '#e3f4fa'
                              : action.imgName == 'WarningAlert'
                              ? '#fcf2d4'
                              : 'white'
                        }"
                        :data-test-id="
                          getActionDataTestId(
                            action.btnText,
                            'previously-performed-action'
                          )
                        "
                      >
                        <div
                          class="third-party-details__previously-performed-actions"
                        >
                          <div>
                            <div
                              v-if="isActionTypeTCertOrEsgm(action)"
                              class="third-party-details__previously-performed-actions-completed"
                            >
                              Completed
                            </div>
                            <BaseIcon
                              v-else
                              v-bind="
                                getIcon(action.imgName, iconSizes.DEFAULT)
                              "
                            />
                          </div>
                          <div style="margin-left: 12px">
                            <b class="d-flex">
                              {{ action.btnText }}
                            </b>
                            <p
                              v-if="action.requestedDate"
                              style="
                                font-style: italic;
                                color: gray;
                                font-size: 14px;
                              "
                            >
                              Days since last ordered:
                              {{ datediff(action.requestedDate) }}
                            </p>
                          </div>
                        </div>
                      </b-list-group-item>
                      <b-list-group-item
                        :disabled="showingReviewResult || isEditModeEnabled"
                        v-for="(action, index) in removedActions"
                        :key="'A' + index"
                        size="sm"
                        :style="{
                          backgroundColor:
                            action.imgName == 'ok' ||
                            action.imgName == 'RemediatedOK'
                              ? '#d0f2d2'
                              : action.imgName == 'HighAlert' ||
                                action.imgName == 'remediatedHighAlert'
                              ? '#f9d1ce'
                              : action.imgName == 'inprogress'
                              ? '#e3f4fa'
                              : action.imgName == 'WarningAlert'
                              ? '#fcf2d4'
                              : 'white',
                          color: 'black',
                          width: '100%',
                          border: '0px'
                        }"
                        :data-test-id="
                          getActionDataTestId(
                            action.btnText,
                            'previously-performed-action'
                          )
                        "
                      >
                        <div style="display: flex; align-items: baseline">
                          <div>
                            <BaseIcon
                              v-bind="
                                getIcon(action.imgName, iconSizes.DEFAULT)
                              "
                            />
                          </div>
                          <div style="margin-left: 12px">
                            <b class="d-flex">
                              {{ action.btnText }}
                            </b>
                            <p
                              v-if="action.requestedDate"
                              style="
                                font-style: italic;
                                color: gray;
                                font-size: 14px;
                              "
                            >
                              Days since last ordered:
                              {{ datediff(action.requestedDate) }}
                            </p>
                          </div>
                        </div>
                      </b-list-group-item>
                    </b-list-group>
                  </b-overlay>
                </div>
              </div>
            </div>
            <!-- Card Previously Performed Actions end  -->

            <!-- Card Default Actions start  -->
            <div class="third-party-details__actions-card">
              <!-- Card Default Actions header-->
              <div class="third-party-details__actions-card-header">
                <BaseText
                  text="Default actions"
                  :size="typographySize.BODY_TEXT_BOLD"
                  hasDefaultSpacingRemoved
                />
                <Link
                  :anchor-options="{
                    href: knowledgeCentreLinks.DEFAULT_ACTIONS
                  }"
                  :icon-options="{
                    icon: icons.QUESTION,
                    shape: shapes.CIRCLE,
                    size: 12,
                    theme: themes.ETHIXBASE_PRIMARY_INVERSE
                  }"
                />
              </div>
              <!-- Card Default Actions content-->
              <div class="third-party-details__actions-card-content">
                <BaseText
                  v-if="isEditModeEnabled || !actionsOccurBtns.length"
                  text="No actions"
                  :size="typographySize.BODY_TEXT_SMALL"
                  hasDefaultSpacingRemoved
                />
                <div v-else>
                  <b-list-group>
                    <b-list-group-item
                      v-for="(action, index) in actionsOccurBtns"
                      :key="index"
                      class="third-party-details__actions-item"
                      style="background-color: #d0f2d2"
                      :data-test-id="
                        getActionDataTestId(action.btnText, 'default-action')
                      "
                    >
                      <b-row style="display: flex; align-items: center">
                        <b-col cols="4">
                          <BaseIcon
                            :icon="icons.PLUS"
                            :shape="shapes.SQUARE"
                            :theme="themes.SUCCESS_WHITE"
                            :size="iconSizes.DEFAULT"
                          />
                        </b-col>
                        <b-col cols="8">
                          <div v-if="action.actionType == 'edd'">
                            <p>
                              <b class="d-flex">{{ action.btnText }}</b>
                            </p>
                            <b-form-group v-if="checkIsEddActive()">
                              <v-select
                                :disabled="
                                  isThirdPartyArchived || hideSubmitButton
                                "
                                placeholder="-- EDD Order Type --"
                                :maxHeight="100"
                                v-model="v$.selecteditem.$model"
                                :state="
                                  v$.selecteditem.$dirty
                                    ? !v$.selecteditem.$error
                                    : null
                                "
                                :class="{
                                  'form-control is-invalid p-0':
                                    v$.selecteditem.$error
                                }"
                                label="name"
                                track-by="name"
                                :options="listOfEDD"
                                @update:modelValue="setSelectedItem($event)"
                                :clearable="false"
                              >
                              </v-select>
                              <b-form-invalid-feedback id="ordertype"
                                >There are no selected
                                actions.</b-form-invalid-feedback
                              >
                            </b-form-group>
                            <b-form-group v-else class="w-100">
                              <v-select
                                :disabled="
                                  isThirdPartyArchived || hideSubmitButton
                                "
                                placeholder="-- EDD Order Type --"
                                :maxHeight="100"
                                v-model="selecteditem"
                                label="name"
                                track-by="name"
                                :options="listOfEDD"
                                @update:modelValue="setSelectedItem($event)"
                                :clearable="false"
                              >
                                <template slot="option" slot-scope="option">
                                  <span>{{ "&nbsp;" + option.name }}</span>
                                </template>
                              </v-select>
                            </b-form-group>
                            <b-overlay
                              :show="isLoadingElements || isLoadingPackages"
                            >
                              <b-list-group flush>
                                <b-list-group-item
                                  v-for="element in allElements"
                                  :key="element.id"
                                  style="
                                    display: flex;
                                    padding: 0.35rem 1rem !important;
                                  "
                                >
                                  <font-awesome-icon
                                    class="m-2"
                                    :icon="
                                      element.package_products_id > 0
                                        ? 'check'
                                        : 'times'
                                    "
                                    :style="{
                                      color:
                                        element.package_products_id > 0
                                          ? '#28a745'
                                          : '#CBCFD1'
                                    }"
                                  />
                                  <p
                                    class="m-0"
                                    :style="{
                                      color:
                                        element.package_products_id > 0
                                          ? ''
                                          : '#CBCFD1'
                                    }"
                                  >
                                    <span style="font-size: 14px !important">{{
                                      element.product_name
                                    }}</span>
                                  </p>
                                </b-list-group-item>
                              </b-list-group>

                              <div
                                class="third-party-details__actions mb-1"
                                v-if="
                                  parseInt(
                                    selectedItemArray.client_packages_tat
                                  ) > 0
                                "
                              >
                                <p v-if="isMaximumLimitOvercomedByAssociates">
                                  {{ associatesUsers.length }} associates are
                                  indicated on the third party record.
                                  Additional fees may apply
                                </p>
                              </div>
                              <div
                                class="third-party-details__actions mb-1"
                                v-if="
                                  parseInt(
                                    selectedItemArray.client_packages_tat
                                  ) > 0
                                "
                              >
                                <font-awesome-icon class="m-1" icon="clock" />
                                <p
                                  v-if="
                                    selectedItemArray.client_packages_tat_max >
                                    0
                                  "
                                >
                                  {{ selectedItemArray.client_packages_tat }} -
                                  {{
                                    selectedItemArray.client_packages_tat_max
                                  }}
                                  Days Turn Around Time
                                </p>
                                <p v-else>
                                  {{ selectedItemArray.client_packages_tat }}
                                  Days Turn Around Time
                                </p>
                              </div>
                            </b-overlay>
                            <a
                              href="/edd-report-types"
                              target="_blank"
                              style="text-decoration: underline"
                              >What is in each report type?</a
                            >
                          </div>
                          <div
                            v-else-if="
                              action.actionType ===
                              thirdPartyActionType.T_CERTIFICATION
                            "
                          >
                            <BaseText
                              :size="typographySize.BODY_TEXT_BOLD"
                              :text="action.btnText"
                            />
                            <Select
                              v-if="!isLoadingOptions"
                              id="t-certification"
                              name="t-certification"
                              :isRequired="action?.isActive"
                              :options="tCertificationOptions"
                              @change="onChangeTCertItems"
                            />
                            <BaseText
                              v-if="isTCertActive(action)"
                              text="Please select any item from list"
                              theme="error-inverse"
                              :size="typographySize.BODY_TEXT_SMALL"
                            />
                          </div>
                          <div
                            v-else-if="
                              action.actionType ===
                              thirdPartyActionType.ESG_METRIC
                            "
                          >
                            <BaseText
                              :size="typographySize.BODY_TEXT_BOLD"
                              :text="action.btnText"
                            />
                            <Select
                              v-if="!isLoadingOptions"
                              id="t-certification"
                              name="t-certification"
                              :isRequired="action?.isActive"
                              :options="ESGMetricsOptions"
                              @change="onChangeEsgmItems"
                            />
                            <BaseText
                              v-if="isEsgmActive(action)"
                              text="Please select any item from list"
                              theme="error-inverse"
                              :size="typographySize.BODY_TEXT_SMALL"
                            />
                          </div>
                          <p v-else>
                            <b class="d-flex">{{ action.btnText }}</b>
                          </p>
                        </b-col>
                      </b-row>
                    </b-list-group-item>
                  </b-list-group>
                </div>
              </div>
            </div>
            <!-- Card Default Actions end  -->

            <!-- Card My Risk Rating start  -->
            <div class="third-party-details__actions-card">
              <!-- Card My Risk Rating header-->
              <div class="third-party-details__actions-card-header">
                <BaseText
                  text="My risk rating"
                  :size="typographySize.BODY_TEXT_BOLD"
                  hasDefaultSpacingRemoved
                />
                <Link
                  :anchor-options="{
                    href: knowledgeCentreLinks.MY_RISK_RATING
                  }"
                  :icon-options="{
                    icon: icons.QUESTION,
                    shape: shapes.CIRCLE,
                    size: 12,
                    theme: themes.ETHIXBASE_PRIMARY_INVERSE
                  }"
                />
              </div>
              <!-- Card My Risk Rating content-->
              <div class="third-party-details__actions-card-content">
                <div
                  :style="{
                    backgroundColor:
                      isThirdPartyArchived || isLoadingResultReview
                        ? '#e9ecef'
                        : 'white'
                  }"
                  data-test-id="third-party-details__risk-rating"
                >
                  <b-dropdown
                    :disabled="isLoadingResultReview || isThirdPartyArchived"
                    size="sm"
                    variant="outline"
                    toggle-class="text-decoration-none"
                    no-caret
                    menu-class="risk-rating-drop-down"
                    data-test-id="third-party-details__risk-rating-dropdown"
                  >
                    <template #button-content>
                      <div class="third-party-details__risk-rating">
                        <div>
                          <BaseIcon v-bind="selectedRiskRatingIcon" />
                        </div>
                        <BaseText
                          :text="`Risk Rating - ${
                            selectedRiskRatingText || ''
                          }`"
                          hasDefaultSpacingRemoved
                          :size="typographySize.BODY_TEXT_BOLD"
                        />
                        <font-awesome-icon class="text-muted" icon="pen" />
                      </div>
                    </template>
                    <b-dropdown-item style="width: 300px">
                      <div class="d-flex">
                        <div
                          class="mx-1"
                          v-for="(item, index) in riskRatingJsonData
                            .myRiskRatingData.status"
                          :key="index"
                          @click="selectRiskRatingOption(item)"
                          style="cursor: pointer"
                        >
                          <BaseIcon
                            :icon="item.icon"
                            :shape="item.shape"
                            :theme="item.theme"
                            :size="iconSizes.DEFAULT"
                            :border="item.border"
                          />
                        </div>
                      </div>
                    </b-dropdown-item>
                  </b-dropdown>
                  <div>
                    <b-form-group class="mb-0">
                      <b-form-textarea
                        v-if="!onChangeRatingStatus"
                        :disabled="isLoadingResultReview"
                        :state="validateRiskRatingComment"
                        placeholder="Please include reasons for change"
                        @input="inputRiskRatingComment"
                        v-model="riskRatingComment"
                        data-test-id="third-party-details__risk-rating-comment"
                        class="third-party-details__risk-rating-comment"
                      ></b-form-textarea>

                      <b-form-invalid-feedback id="contact-person-feedback"
                        >This is a Required field and Notes can be of maximum
                        250 characters</b-form-invalid-feedback
                      >
                    </b-form-group>
                  </div>
                </div>
              </div>
            </div>
            <!-- Card My Risk Rating end  -->
          </b-col>
        </b-row>
      </b-overlay>
      <div class="third-party-details__footer">
        <div class="third-party-details__footer-section">
          <CallToAction
            class="third-party-details__footer-btn"
            value="Discard changes"
            icon="history"
            :iconSize="14"
            hasIconWithText
            :theme="themes.SECONDARY"
            @click="toggleDiscardChangesModalVisibility"
            data-test-id="third-party-details__action-button--discard-changes"
          />
        </div>
        <div class="third-party-details__footer-section">
          <CallToAction
            class="third-party-details__footer-btn"
            :value="isEditModeEnabled ? 'Update' : 'Submit'"
            icon="chevron-right"
            :iconSize="14"
            hasIconWithText
            :is-disabled="isSubmitButtonDisabled"
            @click="onFormSubmission"
            :data-test-id="getActionButtonDataTestId()"
          />
        </div>
      </div>
    </div>

    <!--switch third party modal-->
    <b-modal
      ref="modal-switch-tp"
      hide-header-close
      no-close-on-backdrop
      no-close-on-esc
      @ok="navigateToThirdPartyProfile()"
      @cancel="hideSwitchModal"
    >
      <p class="my-4">Are you sure you want to switch to this Third Party?</p>
    </b-modal>
    <b-modal
      ref="modal-rerun-screening"
      hide-header-close
      no-close-on-backdrop
      no-close-on-esc
      size="lg"
    >
      <template #modal-title>
        <h6>Updates require following actions to be re-run</h6>
      </template>
      <b-overlay :show="isLoadingResultReview" rounded="sm">
        <b-list-group>
          <h6>Please unselect the actions that you don't want to re-run</h6>
          <b-list-group-item
            @click="onSelectReScreeningActionOccur(index, action)"
            v-for="(action, index) in reScreeningActions"
            :key="index"
            size="sm"
            :style="{
              backgroundColor: action.isActive ? '#d0f2d2' : 'white',
              color: 'black',
              width: '100%',
              border: '0px'
            }"
          >
            <b-row style="display: flex; align-items: baseline">
              <b-col cols="3">
                <BaseIcon
                  v-if="isThirdPartyArchived"
                  :shape="shapes.SQUARE"
                  :border="border.DASHED"
                  :size="iconSizes.DEFAULT"
                />
                <CallToAction
                  v-else
                  type="button"
                  :icon="icons.PLUS"
                  :icon-size="35"
                  :icon-space="space.NONE"
                  :theme="selectedActionTheme(action.isActive)"
                  :has-icon-with-text="true"
                  @click="
                    action.actionType == 'edd'
                      ? onSelectReScreeningActionOccurEdd(index, action)
                      : null
                  "
                />
              </b-col>

              <b-col cols="7">
                <div v-if="action.actionType == 'edd'">
                  <p @click="onSelectReScreeningActionOccurEdd(index, action)">
                    <b
                      class="d-flex"
                      :style="{
                        color: isThirdPartyArchived ? 'lightgray' : ''
                      }"
                    >
                      {{ action.btnText }}
                    </b>
                  </p>
                  <b-form-group>
                    <v-select
                      class="input-select"
                      :disabled="isThirdPartyArchived"
                      placeholder="-- EDD Order Types --"
                      :maxHeight="100"
                      v-model="selecteditem"
                      label="name"
                      track-by="name"
                      :options="listOfEDD"
                      @update:modelValue="setSelectedItem($event)"
                      :clearable="false"
                    >
                      <template slot="option" slot-scope="option">
                        <span>{{ "&nbsp;" + option.name }}</span>
                      </template>
                    </v-select>
                    <div class="errorClass" v-if="checkRerunEddActive()">
                      There are no selected actions.
                    </div>
                  </b-form-group>
                  <b-overlay :show="isLoadingElements || isLoadingPackages">
                    <b-list-group flush v-if="selecteditem">
                      <b-list-group-item
                        v-for="element in allElements"
                        :key="element.id"
                        style="display: flex; padding: 0.35rem 1rem !important"
                      >
                        <font-awesome-icon
                          class="m-2"
                          :icon="
                            element.package_products_id > 0 ? 'check' : 'times'
                          "
                          :style="{
                            color:
                              element.package_products_id > 0
                                ? '#28a745'
                                : '#CBCFD1'
                          }"
                        />
                        <p
                          class="m-0"
                          :style="{
                            color:
                              element.package_products_id > 0 ? '' : '#CBCFD1'
                          }"
                        >
                          <span style="font-size: 14px !important">{{
                            element.product_name
                          }}</span>
                        </p>
                      </b-list-group-item>
                    </b-list-group>
                    <div
                      class="third-party-details__actions mb-1"
                      v-if="
                        parseInt(selectedItemArray.client_packages_tat) > 0 &&
                        selecteditem
                      "
                    >
                      <div style="display: flex; flex-direction: column">
                        <p v-if="isMaximumLimitOvercomedByAssociates">
                          {{ associatesUsers.length }} associates are indicated
                          on the third party record. Additional fees may apply
                        </p>
                      </div>
                    </div>
                    <div
                      class="third-party-details__actions mb-1"
                      v-if="
                        parseInt(selectedItemArray.client_packages_tat) > 0 &&
                        selecteditem
                      "
                    >
                      <font-awesome-icon class="m-1" icon="clock" />
                      <p v-if="selectedItemArray.client_packages_tat_max > 0">
                        {{ selectedItemArray.client_packages_tat }} -
                        {{ selectedItemArray.client_packages_tat_max }} Days
                        Turn Around Time
                      </p>
                      <p v-else>
                        {{ selectedItemArray.client_packages_tat }} Days Turn
                        Around Time
                      </p>
                    </div>
                  </b-overlay>
                  <a
                    href="/edd-report-types"
                    target="_blank"
                    style="text-decoration: underline"
                    v-if="selecteditem"
                    >What is in each report type?</a
                  >
                </div>
                <p v-else>
                  <b
                    class="d-flex"
                    :style="{
                      color: isThirdPartyArchived ? 'lightgray' : ''
                    }"
                  >
                    {{ action.btnText }}
                  </b>
                </p>
              </b-col>
            </b-row>
          </b-list-group-item>
        </b-list-group>
      </b-overlay>
      <template v-slot:modal-footer>
        <b-button
          class="mx-1"
          @click="updateThirdParty"
          size="md"
          variant="outline-success"
          >Submit</b-button
        >
      </template>
    </b-modal>
    <Modal
      v-if="isEntityVerificationPopupVisible"
      title="Entity verification"
      @close-modal="onCloseEntityVerificationModal"
      class="third-party-details__entity-verification-modal"
    >
      <template v-slot>
        <BaseText
          text="The Entity you have entered has not been verified against Premium Corporate Data."
        />
        <BaseText text="Do you wish to continue?" />
        <InputErrorMessage
          v-if="!responseSuccess"
          :error="submitErrorMessage"
        />
      </template>
      <template v-slot:left>
        <CallToAction
          value="No - Return to Entity details to verify"
          @click="onCloseEntityVerificationModal"
          :is-disabled="isLoading"
        />
      </template>
      <template v-slot:right>
        <CallToAction
          value="Yes - Complete Entity submission"
          @click="addThirdParty"
          :is-error="hasErrorInSubmit"
          :is-success="responseSuccess"
          :is-loading="isLoading"
        />
      </template>
    </Modal>
    <Modal
      v-if="isEntityUpdatePopUpVisible"
      title="Confirm Update"
      @close-modal="onCloseEntityUpdateModal"
      class="third-party-details__entity-update-confirmation-modal"
    >
      <template v-slot>
        <BaseText
          text="Third party details will be updated to match the selected entity."
        />
      </template>
      <template v-slot:left>
        <CallToAction
          class="third-party-details__entity-update-confirmation-modal__footer-btn"
          :theme="themes.SECONDARY"
          value="Cancel"
          @click="onCloseEntityUpdateModal"
        />
      </template>
      <template v-slot:right>
        <CallToAction
          class="third-party-details__entity-update-confirmation-modal__footer-btn"
          value="OK"
          @click="onSubmitEntityUpdateModal"
        />
      </template>
    </Modal>
    <Modal
      v-if="isDiscardChangesModalVisible"
      @close-modal="toggleDiscardChangesModalVisibility"
      class="third-party-details__discard-changes-modal"
    >
      <template v-slot>
        <BaseText
          class="third-party-details__discard-changes-modal-text"
          hasDefaultSpacingRemoved
          text="Unsaved changes will be discarded. Do you wish to continue?"
        />
      </template>
      <template v-slot:left>
        <CallToAction
          :theme="themes.SECONDARY"
          value="Cancel"
          @click="toggleDiscardChangesModalVisibility"
        />
      </template>
      <template v-slot:right>
        <CallToAction value="Continue" @click="resetFormPostVerification" />
      </template>
    </Modal>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import uid from "uid";
import { mapGetters, mapMutations } from "vuex";
import {
  getIcon,
  getRiskRatingIcon,
  HTTP,
  makeAuthorizationHeader,
  navigateToURL,
  hasPermission
} from "@/utils";
import {
  attributeType,
  border,
  colors,
  companyOrganizationsKeys,
  companyOrganizationsTableHeaders,
  defaultIcons,
  direction,
  eddContracts,
  emptyOption,
  entityOperatingStatuses,
  getterName,
  icons,
  iconSizes,
  knowledgeCentreLinks,
  mutationName,
  orbiaSpecificConstants,
  permissionTypes,
  pillIcons,
  posthogEvents,
  ratingTypeValue,
  routeKeys,
  routeNames,
  shapes,
  sizes,
  space,
  tableOptions,
  themes,
  thirdPartyActionImageType,
  thirdPartyActionProductStatus,
  thirdPartyActionType,
  thirdPartyFormLanguage,
  infoMessages,
  thirdPartyType,
  timers,
  typographySize,
  urls,
  userMember,
  featurePermissionsKeys,
  countries
} from "@/constants";
import defaultActionJson from "@/utils/defaultAction.json";
import iconJson from "@/utils/icon.json";
import { EventBus } from "@/event-bus.js";
import { email, required } from "@vuelidate/validators";
import ReviewResult from "./reviewResult.vue";
import ThirdPartyCustomAttributes from "@/organisms/ThirdPartyCustomAttributes/ThirdPartyCustomAttributes";
import Select from "@/molecules/Select/Select";
import BaseText from "@/atoms/BaseText/BaseText";
import StickyTable from "@/molecules/StickyTable/StickyTable";
import { thirdPartyService } from "@/services";
import Checkbox from "@/molecules/Checkbox/Checkbox";
import { Helper } from "@/helpers";
import BaseLoader from "@/atoms/BaseLoader/BaseLoader";
import PremiumCorporateData from "@/organisms/PremiumCorporateData/PremiumCorporateData";
import permissionsMixin from "@/mixins/permissions/permissions.mixin";
import thirdPartyMethodsMixin from "@/mixins/thirdParty/thirdPartyMethods.mixin";
import Modal from "@/molecules/Modal/Modal";
import CallToAction from "@/atoms/CallToAction/CallToAction";
import Tooltip from "@/molecules/Tooltip/Tooltip";
import { makeMultiSelectCountriesCustomAttributeAPIDTO } from "@/organisms/CustomAttribute/CustomAttributes.dto";
import InputErrorMessage from "@/molecules/InputErrorMessage/InputErrorMessage";
import TextDisplayList from "@/molecules/TextDisplayList/TextDisplayList";
import BaseIcon from "@/atoms/BaseIcon/BaseIcon";
import Accordion from "@/atoms/Accordion/Accordion";
import BaseInfoBox from "@/atoms/BaseInfoBox/BaseInfoBox";
import Link from "@/molecules/Link/Link";
import BaseRadio from "@/atoms/BaseRadio/BaseRadio.vue";
import BaseBadge from "@/atoms/BaseBadge/BaseBadge.vue";
import ErrorPageTemplate from "@/templates/ErrorPageTemplate/ErrorPageTemplate";
import { useVuelidate } from "@vuelidate/core";

export default {
  name: "ThirdPartyDetailsDnb",
  inject: ["$posthog"],
  setup() {
    return {
      v$: useVuelidate()
    };
  },
  mixins: [permissionsMixin, thirdPartyMethodsMixin],
  components: {
    BaseBadge,
    BaseRadio,
    Link,
    Tooltip,
    ReviewResult,
    ThirdPartyCustomAttributes,
    Select,
    BaseText,
    StickyTable,
    BaseLoader,
    PremiumCorporateData,
    Modal,
    CallToAction,
    InputErrorMessage,
    BaseIcon,
    Accordion,
    BaseInfoBox,
    Checkbox,
    ErrorPageTemplate
  },
  data() {
    return {
      thirdPartyType,
      knowledgeCentreLinks,
      infoMessages,
      themes,
      icons,
      shapes,
      border,
      space,
      defaultIcons,
      iconSizes,
      direction,
      sizes,
      pillIcons,
      hasErrorInSubmit: false,
      submitErrorMessage: "",
      userTyping: false,
      riskRatingJsonData: iconJson,
      riskRatingComment: null,
      validateRiskRatingComment: null,
      riskRatingNotes: null,
      selectedRiskRatingText: null,
      selectedRiskRatingIcon: getRiskRatingIcon(
        ratingTypeValue.UNDEFINED,
        iconSizes.DEFAULT
      ),
      riskRatingValue: 0,
      onEditRiskRatingValue: 0,
      onChangeRatingStatus: true,
      isFocusUrl: false,
      showCreditsAlert: false,
      showingReviewResult: false,
      isLoadingResultReview: false,
      isFetchResultsDisabled: false,
      reportId: null,
      searchId: null,
      encryptedProductId: null,
      associatedCurrentPage: 1,
      associatedSortDesc: false,
      associatedSortBy: "id",
      openCorporatesCompanyOfficersFields: [
        { key: "fullName", label: "Entity Name" },
        { key: "jobTitles", label: "Role" },
        { key: "select", label: "Select" }
      ],
      openCorporatesCompanyOfficersItems: [],
      showingAssociatesTable: false,
      isLoadingAssociatesTable: false,
      isAssoLoading: false,
      selectedCompanyOfficers: [],
      associatesUsers: [
        {
          firstName: null,
          assoName: null,
          entityType: thirdPartyType.INDIVIDUAL,
          id: null,
          position: null,
          meta_data: null,
          validationStateEntityType: null,
          validationStateAssosName: null
        }
      ],
      isOpenCorporatesDataFound: false,
      isOpenCorporatesDataSelected: false,
      datasetThirdPartyName: null,
      ocCompanySelectedCountry: null,
      searchKey: null,
      icompany: {},
      thirdpartyid: null,
      submiting: null,
      isLoading: false,
      responseSuccess: false,
      selectedIndividualRowCountry: null,
      selectedOpenCorporateCompany: null,
      isShowingOpenCorporateData: false,
      fromRowsOtherCountries: 0,
      toRowsOtherCountries: 0,
      totalRowsOtherCountries: 0,
      totalPaginationRowsOtherCountries: 0,
      perPageOtherCountries: 5,
      currentPageOtherCountries: 1,
      filter: null,
      perPage: 5,
      currentPage: 1,
      sortBy: "id",
      sortDesc: true,
      tableWithoutCountryItems: [],
      isLoadingForOthers: false,
      entityVerificationData: [],
      isLoadingCountryData: false,
      openCorporateIndividualFields: [
        { key: "name", label: "Company" },
        { key: "registered_country", label: "Registered In" },
        { key: "registration_status", label: "Postion" },
        { key: "select", label: "Select" }
      ],
      encryptedProdId: null,
      encryptedSearchId: null,
      entityTypeSelect: thirdPartyType.ORGANIZATION,
      verificationTableCollapse: false,
      verificationTableCollapseTwo: false,
      isEditModeEnabled: false,
      item: [
        {
          name: "Coperate Fiction",
          country: "Romania",
          status: "Active"
        },
        {
          name: "Coperate",
          country: "Uk",
          status: "In-Active"
        }
      ],
      actionsOccurBtns: [],
      extraActionsBtn: [],
      completedActions: [],
      actionsTaken: [],
      selected: [],
      optionsTypes: [
        { value: thirdPartyType.ORGANIZATION, text: "Company" },
        { value: thirdPartyType.INDIVIDUAL, text: "Individual" }
      ],
      hideSubmitButton: false,
      form: {
        country: null,
        entityName: null,
        alias: null,
        entityThirdPartyUrl: null,
        firstName: null,
        registrationNumber: null,
        searchAssociatedCompaniesValue: null,
        dunsNumber: null
      },
      additionalDetailForm: {
        address: null,
        city: null,
        state: null,
        postal_code: null,
        contactName: null,
        contactEmail: null,
        contactPhone: null,
        contactLanguage: thirdPartyFormLanguage.ENGLISH,
        contactLanguageCode: null
      },
      systemOwnerId: null,
      selectedSystemOwner: { value: null, text: null },
      selectedStateCode: "",
      isLoadingResultData: false,
      tpDnbData: {},
      searchReportData: {},
      tpDnbSelectedAltaresTableItemData: null,
      beneficialOwnershipRegistrationData: {},
      showNoteText: false,
      actionTakenData: [],
      searchTpiId: 0,
      isLoadingForIndividual: false,
      fromRowsIndividual: 0,
      toRowsIndividual: 0,
      totalRowsIndividual: 0,
      verificationTableCollapseThree: false,
      sortByIndividual: "id",
      sortDescIndividual: true,
      perPageIndividual: 5,
      currentPageIndividual: 1,
      filterIndividual: null,
      tableWithIndividualItems: [],
      isExistingContactEmail: false,
      existingContactEmailMessage: "",
      selectedCountryName: null,
      isLoadingData: false,
      invalidUrlDomain: false,
      invalidUrlDomainValue: null,
      allElements: [],
      isLoadingElements: false,
      isLoadingPackages: false,
      selecteditem: null,
      selectedItemArray: [],
      showDropDownModal: false,
      selectedId: null,
      companyDetail: {},
      collapse: {
        EntityVerification: true,
        AssociateEntities: false,
        AdditionalDetails: false,
        PreviouslySavedEntites: true,
        CustomAttributes: true,
        isPremiumCorporateData: false,
        isEntityDetails: true,
        previousSavedEntities: true
      },
      disableUpdateBtn: true,
      isLoadingPreviouslySavedEntities: false,
      existingItems: [],
      fromRowsExistingItems: 0,
      toRowsExistingItems: 0,
      totalRowsExistingItems: 0,
      perPageExistingItems: 5,
      currentPageExistingItems: 1,
      filterExistingItems: null,
      thirdPartyID: null,
      selectedThirdParty: null,
      totalPaginationIndividual: 0,
      customAttributesValues: {},
      customAttributesValuesData: {},
      reScreeningActions: [],
      isChangeInAssociates: false,
      reScreeningCount: 0,
      isChangeAssociateUpdate: false,
      removedActions: [],
      orignalAssociatesUsers: [
        {
          firstName: null,
          assoName: null,
          entityType: thirdPartyType.INDIVIDUAL,
          id: null,
          position: null,
          meta_data: null,
          validationStateEntityType: null,
          validationStateAssosName: null
        }
      ],
      tCertificationOptions: [],
      showDropDownModalForTCert: false,
      selectedTCertItem: this.makeOptionObj(),
      thirdPartyActionType,
      isLoadingOptions: false,
      typographySize,
      showErrorMessage: false,
      ESGMetricsOptions: [],
      selectedEsgmItem: this.makeOptionObj(),
      showDropDownModalForEsgm: false,
      showErrorMessageForEsgm: false,
      premiumCorporateData: {},
      isEntityVerificationPopupVisible: false,
      isPremiumCorporateDataEnabled: false,
      getIcon,
      dynamicCustomAttributesList: [],
      orbiaSelectionAttributeIndex: null,
      orbiaSelectionAttributeId: null,
      orbiaAffectedAttributeIds: [],
      orbiaOptionsValues: [
        orbiaSpecificConstants.OPTIONS_VALUES.CUSTOMER_END_USER,
        orbiaSpecificConstants.OPTIONS_VALUES.EXCEPTIONS
      ],
      entityVerificationTableHeaders: [],
      entityVerificationTableRows: [],
      infoIcon: {
        icon: icons.QUESTION,
        iconShape: shapes.CIRCLE,
        theme: themes.ETHIXBASE_PRIMARY_INVERSE,
        iconSize: 12
      },
      navigateToURL,
      isEntityVerificationReducedView: true,
      isVerifiedEntity: false,
      isEnrichedEntity: false,
      verifyEntitySuccessState: false,
      verifyAndEnrichEntitySuccessState: false,
      verifyAndEnrichEntityErrorState: false,
      isEntityVerificationPerformed: false,
      isEntityUpdatePopUpVisible: false,
      selectedEntityName: null,
      isConfirmWithPremiumCorporateData: false,
      showConfirmVerifiedNextStepsSection: false,
      confirmVerifiedNextStepsQuestionOptions: [
        { label: "Yes", checked: false },
        { label: "No", checked: false }
      ],
      confirmVerifiedNextStepsQuestionError: "",
      verifiedPayload: null,
      isDiscardChangesModalVisible: false,
      originalEntityName: "",
      isStateErrorRequired: false
    };
  },
  computed: {
    ...mapGetters({
      getCountryCodeBasedOnCountryName:
        getterName.COUNTRY.GET_COUNTRY_CODE_BASED_ON_COUNTRY_NAME,
      getStateNameBasedOnStateCode:
        getterName.COUNTRY.GET_STATE_NAME_BASED_ON_STATE_CODE,
      hasPremiumCorporateDataProducts:
        getterName.COMPANY.HAS_PREMIUM_CORPORATE_DATA_PRODUCTS,
      isThirdPartyIDDOrdered: getterName.THIRD_PARTY.IS_THIRD_PARTY_IDD_ORDERED,
      isThirdPartyIDDPlusOrdered:
        getterName.THIRD_PARTY.IS_THIRD_PARTY_IDD_PLUS_ORDERED
    }),
    makePreviouslySavedEntitiesFields() {
      return [
        { key: "comp_name_eng", label: "Registered Company Name" },
        { key: "country", label: "Registered Country" },
        ...(hasPermission({
          key: featurePermissionsKeys.NAVIGATE_TO_MATCHED_THIRD_PARTY
        })
          ? [{ key: "select", label: "Select" }]
          : [])
      ];
    },
    getContactNameErrorMessage() {
      return this.v$.additionalDetailForm?.contactName?.$error;
    },
    getContactEmailErrorMessage() {
      if (this.v$.additionalDetailForm?.contactEmail?.required?.$invalid) {
        return "A Required field has no value";
      } else if (this.v$.additionalDetailForm?.contactEmail?.email?.$invalid) {
        return "Please enter a valid email address";
      } else if (this.isExistingContactEmail) {
        return this.existingContactEmailMessage;
      }
      return "";
    },
    isNameChangedAfterProductsHaveRun() {
      return (
        this.isEditModeEnabled &&
        (this.isThirdPartyIDDOrdered || this.isThirdPartyIDDPlusOrdered) &&
        this.originalEntityName !== this.form.entityName
      );
    },
    matchedPreviouslySavedEntity() {
      return this.existingItems?.find(
        (item) =>
          item?.comp_name_eng?.toLowerCase() ===
            this.form?.entityName?.toLowerCase() &&
          item?.country === this.form?.country?.country
      );
    },
    duplicateEntityURL() {
      return `${window.location.origin}${urls.THIRD_PARTY_PROFILE_COMPANY(
        this.matchedPreviouslySavedEntity?.tpi_id,
        this.companyId
      )}`;
    },
    showEntityDuplicateWarningMessage() {
      return this.isEditModeEnabled
        ? false
        : !!this.matchedPreviouslySavedEntity;
    },
    isSubmitButtonDisabled() {
      return (
        this.isLoadingData ||
        this.isThirdPartyArchived ||
        this.isLoadingResultReview ||
        this.isErrorPresent ||
        (!this.isEditModeEnabled && !!this.matchedPreviouslySavedEntity)
      );
    },
    hasDropdownOptionsForState() {
      return Object.values(countries).some(
        (country) =>
          country.name === this.form?.country ||
          country.code === this.form?.country?.code
      );
    },
    stateOptions() {
      return this.hasDropdownOptionsForState
        ? this.OCStatesData.filter(({ countryCode }) => {
            const getCountryCode =
              this.form?.country?.code ||
              this.getCountryCodeBasedOnCountryName(this.form?.country);
            return countryCode === getCountryCode;
          })
        : [];
    },
    isStateNameOptional() {
      return !this.isCountriesWithStates();
    },
    showEntityVerificationMatchConfidenceButton() {
      return (
        this.entityVerificationListItems?.length &&
        this.isEntityVerificationReducedView &&
        this.highestMatchConfidenceEntities?.length !==
          this.entityVerificationData?.length
      );
    },
    entityVerificationListItems() {
      return this.isEntityVerificationReducedView
        ? this.highestMatchConfidenceEntities
        : this.entityVerificationData;
    },
    highestMatchConfidenceEntities() {
      if (!this.entityVerificationData?.length) {
        return [];
      }

      const highestConfidenceValue = Math.max(
        ...this.entityVerificationData?.map((item) => item?.match_confidence)
      );

      return this.entityVerificationData
        ?.filter((item) => item?.match_confidence === highestConfidenceValue)
        ?.slice(0, 5);
    },
    makeCompanyOrganizationsListForTable() {
      return (
        this.entityVerificationListItems?.map(
          ({
            name,
            registered_address,
            current_status,
            match_confidence,
            corporateLinkage,
            keyPerson,
            registration_numbers
          }) => ({
            [companyOrganizationsKeys.SELECT]: "select",
            [companyOrganizationsKeys.COMPANY_NAME]: name,
            [companyOrganizationsKeys.ADDRESS]:
              this.setEntityAddress(registered_address),
            [companyOrganizationsKeys.OPERATING_STATUS]: current_status,
            [companyOrganizationsKeys.CORPORATE_LINKAGE]:
              corporateLinkage || "-",
            [companyOrganizationsKeys.MATCH_CONFIDENCE]: match_confidence,
            [companyOrganizationsKeys.MORE_INFO]: "moreInfo",
            [tableOptions.ACCORDION_OPTIONS]: {
              [companyOrganizationsKeys.KEY_PERSON]: keyPerson || "-",
              [companyOrganizationsKeys.COMPANY_REGISTRATION_NUMBER]:
                registration_numbers?.[0]?.number || "-"
            }
          })
        ) || []
      );
    },
    entityVerificationSelectedItemIndex() {
      return this.entityVerificationTableRows.findIndex(
        (row) =>
          row?.[companyOrganizationsKeys.SELECT]?.componentOptions?.modelValue
      );
    },
    isMaximumLimitOvercomedByAssociates() {
      return (
        this.associatesUsers.length > this.headerConfigData?.maximumAssociate
      );
    },
    getSystemOwnerListOptions() {
      // Filter out any super admins except if system owner
      const filteredPlatformUsers = this.platformUserList.filter((user) => {
        if (
          parseInt(user?.memberGroupId) === userMember.SUPER_ADMIN &&
          user?.memberId !== this.systemOwnerId
        ) {
          return false;
        }
        return true;
      });
      // Map values and append (Ethixbase) if system owner is ethixbase super admin
      const mappedFilteredPlatformUsers = filteredPlatformUsers.map(
        ({ memberGroupId, memberId, name }) => ({
          text:
            parseInt(memberGroupId) === userMember.SUPER_ADMIN
              ? `${name} (Ethixbase)`
              : name,
          value: memberId
        })
      );

      return [emptyOption, ...mappedFilteredPlatformUsers];
    },
    confirmVerifiedNextStepsAnswer() {
      return this.confirmVerifiedNextStepsQuestionOptions.filter(
        ({ checked }) => checked
      );
    },
    isEntityNameInputDisabled() {
      return (
        this.isVerifiedEntity ||
        this.isFetchResultsDisabled ||
        this.showCreditsAlert
      );
    },
    defaultInfoBoxIconProperties() {
      return {
        size: iconSizes.SMALL,
        shapeSpacing: 2.33
      };
    },
    warningIconOptions() {
      return {
        ...defaultIcons.WARNING_ALERT,
        ...this.defaultInfoBoxIconProperties
      };
    },
    infoIconOptions() {
      return {
        ...defaultIcons.INFO,
        ...this.defaultInfoBoxIconProperties
      };
    },
    successIconOptions() {
      return {
        ...defaultIcons.CHECK,
        ...this.defaultInfoBoxIconProperties
      };
    }
  },
  validations() {
    let actionsExtra = this.extraActionsBtn.filter((btn) => btn.isActive);
    let occurActions = this.actionsOccurBtns;
    let apriFilter = [];
    apriFilter.push(...actionsExtra);
    apriFilter.push(...occurActions);
    let esgFilterIndex = apriFilter.findIndex((btn) => btn.actionType == "esg");
    let msqFilterIndex = apriFilter.findIndex(
      (btn) =>
        btn.actionType === thirdPartyActionType.MSQ ||
        btn.actionType === thirdPartyActionType.T_CERTIFICATION ||
        btn.actionType === thirdPartyActionType.ESG_METRIC
    );
    let eddFilterIndex = apriFilter.findIndex((btn) => btn.actionType == "edd");

    if (this.entityTypeSelect === thirdPartyType.ORGANIZATION) {
      if (this.isContactNameAndEmailRequired) {
        return {
          form: {
            entityName: {
              required
            },
            country: {
              required
            }
          },
          additionalDetailForm: {
            contactName: {
              required
            },
            contactEmail: {
              required,
              email
            },
            contactLanguage: {
              required
            }
          },
          selecteditem: {
            required
          }
        };
      }
      // if entity type is comapany
      else if (
        msqFilterIndex != -1 &&
        esgFilterIndex != -1 &&
        eddFilterIndex != -1
      ) {
        // if any msq, esg and edd is active
        return {
          form: {
            entityName: {
              required
            },
            country: {
              required
            }
          },
          additionalDetailForm: {
            contactName: {
              required
            },
            contactEmail: {
              required,
              email
            },
            contactLanguage: {
              required
            }
          },
          selecteditem: {
            required
          }
        };
      } else if (
        msqFilterIndex != -1 &&
        esgFilterIndex == -1 &&
        eddFilterIndex == -1
      ) {
        // if any msq is active and ESG & edd is not active
        return {
          form: {
            entityName: {
              required
            },
            country: {
              required
            }
          },
          additionalDetailForm: {
            contactName: {
              required
            },
            contactEmail: {
              required,
              email
            },
            contactLanguage: {
              required
            }
          }
        };
      } else if (
        msqFilterIndex == -1 &&
        esgFilterIndex != -1 &&
        eddFilterIndex == -1
      ) {
        // if any msq and edd is not active and esg is active
        return {
          form: {
            entityName: {
              required
            },
            country: {
              required
            }
          },
          additionalDetailForm: {
            contactEmail: {
              email
            }
          }
        };
      } else if (
        msqFilterIndex == -1 &&
        esgFilterIndex == -1 &&
        eddFilterIndex != -1
      ) {
        // if any msq & ESG is not active and edd is active
        return {
          form: {
            entityName: {
              required
            },
            country: {
              required
            }
          },
          additionalDetailForm: {
            contactEmail: {
              email
            }
          },
          selecteditem: {
            required
          }
        };
      } else if (
        msqFilterIndex != -1 &&
        esgFilterIndex != -1 &&
        eddFilterIndex == -1
      ) {
        // if any msq & ESG is active and edd is not active
        return {
          form: {
            entityName: {
              required
            },
            country: {
              required
            }
          },
          additionalDetailForm: {
            contactName: {
              required
            },
            contactEmail: {
              required,
              email
            },
            contactLanguage: {
              required
            }
          }
        };
      } else if (
        msqFilterIndex != -1 &&
        esgFilterIndex == -1 &&
        eddFilterIndex != -1
      ) {
        // if any msq & edd is active and ESG is not active
        return {
          form: {
            entityName: {
              required
            },
            country: {
              required
            }
          },
          additionalDetailForm: {
            contactName: {
              required
            },
            contactEmail: {
              required,
              email
            },
            contactLanguage: {
              required
            }
          },
          selecteditem: {
            required
          }
        };
      } else if (
        msqFilterIndex == -1 &&
        esgFilterIndex != -1 &&
        eddFilterIndex != -1
      ) {
        // if any msq is not active and ESG and EDD is active
        return {
          form: {
            entityName: {
              required
            },
            country: {
              required
            }
          },
          additionalDetailForm: {
            contactEmail: {
              email
            }
          },
          selecteditem: {
            required
          }
        };
      } else {
        return {
          form: {
            entityName: {
              required
            },
            country: {
              required
            }
          },
          additionalDetailForm: {
            contactEmail: {
              email
            }
          }
        };
      }
    } else if (this.entityTypeSelect === thirdPartyType.INDIVIDUAL) {
      if (this.isContactNameAndEmailRequired) {
        return {
          form: {
            firstName: {
              required
            },
            searchAssociatedCompaniesValue: {
              required
            }
          },
          additionalDetailForm: {
            contactName: {
              required
            },
            contactEmail: {
              required,
              email
            },
            contactLanguage: {
              required
            }
          },
          selecteditem: {
            required
          }
        };
      }
      // if entity type is individual
      else if (
        msqFilterIndex != -1 &&
        esgFilterIndex != -1 &&
        eddFilterIndex != -1
      ) {
        // if any msq, ESG and edd is active
        return {
          form: {
            firstName: {
              required
            },
            searchAssociatedCompaniesValue: {
              required
            }
          },
          additionalDetailForm: {
            contactName: {
              required
            },
            contactEmail: {
              required,
              email
            },
            contactLanguage: {
              required
            }
          },
          selecteditem: {
            required
          }
        };
      } else if (
        msqFilterIndex != -1 &&
        esgFilterIndex == -1 &&
        eddFilterIndex == -1
      ) {
        // if any msq is active and ESG & edd is not active
        return {
          form: {
            firstName: {
              required
            },
            searchAssociatedCompaniesValue: {
              required
            }
          },
          additionalDetailForm: {
            contactName: {
              required
            },
            contactEmail: {
              required,
              email
            },
            contactLanguage: {
              required
            }
          }
        };
      } else if (
        msqFilterIndex == -1 &&
        esgFilterIndex != -1 &&
        eddFilterIndex == -1
      ) {
        // if any msq & edd is not active and ESG is active
        return {
          form: {
            firstName: {
              required
            },
            searchAssociatedCompaniesValue: {
              required
            }
          },
          additionalDetailForm: {
            contactEmail: {
              email
            }
          }
        };
      } else if (
        msqFilterIndex == -1 &&
        esgFilterIndex == -1 &&
        eddFilterIndex != -1
      ) {
        // if any msq & ESG is not active and edd is active
        return {
          form: {
            firstName: {
              required
            },
            searchAssociatedCompaniesValue: {
              required
            }
          },
          additionalDetailForm: {
            contactEmail: {
              email
            }
          },
          selecteditem: {
            required
          }
        };
      } else if (
        msqFilterIndex != -1 &&
        esgFilterIndex != -1 &&
        eddFilterIndex == -1
      ) {
        // if any msq & ESG is active and edd is not active
        return {
          form: {
            firstName: {
              required
            },
            searchAssociatedCompaniesValue: {
              required
            }
          },
          additionalDetailForm: {
            contactName: {
              required
            },
            contactEmail: {
              required,
              email
            },
            contactLanguage: {
              required
            }
          }
        };
      } else if (
        msqFilterIndex != -1 &&
        esgFilterIndex == -1 &&
        eddFilterIndex != -1
      ) {
        // if any msq & EDD is active and ESG is not active
        return {
          form: {
            firstName: {
              required
            },
            searchAssociatedCompaniesValue: {
              required
            }
          },
          additionalDetailForm: {
            contactName: {
              required
            },
            contactEmail: {
              required,
              email
            },
            contactLanguage: {
              required
            }
          },
          selecteditem: {
            required
          }
        };
      } else if (
        msqFilterIndex == -1 &&
        esgFilterIndex != -1 &&
        eddFilterIndex != -1
      ) {
        // if EDD & ESG is active and any msq is not active
        return {
          form: {
            firstName: {
              required
            },
            searchAssociatedCompaniesValue: {
              required
            }
          },
          additionalDetailForm: {
            contactEmail: {
              email
            }
          },
          selecteditem: {
            required
          }
        };
      } else {
        return {
          form: {
            firstName: {
              required
            },
            searchAssociatedCompaniesValue: {
              required
            }
          },
          additionalDetailForm: {
            contactEmail: {
              email
            }
          }
        };
      }
    }
  },
  watch: {
    isNameChangedAfterProductsHaveRun(value) {
      if (value) {
        this.showConfirmVerifiedNextStepsSection = true;
      }
    },
    hasErrorInSubmit(newValue) {
      if (newValue) {
        setTimeout(() => {
          this.hasErrorInSubmit = false;
        }, timers.MODERATE);
      }
    },
    responseSuccess(newValue) {
      if (newValue) {
        setTimeout(() => {
          this.isEntityVerificationPopupVisible = false;
        }, timers.MODERATE);
      }
    }
  },
  async created() {
    await this.initializeData(this.$route?.params?.id);
    EventBus.on("saveValue", (data) => {
      let { value, id, error, type } = data;
      if (type === attributeType.MULTISELECT_COUNTRIES) {
        const selectedCountries = this.countries.filter((obj) =>
          value.split(",").map(Number).includes(obj.id)
        );

        value = JSON.stringify(
          makeMultiSelectCountriesCustomAttributeAPIDTO(selectedCountries)
        );
      }
      this.customAttributesValues[id] = {
        id,
        value,
        error,
        type
      };
      this.customAttributesValuesData[`customatt_${id}`] = value;
      this.disableUpdateBtn = !error;
    });

    this.$posthog.capture(posthogEvents.PAGEVIEW_MODAL, {
      page: "add-third-party-dnb"
    });
  },
  methods: {
    ...mapMutations({
      resetThirdPartyDetails:
        mutationName.THIRD_PARTY.RESET_THIRD_PARTY_DETAILS,
      setThirdPartyDetailsMessage:
        mutationName.THIRD_PARTY.SET_THIRD_PARTY_DETAILS_MESSAGE
    }),
    handleShowSavedEntitiesSection() {
      if (
        (this.form?.entityName || this.form?.firstName) &&
        this.form?.country?.name
      ) {
        this.getPreviouslySavedEntities();
      }
    },
    onChangeSystemOwner(value) {
      this.selectedSystemOwner = value;
    },
    onCloseEntityVerificationModal() {
      if (!this.isLoading) {
        this.isEntityVerificationPopupVisible = false;
      }
    },
    handleVerifyEntitySuccessEvent(stateItem) {
      this[stateItem] = true;

      setTimeout(() => {
        this[stateItem] = false;
        this.$refs?.["entity-details-accordion"]?.$el?.scrollIntoView?.({
          behavior: "smooth"
        });
        this.entityVerificationData = [];
      }, timers.FAST);
    },
    handleVerifyEntityErrorEvent() {
      this.verifyAndEnrichEntityErrorState = true;

      setTimeout(() => {
        this.verifyAndEnrichEntityErrorState = false;
      }, timers.MODERATE);
    },
    onSubmitEntityUpdateModal() {
      this.setOpenCorporateVerifiedData(
        this.entityVerificationListItems[
          this.entityVerificationSelectedItemIndex
        ],
        this.isConfirmWithPremiumCorporateData
      );
      this.onCloseEntityUpdateModal();
    },
    onShowEntityVerificationUpdatePopUp(entityVerificationListItem) {
      return (
        entityVerificationListItem?.name?.toLowerCase() !==
          this.form?.entityName?.toLowerCase() ||
        (entityVerificationListItem?.name?.toLowerCase() ===
          this.form?.entityName?.toLowerCase() &&
          this.getJurisdicationCountryName(
            entityVerificationListItem?.jurisdiction_code
          )?.country === this.form?.country)
      );
    },
    onConfirmEntityVerification({
      entityVerificationListItem = {},
      isBasicVerificationOnly = false
    } = {}) {
      if (
        this.onShowEntityVerificationUpdatePopUp(entityVerificationListItem)
      ) {
        this.isEntityUpdatePopUpVisible = true;
        this.isConfirmWithPremiumCorporateData = isBasicVerificationOnly;
      } else {
        this.setOpenCorporateVerifiedData(
          entityVerificationListItem,
          isBasicVerificationOnly
        );
      }
    },
    setOpenCorporateVerifiedData(data, isBasicVerificationOnly = false) {
      this.premiumCorporateData = {};

      if (this.entityTypeSelect === thirdPartyType.ORGANIZATION) {
        if (this.isEditModeEnabled) {
          if (
            this.form?.entityName?.toLowerCase() !== data?.name?.toLowerCase()
          ) {
            this.form.alias = this.originalEntityName;
            if (
              this.isThirdPartyIDDOrdered ||
              this.isThirdPartyIDDPlusOrdered
            ) {
              this.showConfirmVerifiedNextStepsSection = true;
            }
          }
        }
        this.form.entityName = data?.name;
        this.datasetThirdPartyName = data?.name;
        this.additionalDetailForm.address =
          data?.registered_address?.street_address;
        this.additionalDetailForm.city = data?.registered_address?.locality;
        this.additionalDetailForm.state = this.getStateNameBasedOnStateCode(
          data?.registered_address?.region,
          this.form?.country?.code
        );
        this.additionalDetailForm.postal_code =
          data?.registered_address?.postal_code;
        this.form.registrationNumber = data?.registration_numbers[0]?.number;
        this.form.dunsNumber = data?.company_number;
        this.form.entityThirdPartyUrl = data?.url;
        this.makeVerifiedPayload(data);
        this.isVerifiedEntity = true;

        if (isBasicVerificationOnly) {
          if (data?.keyPerson) {
            this.openCorporatesCompanyOfficersItems = [
              { fullName: data?.keyPerson, jobTitles: [{ title: "-" }] }
            ];
            const hasExistingAssociate = this.associatesUsers.some(
              (user) => user?.assoName === data?.keyPerson
            );
            if (hasExistingAssociate) {
              this.selectedCompanyOfficers[0] = true;
            }
            this.collapse.AssociateEntities = true;
            this.showingAssociatesTable = true;
          }

          this.isEnrichedEntity = false;

          if (!this.showConfirmVerifiedNextStepsSection) {
            this.handleVerifyEntitySuccessEvent("verifyEntitySuccessState");
            this.isEntityVerificationPerformed = false;
          }

          return;
        }
      } else {
        this.selectedIndividualRowCountry = data?.index;
        this.datasetThirdPartyName = data?.company?.name;
      }
      this.isOpenCorporatesDataSelected = true;
      this.isOpenCorporatesDataFound = true;

      this.selectedOpenCorporateCompany = data;
      if (this.entityTypeSelect === thirdPartyType.ORGANIZATION) {
        let icompany = {};
        this.searchKey = data?.company_number;
        icompany[
          data?.company_number
        ] = `${data?.jurisdiction_code}|${data?.company_number}`;

        this.icompany = icompany;
        this.ocCompanySelectedCountry = data?.jurisdiction_code;
        let countryCodeIndex = this.countries.findIndex(
          (x) => x.isoCode1.toLowerCase() === data?.jurisdiction_code
        );
        if (countryCodeIndex != -1) {
          this.form.country = this.countries[countryCodeIndex];
        }
        this.fetchCompanyCorporateData();
        this.showingAssociatesTable = true;
      } else {
        let icompany = {};
        this.searchKey = data?.company?.company_number;
        icompany[
          data?.company?.company_number
        ] = `${data?.company?.jurisdiction_code}|${data?.id}`;

        this.icompany = icompany;
      }
    },
    async fetchCompanyCorporateData(countryRegistrationNumber) {
      this.isLoadingAssociatesTable = true;
      this.isAssoLoading = true;
      try {
        const { data = {} } = await thirdPartyService.fetchCompanyCorporateData(
          this.companyId,
          countryRegistrationNumber || this.searchKey
        );
        this.premiumCorporateData = data.response.data;
        this.openCorporatesCompanyOfficersItems =
          this.makeOpenCorporatesCompanyOfficersItems(data);
        this.openCorporatesCompanyOfficersItems.map(
          (companyOfficers, index) => {
            this.associatesUsers.map((users) => {
              if (users.assoName === companyOfficers.fullName) {
                this.selectedCompanyOfficers[index] = true;
              }
            });
          }
        );
        this.collapse.AssociateEntities = true;
        this.collapse.isPremiumCorporateData = true;
        this.isEnrichedEntity = true;
        this.handleVerifyEntitySuccessEvent(
          "verifyAndEnrichEntitySuccessState"
        );
        this.isVerifiedEntity = true;
        this.isEntityVerificationPerformed = false;
      } catch ({ status }) {
        this.fetchCompanyCorporateDataFailure(status);
        this.handleVerifyEntityErrorEvent();
      } finally {
        this.isLoadingAssociatesTable = false;
        this.showingAssociatesTable = true;
        this.isAssoLoading = false;
      }
    },
    makeOpenCorporatesCompanyOfficersItems(data) {
      return [
        ...data?.response?.data?.mostSeniorPrincipals,
        ...data?.response?.data?.currentPrincipals
      ];
    },
    isCountriesWithStates() {
      return (
        this.hasDropdownOptionsForState && !this.additionalDetailForm.state
      );
    },
    isActionTypeTCertOrEsgm(action) {
      return (
        (action.actionType === thirdPartyActionType.T_CERTIFICATION ||
          action.actionType === thirdPartyActionType.ESG_METRIC) &&
        !action.imgName
      );
    },
    getActionType(action) {
      if (action.actionType === thirdPartyActionType.EDD) {
        this.onSelectExtraActionOccurEdd(action);
      } else if (action.actionType === thirdPartyActionType.T_CERTIFICATION) {
        this.onSelectExtraActionOccurTCert(action);
      } else if (action.actionType === thirdPartyActionType.ESG_METRIC) {
        this.onSelectExtraActionOccurEsgm(action);
      }
    },
    getActionToBePerformed(action) {
      if (action.actionType === thirdPartyActionType.EDD) {
        this.onSelectExtraActionOccurEdd(action);
      } else if (action.actionType === thirdPartyActionType.T_CERTIFICATION) {
        this.onSelectExtraActionOccurTCert(action);
      } else if (action.actionType === thirdPartyActionType.ESG_METRIC) {
        this.onSelectExtraActionOccurEsgm(action);
      } else {
        this.onSelectExtraActionOccur(action);
      }
    },
    isTCertActive(action) {
      return (
        action.isActive &&
        !this.selectedTCertItem?.value &&
        this.showErrorMessage
      );
    },
    isEsgmActive(action) {
      return (
        action.isActive &&
        !this.selectedEsgmItem?.value &&
        this.showErrorMessageForEsgm
      );
    },
    onChangeTCertItems(val) {
      this.tCertificationOptions.forEach((tCertificationOption) => {
        if (parseInt(tCertificationOption.value) === parseInt(val)) {
          tCertificationOption.selected = true;
          this.selectedTCertItem = tCertificationOption;
        } else {
          delete tCertificationOption.selected;
        }
      });

      const selectedValueIndex = this.tCertificationOptions.findIndex(
        ({ value }) => parseInt(value) === parseInt(val)
      );
      if (selectedValueIndex === -1) {
        this.selectedTCertItem = this.makeOptionObj();
      }
    },
    onChangeEsgmItems(val) {
      this.ESGMetricsOptions.forEach((esgMetricsOption) => {
        if (parseInt(esgMetricsOption.value) === parseInt(val)) {
          esgMetricsOption.selected = true;
          this.selectedEsgmItem = esgMetricsOption;
        } else {
          delete esgMetricsOption.selected;
        }
      });

      const selectedValueIndex = this.ESGMetricsOptions.findIndex(
        ({ value }) => parseInt(value) === parseInt(val)
      );
      if (selectedValueIndex === -1) {
        this.selectedEsgmItem = this.makeOptionObj();
      }
    },
    getActionDataTestId(actionName, type) {
      return `third-party-details__add-${type}--${actionName
        .replace(/ /g, "-")
        .toLowerCase()}`;
    },
    getActionButtonDataTestId() {
      return `third-party-details__action-button--${
        this.isEditModeEnabled ? "update" : "submit"
      }`;
    },
    inputRiskRatingComment(value) {
      if (value && value.trim() != "") {
        if (value.length <= 250) {
          this.validateRiskRatingComment = null;
        } else {
          this.validateRiskRatingComment = false;
        }
      } else {
        this.validateRiskRatingComment = false;
      }
    },
    selectRiskRatingOption(item) {
      if (item) {
        this.selectedRiskRatingText = item.text;
        this.selectedRiskRatingIcon = {
          icon: item?.icon,
          shape: item?.shape,
          theme: item?.theme,
          border: item?.border,
          size: iconSizes.DEFAULT
        };
        this.riskRatingValue = item.value;
        this.onChangeRatingStatus = false;
        if (item.value == 0 || item.value == "0") {
          this.onChangeRatingStatus = true;
          this.riskRatingComment = null;
          this.validateRiskRatingComment = null;
        }
      }
      if (this.isEditModeEnabled) {
        if (this.riskRatingValue == this.onEditRiskRatingValue) {
          this.onChangeRatingStatus = true;
        } else {
          this.onChangeRatingStatus = false;
        }
      }
      if (!this.onChangeRatingStatus && this.isEditModeEnabled) {
        this.disableUpdateBtn = false;
      } else {
        this.disableUpdateBtn = true;
      }
    },
    resetRiskRating() {
      this.selectedRiskRatingText = null;
      this.selectedRiskRatingIcon = getRiskRatingIcon(
        ratingTypeValue.UNDEFINED,
        iconSizes.DEFAULT
      );
      this.riskRatingValue = 0;
      this.onChangeRatingStatus = true;
      this.riskRatingComment = null;
      this.validateRiskRatingComment = null;
      this.riskRatingNotes = null;
      this.onEditRiskRatingValue = null;
    },
    is_url() {
      let regexp =
        /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
      if (
        !regexp.test(this.form.entityThirdPartyUrl) &&
        this.form.entityThirdPartyUrl
      ) {
        this.invalidUrlDomain = true;
        this.invalidUrlDomainValue = "Please enter a valid URL";
      } else {
        this.invalidUrlDomain = false;
      }
    },
    setContactLanguage(value) {
      this.additionalDetailForm.contactLanguage = value
        ? value.languages
        : "English";
      this.additionalDetailForm.contactLanguageCode = value
        ? value.languageCode
        : "en";
    },
    checkEntityName(Value) {
      if (!Value) {
        this.form.entityName = null;
      }
    },
    async contactExistEmail() {
      let formData = {
        email: this.additionalDetailForm.contactEmail
      };
      try {
        this.isLoadingData = true;
        let result = await HTTP(
          "post",
          "thirdparty/contact/exist-email",
          formData,
          makeAuthorizationHeader()
        );
        this.isLoadingData = false;
        if (
          result.data.httpStatus == 200 &&
          result.data.success == 1 &&
          result.data.data > 0
        ) {
          this.isExistingContactEmail = true;
          this.existingContactEmailMessage =
            "This Email already exist, Please try another Email.";
        } else {
          this.isExistingContactEmail = false;
          this.existingContactEmailMessage = "";
        }
      } catch (err) {
        this.isLoadingData = false;
        return [err];
      }
    },
    setSelectedState({ state = "", stateCode = "" } = {}) {
      this.additionalDetailForm.state = state;
      this.selectedStateCode = stateCode;
      this.isStateErrorRequired = false;
    },
    callOpenCorporateTableApi(value, type = null) {
      this.resetCountryStateFields();
      if (type == "country") {
        this.selectedCountryName = value.name;
        this.checkValue();
      }
      this.isShowingOpenCorporateData = false;
      this.clearOpenCorporateSectionData();
      setTimeout(() => {
        if (
          (this.entityTypeSelect === thirdPartyType.ORGANIZATION &&
            this.form.entityName != null &&
            this.form.country != null) ||
          (this.entityTypeSelect === thirdPartyType.INDIVIDUAL &&
            this.form.firstName != null)
        ) {
          if (
            this.entityTypeSelect === thirdPartyType.INDIVIDUAL &&
            this.form.firstName != null
          ) {
            this.currentPageIndividual = 1;
            this.isShowingOpenCorporateData = true;
          }
          this.isShowingOpenCorporateData = true;
          this.currentPage = 1;
          this.currentPageOtherCountries = 1;
        } else {
          this.isShowingOpenCorporateData = false;
        }
      }, 500);
    },
    resetCountryStateFields() {
      this.additionalDetailForm.state = "";
      this.selectedStateCode = "";
    },
    onFormSubmission() {
      this.validateContactEmail();
    },
    validateContactEmail() {
      if (this.additionalDetailForm.contactEmail) {
        this.contactExistEmail().then(this.checkValidation);
      } else {
        this.checkValidation();
      }
    },
    validateShowEntityVerificationPopup() {
      return (
        !this.isEditModeEnabled &&
        this.isPremiumCorporateDataEnabled &&
        !this.isVerifiedEntity
      );
    },
    checkValidation() {
      const hasVerifiedNextStepsValidationError =
        this.showConfirmVerifiedNextStepsSection &&
        !this.confirmVerifiedNextStepsAnswer.length;

      if (hasVerifiedNextStepsValidationError) {
        this.confirmVerifiedNextStepsQuestionError = "This field is required";
        this.$refs?.confirmNextStepsError?.$el?.scrollIntoView?.({
          behavior: "smooth"
        });
        return;
      }
      let customAttributesError = [];
      const filteredCustomAttributeValues = Object.keys(
        this.customAttributesValues
      ).filter((customAttributeId) =>
        this.hasPermission(
          permissionTypes.EDIT_CUSTOM_ATTRIBUTE_VALUE,
          customAttributeId
        )
      );

      filteredCustomAttributeValues.forEach((key) => {
        if (this.customAttributesValues[key].error) {
          customAttributesError.push(true);
        } else {
          customAttributesError.push(false);
        }
      });
      let checker = customAttributesError.every((v) => v == false);
      if (!this.onChangeRatingStatus) {
        if (
          this.riskRatingComment &&
          this.riskRatingComment.trim() != "" &&
          this.riskRatingComment.length <= 250
        ) {
          this.validateRiskRatingComment = null;
        } else {
          this.validateRiskRatingComment = false;
        }
      }
      this.v$.form?.$touch();
      if (!this.v$.form.$errors?.length) {
        this.v$.additionalDetailForm?.$touch();
        if (this.v$.additionalDetailForm.$errors?.length) {
          this.collapse.AdditionalDetails = true;

          setTimeout(() => {
            this.$refs["third-party-details__msq-wrapper"]?.scrollIntoView?.({
              behavior: "smooth"
            });
          }, 500);
        } else {
          if (!this.isExistingContactEmail && !this.invalidUrlDomain) {
            if (this.checkIsEddActive()) {
              this.v$.selecteditem.$touch();
            } else {
              if (
                this.isThirdPartyActionActive(
                  thirdPartyActionType.T_CERTIFICATION
                ) &&
                !this.selectedTCertItem.value
              ) {
                this.showErrorMessage = true;
              } else {
                this.showErrorMessage = false;
                if (
                  this.isThirdPartyActionActive(
                    thirdPartyActionType.ESG_METRIC
                  ) &&
                  !this.selectedEsgmItem.value
                ) {
                  this.showErrorMessageForEsgm = true;
                } else {
                  this.showErrorMessageForEsgm = false;
                  if (this.validateRiskRatingComment !== false) {
                    if (
                      (this.additionalDetailForm.contactName &&
                        this.additionalDetailForm.contactEmail) ||
                      (!this.additionalDetailForm.contactEmail &&
                        !this.additionalDetailForm.contactName)
                    ) {
                      if (!checker) {
                        this.collapse.AdditionalDetails = true;
                        setTimeout(() => {
                          for (const key of Object.keys(
                            this.customAttributesValues
                          )) {
                            if (this.customAttributesValues[key].error) {
                              if (
                                this.customAttributesValues[key].error &&
                                this.customAttributesValues[key].type ===
                                  attributeType.TEXTAREA
                              ) {
                                EventBus.emit(
                                  "textAreaValidationError",
                                  this.customAttributesValues[key]
                                );
                                break;
                              } else if (
                                this.customAttributesValues[key].error &&
                                (this.customAttributesValues[key].type ===
                                  attributeType.MULTISELECT ||
                                  this.customAttributesValues[key].type ===
                                    attributeType.MULTISELECT_COUNTRIES)
                              ) {
                                EventBus.emit(
                                  "multiselectValidationError",
                                  this.customAttributesValues[key]
                                );
                                break;
                              } else if (
                                this.customAttributesValues[key].error &&
                                this.customAttributesValues[key].type ==
                                  attributeType.COUNTRY_LIST
                              ) {
                                EventBus.emit(
                                  "countryListValidationError",
                                  this.customAttributesValues[key]
                                );
                                break;
                              } else if (
                                this.customAttributesValues[key].error &&
                                this.customAttributesValues[key].type ===
                                  attributeType.HTML_STRING
                              ) {
                                EventBus.emit(
                                  "htmlStringValidationError",
                                  this.customAttributesValues[key]
                                );
                                break;
                              } else if (
                                this.customAttributesValues[key].error &&
                                this.customAttributesValues[key].type ===
                                  attributeType.USER
                              ) {
                                EventBus.emit(
                                  "UserListValidationError",
                                  this.customAttributesValues[key]
                                );
                                break;
                              } else if (
                                this.customAttributesValues[key].error &&
                                this.customAttributesValues[key].type ===
                                  attributeType.BOOLEAN
                              ) {
                                EventBus.emit(
                                  "CheckBoxValidationError",
                                  this.customAttributesValues[key]
                                );
                                break;
                              } else if (
                                this.customAttributesValues[key].error &&
                                [
                                  attributeType.DROPDOWN,
                                  attributeType.RATING
                                ].includes(
                                  this.customAttributesValues[key].type
                                )
                              ) {
                                EventBus.emit(
                                  "DropDownValidationError",
                                  this.customAttributesValues[key]
                                );
                                break;
                              } else if (
                                this.customAttributesValues[key].error
                              ) {
                                EventBus.emit(
                                  "validationError",
                                  this.customAttributesValues[key]
                                );
                                break;
                              }
                            }
                          }
                        }, 500);
                      } else {
                        let myActionTaken = this.completedActions.filter(
                          (x) =>
                            x.actionType == "idd" ||
                            x.actionType == "idd_plus" ||
                            x.actionType == "edd"
                        );
                        let actionsExtra = this.extraActionsBtn.filter(
                          (btn) => btn.isActive
                        );
                        let eddFilterIndex = actionsExtra.findIndex(
                          (btn) => btn.actionType == "edd"
                        );
                        myActionTaken.map((action, index) => {
                          if (
                            action.actionType == "edd" &&
                            action.imgName == "inprogress"
                          ) {
                            myActionTaken.splice(index, 1);
                          } else if (eddFilterIndex != -1) {
                            action.isActive = true;
                          } else if (action.actionType == "edd") {
                            action.isActive = false;
                          }
                        });

                        if (this.selectedSystemOwner.value) {
                          this.systemOwnerId = this.selectedSystemOwner.value;
                        }

                        if (
                          this.isEditModeEnabled &&
                          this.isChangeInAssociates &&
                          myActionTaken.length &&
                          !this.showConfirmVerifiedNextStepsSection
                        ) {
                          this.reScreeningActions = myActionTaken;
                          if (eddFilterIndex == -1) {
                            this.selecteditem = null;
                          }
                          this.$refs["modal-rerun-screening"].show();
                        } else if (this.validateShowEntityVerificationPopup()) {
                          this.isEntityVerificationPopupVisible = true;
                        } else if (this.isEditModeEnabled) {
                          this.updateThirdParty();
                        } else {
                          this.addThirdParty();
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    makeActionTakenAllIsActive(actionsTaken) {
      actionsTaken.map((action, index) => {
        if (action.actionType == "idd" || action.actionType == "idd_plus") {
          action.isActive = true;
          actionsTaken[index] = action;
        }
      });
      return actionsTaken;
    },
    makeVerifiedPayload(data = {}) {
      this.verifiedPayload = {
        name: data.name || "",
        address: data.registered_address?.street_address || "",
        operating_status: data.current_status || "",
        corporate_linkage: data.corporateLinkage || "",
        key_person: data.keyPerson || "",
        company_registration_number:
          data.registration_numbers?.[0]?.number || ""
      };
    },
    async updateThirdParty() {
      let actionsTaken = [];
      this.actionsOccurBtns.map((actions) => {
        actions.isActive = true;
        actionsTaken.push(actions);
      });

      let eddRerunFilterIndex = this.reScreeningActions.findIndex(
        (btn) => btn.actionType == "edd"
      );
      let eddExtraFilterIndex = this.extraActionsBtn.findIndex(
        (btn) => btn.actionType == "edd"
      );
      if (eddRerunFilterIndex != -1 && eddExtraFilterIndex != -1) {
        this.extraActionsBtn[eddExtraFilterIndex].isActive = false;
      }
      let filteredExtraActions = this.extraActionsBtn.filter(
        (btn) => btn.isActive
      );
      actionsTaken.push(...filteredExtraActions);

      let reScreeningActiveActions = this.reScreeningActions.filter(
        (btn) => btn.isActive
      );
      actionsTaken.push(...reScreeningActiveActions);

      let actionNotActive = [];
      let reScreeningInactiveActions = this.reScreeningActions.filter(
        (btn) => !btn.isActive
      );
      actionNotActive.push(...reScreeningInactiveActions);

      let IDDTypeIndex = actionsTaken.findIndex((x) => x.actionType == "idd");
      let IDDCompletedIndex = this.completedActions.findIndex(
        (x) => x.actionType == "idd"
      );
      if (IDDTypeIndex != -1) {
        actionsTaken[IDDTypeIndex].requestedDate = new Date();

        if (IDDCompletedIndex != -1) {
          actionsTaken[IDDTypeIndex].count =
            this.completedActions[IDDCompletedIndex].count + 1;
        } else {
          let IddRemovedIndex = this.removedActions.findIndex(
            (x) => x.actionType == "idd"
          );
          if (IddRemovedIndex != -1) {
            actionsTaken[IDDTypeIndex].count =
              this.removedActions[IddRemovedIndex].count + 1;
          }
        }
      }

      let due_diligence_type = "report_type_idd_free";
      let dueDiligenceTypeIndex = actionsTaken.findIndex(
        (x) => x.actionType == "idd_plus"
      );
      let IddPlusCompletedIndex = this.completedActions.findIndex(
        (x) => x.actionType == "idd_plus"
      );
      if (dueDiligenceTypeIndex != -1) {
        actionsTaken[dueDiligenceTypeIndex].backgroundColor = "#d0f2d2";
        actionsTaken[dueDiligenceTypeIndex].imgName = "ok";
        actionsTaken[dueDiligenceTypeIndex].requestedDate = new Date();
        due_diligence_type = "report_type_idd_plus";

        if (IddPlusCompletedIndex != -1) {
          actionsTaken[dueDiligenceTypeIndex].count =
            this.completedActions[IddPlusCompletedIndex].count + 1;
        } else {
          let IddPlusRemovedIndex = this.removedActions.findIndex(
            (x) => x.actionType == "idd_plus"
          );
          if (IddPlusRemovedIndex != -1) {
            actionsTaken[dueDiligenceTypeIndex].count =
              this.removedActions[IddPlusRemovedIndex].count + 1;
          }
        }
      }

      let isMSQActive = 0;
      actionsTaken.map((actions) => {
        let MsqCompletedIndex = this.completedActions.findIndex(
          (x) => x.actionType == "msq" && x.id == actions.id
        );
        if (actions.actionType == "msq") {
          actions.requestedDate = new Date();
          isMSQActive = 1;

          if (MsqCompletedIndex != -1) {
            actions.count = this.completedActions[MsqCompletedIndex].count + 1;
          }
        }
      });

      let isAPRIActive = 0;
      actionsTaken.map((actions) => {
        let ApriCompletedIndex = this.completedActions.findIndex(
          (x) => x.actionType == "apri" && x.id == actions.id
        );
        if (actions.actionType == "apri") {
          actions.backgroundColor = "#e3f4fa";
          actions.imgName = "inprogress";
          actions.requestedDate = new Date();
          isAPRIActive = 1;

          if (ApriCompletedIndex != -1) {
            actions.count = this.completedActions[ApriCompletedIndex].count + 1;
          }
        }
      });

      let standardTypeIndex = actionsTaken.findIndex(
        (x) => x.actionType == "pri"
      );
      let PriCompletedIndex = this.completedActions.findIndex(
        (x) => x.actionType == "pri"
      );
      if (standardTypeIndex != -1) {
        actionsTaken[standardTypeIndex].backgroundColor = "#d0f2d2";
        actionsTaken[standardTypeIndex].imgName = "ok";
        actionsTaken[standardTypeIndex].requestedDate = new Date();

        if (PriCompletedIndex != -1) {
          actionsTaken[standardTypeIndex].count =
            this.completedActions[PriCompletedIndex].count + 1;
        }
      }
      let eddTypeIndex = actionsTaken.findIndex((x) => x.actionType == "edd");
      let EddCompletedIndex = this.completedActions.findIndex(
        (x) => x.actionType == "edd"
      );
      let productPriceId = 0;
      if (eddTypeIndex != -1) {
        actionsTaken[eddTypeIndex].backgroundColor = "#e3f4fa";
        actionsTaken[eddTypeIndex].imgName = "inprogress";
        actionsTaken[eddTypeIndex].requestedDate = new Date();
        productPriceId = this.selectedItemArray.product_price_id;

        if (EddCompletedIndex != -1) {
          actionsTaken[eddTypeIndex].count =
            this.completedActions[EddCompletedIndex].count + 1;
        } else {
          let EddRemovedIndex = this.removedActions.findIndex(
            (x) => x.actionType == "edd"
          );
          if (EddRemovedIndex != -1) {
            actionsTaken[eddTypeIndex].count =
              this.removedActions[EddRemovedIndex].count + 1;
          }
        }
      }

      const tCertTypeIndex = actionsTaken.findIndex(
        (x) => x.actionType === thirdPartyActionType.T_CERTIFICATION
      );
      this.makeTComplianceActionObject(
        actionsTaken,
        thirdPartyActionType.T_CERTIFICATION
      );

      const esgMTypeIndex = actionsTaken.findIndex(
        (x) => x.actionType === thirdPartyActionType.ESG_METRIC
      );
      this.makeTComplianceActionObject(
        actionsTaken,
        thirdPartyActionType.ESG_METRIC
      );
      this.completedActions = actionsTaken;

      let productsArr = this.allElements.filter(
        (element) => element.package_products_id > 0
      );
      let productsName = [];
      productsArr.map((product) => {
        productsName.push(product.product_name);
      });

      let formData = {
        url: this.form.entityThirdPartyUrl,
        thirdPartyId: this.thirdpartyid,
        companyId: this.companyId,
        userId: this.userId,
        isApri: isAPRIActive,
        isDdq: isMSQActive,
        due_diligence_type,
        srchTpiId: this.searchTpiId,
        riskRatingNotes: this.riskRatingComment,
        riskRating: this.riskRatingValue,
        action_taken: this.completedActions,
        associates: this.associatesUsers,
        isChangeAssociate: this.isChangeAssociateUpdate ? 1 : 0,
        ...this.additionalDetailForm,
        comp_name: this.companyName,
        alias: this.form?.alias,
        tp_name: this.form?.entityName,
        verified: this.isVerifiedEntity,
        enriched: this.isEnrichedEntity,
        remove_actions: actionNotActive,
        custom_attributes: this.customAttributesValuesData,
        registrationNumber: this.form.registrationNumber,
        duns_number: this.form?.dunsNumber,
        memberId: this.systemOwnerId || null,
        dnb_data: {
          isAlatresDataFound: this.isOpenCorporatesDataFound,
          isAlatresDataSelected: this.isOpenCorporatesDataSelected,
          datasetThirdPartyName: this.datasetThirdPartyName,
          ocCompanySelectedCountry: this.ocCompanySelectedCountry,
          searchedCompany:
            this.entityTypeSelect === thirdPartyType.ORGANIZATION
              ? this.form?.entityName
              : this.form?.firstName,
          selectedReviewCompany: this.selectedOpenCorporateCompany,
          clientCustonAttributeData: []
        },
        ...(!!this.verifiedPayload && {
          verified_details: this.verifiedPayload
        }),
        rerunDueDiligence:
          this.confirmVerifiedNextStepsAnswer?.[0]?.label?.toLowerCase() ===
          "yes"
      };
      if (eddTypeIndex != -1) {
        formData.edd = {
          bill_name: this.companyDetails.billName,
          bill_email: this.companyDetails.billEmail,
          cost: this.selectedItemArray.client_packages_cost,
          tat: this.selectedItemArray.client_packages_tat,
          tat_max: this.selectedItemArray.client_packages_tat_max,
          package_id: this.selectedItemArray.packagesId,
          package_name: this.selectedItemArray.name || "",
          region_id: this.selectedItemArray.region_id,
          bill_country: this.companyDetails.billCountry,
          billing_add: this.companyDetails.billingAdd,
          eddScope: this.selecteditem,
          product_price_id: productPriceId,
          products: productsName
        };
      }
      formData.products = [];
      if (tCertTypeIndex !== -1) {
        formData.products.push({
          product: actionsTaken[tCertTypeIndex].product,
          product_id: actionsTaken[tCertTypeIndex].id.split("_")[1],
          order_type: this.selectedTCertItem.text,
          order_type_id: this.selectedTCertItem.value
        });
      }
      if (esgMTypeIndex !== -1) {
        formData.products.push({
          product: actionsTaken[esgMTypeIndex].product,
          product_id: actionsTaken[esgMTypeIndex].id.split("_")[1],
          order_type: this.selectedEsgmItem.text,
          order_type_id: this.selectedEsgmItem.value
        });
      }
      if (
        this.isEditModeEnabled &&
        this.isChangeInAssociates &&
        this.reScreeningActions.length
      ) {
        if (reScreeningActiveActions.length == 0) {
          formData.reRunScreening = 2;
        } else {
          formData.reRunScreening = 1;
        }
      }
      this.hideSubmitButton = true;
      if (!this.checkRerunEddActive()) {
        try {
          this.submiting = true;
          this.isLoading = true;
          this.isLoadingResultReview = true;
          this.orignalAssociatesUsers = [];
          let result = await HTTP(
            "post",
            "thirdparty/update/thirdparty/with/idd",
            formData,
            makeAuthorizationHeader()
          );
          if (result.data.message) {
            this.showCreditsAlert = true;
            this.isLoadingResultReview = false;
          }
          if (result.data && result.data.third_party_id) {
            this.thirdpartyid = result.data.third_party_id;
            localStorage.setItem("editThirdPartyId", this.thirdpartyid);
            this.encryptedProdId = result.data.encryptedProductId;
            this.encryptedSearchId = result.data.encryptedSrchId;
            this.completedActions = this.getUnique(result.data.action_taken);
            if (isAPRIActive == 0) {
              this.completedActions.map((data) => {
                if (data.actionType == "apri") {
                  data.imgName = "";
                }
              });
            }
            this.removedActions = result.data.remove_actions
              ? result.data.remove_actions
              : [];
            this.showingReviewResult = true;
            this.isFetchResultsDisabled = true;
            this.userTyping = true;
            if (
              productPriceId > 0 &&
              this.companyDetails.eddContract === eddContracts.ORDER
            ) {
              this.$bvToast.toast(
                "Thank you for your Enhanced Due Diligence Order. This order has submitted to our team for action. You will be notified once the report is available on the platform",
                {
                  variant: "success",
                  autoHideDelay: 5000,
                  appendToast: false,
                  noCloseButton: true
                }
              );
            } else if (
              productPriceId > 0 &&
              this.companyDetails.eddContract === eddContracts.ENQUIRY
            ) {
              this.$bvToast.toast(
                "Thank you for your Enhanced Due Diligence enquiry. This order has submitted to our team for action. You will be contacted shortly",
                {
                  variant: "success",
                  autoHideDelay: 5000,
                  appendToast: false,
                  noCloseButton: true
                }
              );
            }
            this.orignalAssociatesUsers = cloneDeep(this.associatesUsers);
            this.collapse = {
              EntityVerification: false,
              AssociateEntities: false,
              AdditionalDetails: false,
              CustomAttributes: false,
              isPremiumCorporateData: false,
              isEntityDetails: false,
              previousSavedEntities: false
            };
            this.extraActionsBtn.map((actions) => {
              actions.isActive = false;
              actions.imgName = "";
              actions.backgroundColor = "";
            });
            this.disableUpdateBtn = true;
            this.$refs["modal-rerun-screening"].hide();
            this.getResultPreviewData();
            if (this.isChangeInAssociates && this.reScreeningActions.length) {
              this.exitDetailPage();
            }
          }

          this.reScreeningActions = [];
          this.reScreeningCount = 0;
          this.isChangeAssociateUpdate = false;
          this.isChangeInAssociates = false;
          this.onChangeRatingStatus = true;
          this.riskRatingComment = null;
          this.isLoadingResultReview = false;
          this.submiting = false;
          this.isLoading = false;
          this.responseSuccess = true;
          this.navigateToThirdPartyProfile(result?.data?.third_party_id);
        } catch (error) {
          this.hideSubmitButton = false;
          this.isLoadingResultReview = false;
          this.isLoading = false;
          this.submiting = false;
        }
      }
    },
    makeTComplianceActionObject(actionsTaken, thirdPartyActionType) {
      const tComplianceActionIndex = actionsTaken.findIndex(
        (x) => x.actionType === thirdPartyActionType
      );
      const tComplianceCompleteIndex = this.completedActions.findIndex(
        (x) => x.actionType === thirdPartyActionType
      );
      if (tComplianceActionIndex !== -1) {
        actionsTaken[tComplianceActionIndex].backgroundColor = colors.bubbles;
        actionsTaken[tComplianceActionIndex].imgName =
          thirdPartyActionImageType.IN_PROGRESS;
        actionsTaken[tComplianceActionIndex].requestedDate = new Date();

        if (tComplianceCompleteIndex !== -1) {
          actionsTaken[tComplianceActionIndex].count =
            this.completedActions[tComplianceCompleteIndex].count + 1;
        } else {
          const EsgMRemovedIndex = this.removedActions.findIndex(
            (x) => x.actionType === thirdPartyActionType
          );
          if (EsgMRemovedIndex !== -1) {
            actionsTaken[tComplianceActionIndex].count =
              this.removedActions[EsgMRemovedIndex].count + 1;
          }
        }
      }
      return actionsTaken;
    },
    getUnique(array) {
      const filteredArr = array.reduce((acc, current) => {
        const x = acc.find((item) => item.id === current.id);
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []);
      return filteredArr;
    },
    checkIsMsqActive() {
      let actionsExtra = this.extraActionsBtn.filter((btn) => btn.isActive);
      let occurActions = this.actionsOccurBtns;
      let actionsData = [];
      actionsData.push(...actionsExtra);
      actionsData.push(...occurActions);
      let isMsqActive = false;

      actionsData.forEach(({ actionType }) => {
        if (
          actionType === thirdPartyActionType.MSQ ||
          actionType === thirdPartyActionType.T_CERTIFICATION ||
          actionType === thirdPartyActionType.ESG_METRIC
        ) {
          isMsqActive = true;
        }
      });
      return isMsqActive;
    },
    checkIsEddActive() {
      let actionsExtra = this.extraActionsBtn.filter((btn) => btn.isActive);
      let occurActions = this.actionsOccurBtns;
      let actionsData = [];
      actionsData.push(...actionsExtra);
      actionsData.push(...occurActions);
      let eddFilterIndex = actionsData.findIndex(
        (btn) => btn.actionType == "edd"
      );
      let isEDDActive = false;
      if (eddFilterIndex != -1 && !this.selecteditem) {
        isEDDActive = true;
      }
      return isEDDActive;
    },
    isThirdPartyActionActive(tpActionType) {
      const actionsExtra = this.extraActionsBtn.filter((btn) => btn.isActive);
      const isThirdPartyActionTypePresent = !![
        ...actionsExtra,
        ...this.actionsOccurBtns
      ].find(({ actionType }) => actionType === tpActionType);
      if (tpActionType === thirdPartyActionType.T_CERTIFICATION) {
        return isThirdPartyActionTypePresent && !this.selectedTCertItem.value;
      } else if (tpActionType === thirdPartyActionType.ESG_METRIC) {
        return isThirdPartyActionTypePresent && !this.selectedEsgmItem.value;
      }
    },
    checkRerunEddActive() {
      let actionsExtra = this.reScreeningActions.filter((btn) => btn.isActive);
      let eddFilterIndex = actionsExtra.findIndex(
        (btn) => btn.actionType == "edd"
      );
      let isEDDActive = false;
      if (eddFilterIndex != -1 && !this.selecteditem) {
        isEDDActive = true;
      }
      return isEDDActive;
    },
    async addThirdParty() {
      let actionsTaken = [];

      this.actionsOccurBtns.map((actions) => {
        actions.isActive = true;
        actionsTaken.push(actions);
      });
      let filteredExtraActions = this.extraActionsBtn.filter(
        (btn) => btn.isActive
      );
      actionsTaken.push(...filteredExtraActions);

      let IDDTypeIndex = actionsTaken.findIndex((x) => x.actionType == "idd");
      if (IDDTypeIndex != -1) {
        actionsTaken[IDDTypeIndex].requestedDate = new Date();
      }

      let due_diligence_type = "report_type_idd_free";
      let dueDiligenceTypeIndex = actionsTaken.findIndex(
        (x) => x.actionType == "idd_plus"
      );
      if (dueDiligenceTypeIndex != -1) {
        actionsTaken[dueDiligenceTypeIndex].backgroundColor = "#d0f2d2";
        actionsTaken[dueDiligenceTypeIndex].imgName = "ok";
        actionsTaken[dueDiligenceTypeIndex].requestedDate = new Date();
        due_diligence_type = "report_type_idd_plus";
      }

      let isMSQActive = 0;
      actionsTaken.map((actions) => {
        if (actions.actionType == "msq") {
          actions.requestedDate = new Date();
          isMSQActive = 1;
        }
      });

      let isAPRIActive = 0;
      actionsTaken.map((actions) => {
        if (actions.actionType == "apri") {
          actions.backgroundColor = "#e3f4fa";
          actions.imgName = "inprogress";
          actions.requestedDate = new Date();
          isAPRIActive = 1;
        }
      });
      let standardTypeIndex = actionsTaken.findIndex(
        (x) => x.actionType == "pri"
      );
      if (standardTypeIndex != -1) {
        actionsTaken[standardTypeIndex].backgroundColor = "#d0f2d2";
        actionsTaken[standardTypeIndex].imgName = "ok";
        actionsTaken[standardTypeIndex].requestedDate = new Date();
      }
      let eddTypeIndex = actionsTaken.findIndex((x) => x.actionType == "edd");
      let productPriceId = 0;
      if (eddTypeIndex != -1) {
        actionsTaken[eddTypeIndex].backgroundColor = "#e3f4fa";
        actionsTaken[eddTypeIndex].imgName = "inprogress";
        actionsTaken[eddTypeIndex].requestedDate = new Date();
        productPriceId = this.selectedItemArray.product_price_id;
      }

      const tCertTypeIndex = actionsTaken.findIndex(
        (x) => x.actionType === thirdPartyActionType.T_CERTIFICATION
      );
      if (tCertTypeIndex !== -1) {
        actionsTaken[tCertTypeIndex].backgroundColor = colors.bubbles;
        actionsTaken[tCertTypeIndex].imgName =
          thirdPartyActionImageType.IN_PROGRESS;
        actionsTaken[tCertTypeIndex].requestedDate = new Date();
      }

      const esgMTypeIndex = actionsTaken.findIndex(
        (x) => x.actionType === thirdPartyActionType.ESG_METRIC
      );
      if (esgMTypeIndex !== -1) {
        actionsTaken[esgMTypeIndex].backgroundColor = colors.bubbles;
        actionsTaken[esgMTypeIndex].imgName =
          thirdPartyActionImageType.IN_PROGRESS;
        actionsTaken[esgMTypeIndex].requestedDate = new Date();
      }

      let productsArr = this.allElements.filter(
        (element) => element.package_products_id > 0
      );
      let productsName = [];
      productsArr.map((product) => {
        productsName.push(product.product_name);
      });

      let formData = {
        url: this.form.entityThirdPartyUrl,
        companyId: this.companyId,
        userId: this.userId,
        alias: this.form.alias,
        thirdPartyName:
          this.entityTypeSelect === thirdPartyType.ORGANIZATION
            ? this.form.entityName
            : this.form.firstName,
        associates: this.associatesUsers,
        tpiType: this.entityTypeSelect,
        country: this.form.country ? this.form.country.name : null,
        due_diligence_type,
        icompany: this.icompany,
        searchKey: this.searchKey,
        registrationNumber: this.form.registrationNumber,
        dunsNumber: this.form.dunsNumber,
        isApri: isAPRIActive,
        isDdq: isMSQActive,
        dnb_data: {
          isAlatresDataFound: this.isOpenCorporatesDataFound,
          isAlatresDataSelected: this.isOpenCorporatesDataSelected,
          datasetThirdPartyName: this.datasetThirdPartyName,
          ocCompanySelectedCountry: this.ocCompanySelectedCountry,
          searchedCompany:
            this.entityTypeSelect === thirdPartyType.ORGANIZATION
              ? this.form.entityName
              : this.form.firstName,
          selectedReviewCompany: this.selectedOpenCorporateCompany,
          clientCustonAttributeData: []
        },
        ...(!!this.verifiedPayload && {
          verified_details: this.verifiedPayload
        }),
        ...this.additionalDetailForm,
        action_taken: actionsTaken,
        riskRating: this.riskRatingValue,
        riskRatingNotes: this.riskRatingComment,
        custom_attributes: this.customAttributesValuesData,
        memberId: this.systemOwnerId || null,
        verified: this.isVerifiedEntity,
        enriched: this.isEnrichedEntity
      };
      if (eddTypeIndex != -1) {
        formData.edd = {
          bill_name: this.companyDetails.billName,
          bill_email: this.companyDetails.billEmail,
          cost: this.selectedItemArray.client_packages_cost,
          tat: this.selectedItemArray.client_packages_tat,
          tat_max: this.selectedItemArray.client_packages_tat_max,
          package_id: this.selectedItemArray.packagesId,
          package_name: this.selectedItemArray.name || "",
          region_id: this.selectedItemArray.region_id,
          bill_country: this.companyDetails.billCountry,
          billing_add: this.companyDetails.billingAdd,
          eddScope: this.selecteditem,
          product_price_id: productPriceId,
          products: productsName
        };
      }
      formData.products = [];
      if (tCertTypeIndex !== -1) {
        formData.products.push({
          product: actionsTaken[tCertTypeIndex].product,
          product_id: actionsTaken[tCertTypeIndex].id.split("_")[1],
          order_type: this.selectedTCertItem.text,
          order_type_id: this.selectedTCertItem.value
        });
      }
      if (esgMTypeIndex !== -1) {
        formData.products.push({
          product: actionsTaken[esgMTypeIndex].product,
          product_id: actionsTaken[esgMTypeIndex].id.split("_")[1],
          order_type: this.selectedEsgmItem.text,
          order_type_id: this.selectedEsgmItem.value
        });
      }
      this.hideSubmitButton = true;
      try {
        this.hasErrorInSubmit = false;
        this.submiting = true;
        this.isLoading = true;
        this.isLoadingResultReview = true;
        let result = await HTTP(
          "post",
          "thirdparty/create/thirdparty/with/idd",
          formData,
          makeAuthorizationHeader()
        );
        if (result.data.message) {
          this.showCreditsAlert = true;
          this.isLoadingResultReview = false;
        }
        if (result.data && result.data.third_party_id) {
          this.thirdpartyid = result.data.third_party_id;
          localStorage.setItem("editThirdPartyId", this.thirdpartyid);

          this.encryptedProdId = result.data.encryptedProductId;
          this.encryptedSearchId = result.data.encryptedSrchId;
          this.searchTpiId = result.data.srchId;
          this.showingReviewResult = true;
          this.isLoadingResultReview = false;
          this.isFetchResultsDisabled = true;
          this.userTyping = true;
          this.completedActions = result.data.action_taken;
          this.extraActionsBtn.push(...this.actionsOccurBtns);
          this.actionsOccurBtns = [];
          this.collapse = {
            EntityVerification: false,
            AssociateEntities: false,
            AdditionalDetails: false,
            CustomAttributes: false,
            isPremiumCorporateData: false,
            isEntityDetails: false,
            previousSavedEntities: false
          };
          this.extraActionsBtn.map((actions) => {
            actions.isActive = false;
            actions.imgName = "";
            actions.backgroundColor = "";
          });
          this.disableUpdateBtn = true;
          this.getResultPreviewData();
          if (
            productPriceId > 0 &&
            this.companyDetails.eddContract === eddContracts.ORDER
          ) {
            this.$bvToast.toast(
              "Thank you for your Enhanced Due Diligence Order. This order has submitted to our team for action. You will be notified once the report is available on the platform",
              {
                variant: "success",
                autoHideDelay: 5000,
                appendToast: false,
                noCloseButton: true
              }
            );
          } else if (
            productPriceId > 0 &&
            this.companyDetails.eddContract === eddContracts.ENQUIRY
          ) {
            this.$bvToast.toast(
              "Thank you for your Enhanced Due Diligence enquiry. This order has submitted to our team for action. You will be contacted shortly",
              {
                variant: "success",
                autoHideDelay: 5000,
                appendToast: false,
                noCloseButton: true
              }
            );
          }
        }
        this.onChangeRatingStatus = true;
        this.riskRatingComment = null;
        this.isEditModeEnabled = true;
        this.isChangeInAssociates = false;
        this.isChangeAssociateUpdate = false;
        this.isLoadingResultReview = false;
        this.submiting = false;
        this.isLoading = false;
        this.responseSuccess = true;
        this.$store.state.isTpEditModal = true;
        this.navigateToThirdPartyProfile(result?.data?.third_party_id);
      } catch (error) {
        this.hideSubmitButton = false;
        this.isLoadingResultReview = false;
        this.isLoading = false;
        this.submiting = false;
        this.submitFailure();
      }
    },
    submitFailure() {
      this.responseSuccess = false;
      this.hasErrorInSubmit = true;
      this.submitErrorMessage =
        "Something went wrong, please try again. If the issue persists please contact support.";
    },
    async getResultPreviewData(editData = null) {
      let srchId = editData ? editData.srch_tpi_id_enc : this.encryptedSearchId;
      let prodId = editData
        ? editData.encryptedProductId
        : this.encryptedProdId;
      try {
        this.isLoadingResultData = true;
        this.isLoadingAssociatesTable = true;
        this.isAssoLoading = true;
        let result = await HTTP(
          "get",
          `thirdparty/result-preview-msq/${srchId}/${this.userId}/${prodId}/${this.companyId}`,
          null,
          makeAuthorizationHeader(),
          null,
          true
        );
        if (
          result.data.data &&
          result.data.status == 1 &&
          result.status == 200
        ) {
          if (result.data.data.shield_vendor_infos) {
            this.selectedEntityType = result.data.data.shield_vendor_infos
              .tpi_type
              ? result.data.data.shield_vendor_infos.tpi_type
              : null;
          }
          this.form.entityName = result?.data?.data?.thirdPartyName;
          this.searchReportData = result.data.data;
          if (this.completedActions.length != 0) {
            let iddTypeIndex = this.completedActions.findIndex(
              (x) => x.actionType == "idd"
            );
            if (
              iddTypeIndex != -1 &&
              this.searchReportData &&
              this.searchReportData.shield_vendor_infos &&
              this.searchReportData.shield_vendor_infos.risk_level
            ) {
              this.completedActions[iddTypeIndex].imgName =
                this.searchReportData.shield_vendor_infos.risk_level == 1
                  ? "ok"
                  : this.searchReportData.shield_vendor_infos.risk_level == 3
                  ? "HighAlert"
                  : this.searchReportData.shield_vendor_infos.risk_level == 5
                  ? "RemediatedOK"
                  : this.searchReportData.shield_vendor_infos.risk_level == 6
                  ? "remediatedHighAlert"
                  : "ok";
            }
            let dueDiligenceTypeIndex = this.completedActions.findIndex(
              (x) => x.actionType == "idd_plus"
            );
            if (dueDiligenceTypeIndex != -1) {
              this.completedActions[dueDiligenceTypeIndex].imgName =
                this.searchReportData.shield_vendor_infos.idd_color == 1
                  ? "ok"
                  : this.searchReportData.shield_vendor_infos.idd_color == 3
                  ? "HighAlert"
                  : this.searchReportData.shield_vendor_infos.idd_color == 5
                  ? "RemediatedOK"
                  : this.searchReportData.shield_vendor_infos.idd_color == 6
                  ? "remediatedHighAlert"
                  : "";
            }
            let priTypeIndex = this.completedActions.findIndex(
              (x) => x.actionType == "pri"
            );
            if (priTypeIndex != -1) {
              this.completedActions[priTypeIndex].imgName =
                this.searchReportData.shield_vendor_infos.ethix_risk_rating == 1
                  ? "ok"
                  : this.searchReportData.shield_vendor_infos
                      .ethix_risk_rating == 2
                  ? "WarningAlert"
                  : this.searchReportData.shield_vendor_infos
                      .ethix_risk_rating == 3
                  ? "HighAlert"
                  : "ok";
            }
            let eddTypeIndex = this.completedActions.findIndex(
              (x) => x.actionType == "edd"
            );
            if (eddTypeIndex != -1) {
              this.completedActions[eddTypeIndex].imgName =
                this.searchReportData.shield_vendor_infos.edd_color == 1
                  ? "ok"
                  : this.searchReportData.shield_vendor_infos.edd_color == 3
                  ? "HighAlert"
                  : "inprogress";

              let eddIndex = this.extraActionsBtn.findIndex(
                (x) => x.actionType == "edd"
              );
              if (
                eddIndex != -1 &&
                this.completedActions[eddTypeIndex].imgName == "inprogress"
              ) {
                this.extraActionsBtn.splice(eddIndex, 1);
              }
            }
            iddTypeIndex = this.completedActions.findIndex(
              (x) => x.actionType == "idd"
            );
            if (iddTypeIndex != -1) {
              if (
                parseInt(this.headerConfigData.specialFeature.autoremidd) &&
                this.completedActions[iddTypeIndex].imgName == "HighAlert"
              ) {
                this.completedActions.push({
                  id: 9,
                  btnText: "Remediate IDD",
                  gridBtntext: "R IDD",
                  isActive: false,
                  actionType: "remediate_idd",
                  isHidden: false,
                  backgroundColor: "#e3f4fa",
                  imgName: "inprogress"
                });
              } else if (
                this.completedActions[iddTypeIndex].imgName ==
                "remediatedHighAlert"
              ) {
                let remediateIddTypeIndex = this.completedActions.findIndex(
                  (x) => x.actionType == "remediate_idd"
                );
                if (remediateIddTypeIndex != -1) {
                  this.completedActions.splice(remediateIddTypeIndex, 1);
                }
              }
            }
            dueDiligenceTypeIndex = this.completedActions.findIndex(
              (x) => x.actionType == "idd_plus"
            );
            if (dueDiligenceTypeIndex != -1) {
              if (
                parseInt(this.headerConfigData.specialFeature.autoremiddplus) &&
                this.completedActions[dueDiligenceTypeIndex].imgName ==
                  "HighAlert"
              ) {
                this.completedActions.push({
                  id: 10,
                  btnText: "Remediate IDD+",
                  gridBtntext: "R IDD+",
                  isActive: false,
                  actionType: "remediate_idd_plus",
                  isHidden: false,
                  backgroundColor: "#e3f4fa",
                  imgName: "inprogress"
                });
              } else if (
                this.completedActions[dueDiligenceTypeIndex].imgName ==
                "remediatedHighAlert"
              ) {
                let remediateIddPlusTypeIndex = this.completedActions.findIndex(
                  (x) => x.actionType == "remediate_idd_plus"
                );
                if (remediateIddPlusTypeIndex != -1) {
                  this.completedActions.splice(remediateIddPlusTypeIndex, 1);
                }
              }
            }
            const tCertTypeCompletedIndex = this.completedActions.findIndex(
              (action) =>
                action.actionType === thirdPartyActionType.T_CERTIFICATION
            );
            if (tCertTypeCompletedIndex !== -1) {
              const tCertTypeProductIndex =
                this.searchReportData.product_orders.findIndex(
                  (product) =>
                    product.product_id ===
                    this.completedActions[tCertTypeCompletedIndex].id.split(
                      "_"
                    )[1]
                );
              if (tCertTypeProductIndex !== -1) {
                this.completedActions[tCertTypeCompletedIndex].imgName =
                  this.searchReportData.product_orders[tCertTypeProductIndex]
                    .status === thirdPartyActionProductStatus.IN_PROGRESS
                    ? thirdPartyActionImageType.IN_PROGRESS
                    : "";
              }
              const tCertIndex = this.extraActionsBtn.findIndex(
                (action) =>
                  action.actionType === thirdPartyActionType.T_CERTIFICATION
              );
              if (
                tCertIndex !== -1 &&
                this.completedActions[tCertTypeCompletedIndex].imgName ===
                  thirdPartyActionImageType.IN_PROGRESS
              ) {
                this.extraActionsBtn.splice(tCertIndex, 1);
              }
            }
            const esgmTypeCompletedIndex = this.completedActions.findIndex(
              (action) => action.actionType === thirdPartyActionType.ESG_METRIC
            );
            if (esgmTypeCompletedIndex !== -1) {
              const esgmTypeProductIndex =
                this.searchReportData.product_orders.findIndex(
                  (product) =>
                    product.product_id ===
                    this.completedActions[esgmTypeCompletedIndex].id.split(
                      "_"
                    )[1]
                );
              if (esgmTypeProductIndex !== -1) {
                this.completedActions[esgmTypeCompletedIndex].imgName =
                  this.searchReportData.product_orders[esgmTypeProductIndex]
                    .status === thirdPartyActionProductStatus.IN_PROGRESS
                    ? thirdPartyActionImageType.IN_PROGRESS
                    : "";
              }
              const esgmIndex = this.extraActionsBtn.findIndex(
                (action) =>
                  action.actionType === thirdPartyActionType.ESG_METRIC
              );
              if (
                esgmIndex !== -1 &&
                this.completedActions[esgmTypeCompletedIndex].imgName ===
                  thirdPartyActionImageType.IN_PROGRESS
              ) {
                this.extraActionsBtn.splice(esgmIndex, 1);
              }
            }
          }

          this.form.dunsNumber =
            result?.data?.data?.oc_orginfos_srch20[0]?.company_number;
          if (this.form.dunsNumber) {
            this.fetchCompanyCorporateData(this.form.dunsNumber);
          } else {
            this.isLoadingAssociatesTable = false;
            this.isAssoLoading = false;
          }

          this.removePerformedActions();
          let dnb_data = result.data.data.dnb_data
            ? JSON.parse(result.data.data.dnb_data)
            : null;
          this.tpDnbData = dnb_data;
          this.tpDnbSelectedAltaresTableItemData =
            dnb_data.selectedAltaresTableItem
              ? dnb_data.selectedAltaresTableItem.organization
                ? dnb_data.selectedAltaresTableItem.organization
                : null
              : null;
          this.beneficialOwnershipRegistrationData =
            dnb_data.beneficialOwnershipRegistrationData
              ? dnb_data.beneficialOwnershipRegistrationData
              : {};
          this.isLoadingAssociatesTable = false;
          this.showNoteTextCall(result);
        } else {
          this.isLoadingAssociatesTable = false;
          this.isLoadingResultData = false;
        }
      } catch {
        this.isLoadingAssociatesTable = false;
        this.isLoadingResultData = false;
      }
    },
    showNoteTextCall(result) {
      if (result.data.data.productType == 2) {
        if (result.data.data.shield_vendor_infos) {
          if (result.data.data.shield_vendor_infos.is_idd == "1") {
            if (result.data.data.shield_vendo_infos.idd_color == "3") {
              if (result.data.data.shield_vedo_infos.idd_color == "1") {
                if (result.data.data.srch_tpi_rdc_indivi_alrt.length) {
                  this.showNoteText = true;
                }
              } else if (result.data.data.shield_vedo_infos.idd_color == "3") {
                if (result.data.data.srch_tpi_rdc_indivi_alrt.length) {
                  this.showNoteText = true;
                }
              } else {
                this.showNoteText = false;
              }
            } else {
              this.showNoteText = false;
            }
          } else {
            this.showNoteText = false;
          }
        } else {
          this.showNoteText = false;
        }
      } else {
        this.showNoteText = false;
      }
      this.isLoading = false;
    },
    onSelectEntityType(value, associatesUsersIndex) {
      this.isLoadingAssociatesValidation = true;
      this.checkAssociateChange();
      if (value) {
        this.associatesUsers[associatesUsersIndex].entityType = value;
        this.associatesUsers[associatesUsersIndex].validationStateEntityType =
          null;
      } else {
        this.associatesUsers[associatesUsersIndex].entityType = null;
      }
      this.isLoadingAssociatesValidation = false;
    },
    onInputAssosName(value, associatesUsersIndex) {
      this.isLoadingAssociatesValidation = true;
      this.checkAssociateChange();
      if (value) {
        this.associatesUsers[associatesUsersIndex].validationStateAssosName =
          null;
      } else {
        this.associatesUsers[associatesUsersIndex].assoName = null;
      }
      this.isLoadingAssociatesValidation = false;
    },
    selectAssociatedUsersData(value, data) {
      if (value) {
        if (this.associatesUsers[0].assoName == null) {
          this.associatesUsers = [];
          this.associatesUsers.push({
            firstName: null,
            assoName: data?.item?.fullName,
            entityType: thirdPartyType.INDIVIDUAL,
            id: data?.item?.id,
            position: data?.item?.jobTitles?.[0]?.title,
            meta_data: data?.item,
            validationStateEntityType: null,
            validationStateAssosName: null
          });
        } else {
          this.associatesUsers.push({
            firstName: null,
            assoName: data?.item?.fullName,
            entityType: thirdPartyType.INDIVIDUAL,
            id: data?.item?.id,
            position: data?.item?.jobTitles?.[0]?.title,
            meta_data: data?.item,
            validationStateEntityType: null,
            validationStateAssosName: null
          });
        }
      } else {
        let associatesUsersIndex = this.associatesUsers.findIndex(
          (x) => x.assoName === data.item.fullName
        );
        this.associatesUsers.splice(associatesUsersIndex, 1);
        if (this.associatesUsers.length == 0) {
          this.associatesUsers.push({
            firstName: null,
            assoName: null,
            entityType: thirdPartyType.INDIVIDUAL,
            id: uid(),
            position: null,
            meta_data: null,
            validationStateEntityType: null,
            validationStateAssosName: null
          });
        }
      }
      this.checkAssociateChange();
    },
    async getOpenCorporatesCompanyOfficers(country, countryRegistrationNumber) {
      this.openCorporatesCompanyOfficersItems = [];
      let formData = {
        country:
          country ||
          (this.selectedOpenCorporateCompany
            ? this.selectedOpenCorporateCompany.jurisdiction_code
            : null),
        openCorporateCompanyRegistrationNumber:
          countryRegistrationNumber ||
          (this.selectedOpenCorporateCompany
            ? this.selectedOpenCorporateCompany.company_number
            : null)
      };
      this.isLoadingAssociatesTable = true;
      this.isAssoLoading = true;
      let result = HTTP(
        "post",
        "thirdparty/getopencorporatecompanyofficers",
        formData,
        makeAuthorizationHeader()
      );

      return result
        .then((response) => {
          this.openCorporatesCompanyOfficersItems = response.data.response.data
            ? response.data.response.data
            : [];
          this.openCorporatesCompanyOfficersItems.map(
            (companyOfficers, index) => {
              this.associatesUsers.map((users) => {
                if (users.id == companyOfficers.id) {
                  this.selectedCompanyOfficers[index] = true;
                }
              });
            }
          );
          this.isLoadingAssociatesTable = false;
          this.showingAssociatesTable = true;
          this.isAssoLoading = false;
        })
        .catch((error) => {
          this.isLoadingAssociatesTable = false;
          this.isAssoLoading = false;
          return [error];
        });
    },
    clearOpenCorporateSectionData() {
      this.selectedIndividualRowCountry = null;
      this.selectedCompanyOfficers = [];

      this.isShowingOpenCorporateData = false;
      this.verificationTableCollapse = false;
      this.verificationTableCollapseTwo = false;
      this.verificationTableCollapseThree = false;
      this.tableWithoutCountryItems = [];
      this.isLoadingForOthers = false;
      this.tableWithIndividualItems = [];
      this.clearAssociatesSectionData();
    },
    clearAssociatesSectionData() {
      localStorage.removeItem("encryptedSrchId");
      localStorage.removeItem("encryptedProductId");
      localStorage.removeItem("encryptedReportId");

      this.openCorporatesCompanyOfficersItems = [];
      this.showingAssociatesTable = false;
      this.ocCompanySelectedCountry = null;
      this.isOpenCorporatesDataSelected = false;
      this.datasetThirdPartyName = null;
      this.selectedOpenCorporateCompany = null;
      this.selectedOpenCorporateCompany = null;
      this.selectedIndividualRowCountry = null;
      this.associatesUsers = [
        {
          firstName: null,
          assoName: null,
          entityType: thirdPartyType.INDIVIDUAL,
          id: null,
          position: null,
          meta_data: null,
          validationStateEntityType: null,
          validationStateAssosName: null
        }
      ];
      this.orignalAssociatesUsers = [
        {
          firstName: null,
          assoName: null,
          entityType: thirdPartyType.INDIVIDUAL,
          id: null,
          position: null,
          meta_data: null,
          validationStateEntityType: null,
          validationStateAssosName: null
        }
      ];
      this.reportId = null;
      this.searchId = null;
      this.encryptedProductId = null;
      this.icompany = {};
      this.searchKey = null;
      this.isOpenCorporatesDataFound = false;
    },
    setOpenCorporateData(data) {
      this.clearAssociatesSectionData();
      if (this.entityTypeSelect === thirdPartyType.ORGANIZATION) {
        this.form.entityName = data.item.name;
        this.datasetThirdPartyName = data.item.name;
      } else {
        this.selectedIndividualRowCountry = data.index;
        this.datasetThirdPartyName = data.item.company.name;
      }
      this.isOpenCorporatesDataSelected = true;
      this.isOpenCorporatesDataFound = true;

      this.selectedOpenCorporateCompany = null;
      this.selectedOpenCorporateCompany = data.item;
      this.collapse.isPremiumCorporateData = true;
      if (this.entityTypeSelect === thirdPartyType.ORGANIZATION) {
        let icompany = {};
        this.searchKey = data.item.company_number;
        icompany[
          data.item.company_number
        ] = `${data.item.jurisdiction_code}|${data.item.company_number}`;

        this.icompany = {};
        this.icompany = icompany;
        this.ocCompanySelectedCountry = data.item.jurisdiction_code;
        let countryCodeIndex = this.countries.findIndex(
          (isoCode1) => isoCode1.toLowerCase() === data.item.jurisdiction_code
        );
        if (countryCodeIndex !== -1) {
          this.form.country = this.countries[countryCodeIndex];
        }
        this.getOpenCorporatesCompanyOfficers();
        this.showingAssociatesTable = true;
      } else {
        let icompany = {};
        this.searchKey = data.item.company.company_number;
        icompany[
          data.item.company.company_number
        ] = `${data.item.company.jurisdiction_code}|${data.item.id}`;

        this.icompany = {};
        this.icompany = icompany;
      }
    },
    getJurisdicationCountryName(jurisdiction_code) {
      let countryCodeIndex = this.countries.findIndex(
        ({ isoCode1 }) => isoCode1.toLowerCase() === jurisdiction_code
      );

      return this.countries[countryCodeIndex];
    },
    getCountryNames(jurisdiction_code) {
      let OCStatesIndex = this.OCStatesData.findIndex(
        (x) => x.code == jurisdiction_code
      );
      return OCStatesIndex;
    },
    async getPreviouslySavedEntities() {
      const formData = {
        comp_name_eng:
          this.entityTypeSelect === thirdPartyType.ORGANIZATION
            ? this.form.entityName
            : this.form.firstName,
        company_id: this.companyId,
        country: this.form?.country?.name,
        page: this.currentPageExistingItems,
        per_page: this.perPageExistingItems
      };

      if (this.existingItems.length) {
        this.isLoadingPreviouslySavedEntities = true;
      }

      const result = HTTP(
        "post",
        "thirdparty/existing",
        formData,
        makeAuthorizationHeader()
      );

      return result
        .then(({ data } = {}) => {
          this.fromRowsExistingItems = data?.from || 0;
          this.toRowsExistingItems = data?.to || 0;
          this.totalRowsExistingItems = data?.total || 0;
          this.existingItems = data?.data || [];
        })
        .catch((error) => [error])
        .finally(() => {
          this.isLoadingPreviouslySavedEntities = false;
        });
    },
    async fetchCompanyOrganizations() {
      if (this.isStateNameOptional) {
        this.isLoadingCountryData = true;
        this.isEntityVerificationReducedView = true;
        this.isEntityVerificationPerformed = false;
        this.isVerifiedEntity = false;
        try {
          const { data = {} } =
            await thirdPartyService.fetchCompanyOrganizations(this.companyId, {
              jurisdiction:
                this.form?.country?.isoCode1?.toLowerCase() ||
                this.getCountryCodeBasedOnCountryName(this.form?.country),
              name: this.form.entityName,
              address: this.additionalDetailForm.address,
              city: this.additionalDetailForm.city,
              state: this.selectedStateCode,
              zipCode: this.additionalDetailForm.postal_code,
              registrationNumber: this.form.registrationNumber,
              url: this.form?.entityThirdPartyUrl
            });

          this.entityVerificationData = this.filterEntityVerificationData(
            data?.response?.data
          );
          this.isEntityVerificationPerformed = true;
          this.setEntityVerificationTableHeaders();
          this.setEntityVerificationTableRows();
          await this.$nextTick();
          this.$refs?.["entity-verification"]?.scrollIntoView?.({
            behavior: "smooth"
          });
        } catch ({ status }) {
          this.fetchCompanyCorporateDataFailure(status);
        } finally {
          this.isLoadingCountryData = false;
        }
      } else {
        this.isStateErrorRequired = true;
        this.$refs?.["entity-details-accordion"]?.$el?.scrollIntoView?.({
          behavior: "smooth"
        });
      }
    },
    filterEntityVerificationData(data) {
      return data.filter((item) => item?.match_confidence >= 5);
    },
    fetchCompanyCorporateDataFailure(status) {
      Helper.makeToast(
        this.$bvToast,
        "danger",
        `There is an unknown error with status of ${status}. Please contact support`
      );
    },
    setEntityVerificationTableHeaders() {
      this.entityVerificationTableHeaders = this
        .makeCompanyOrganizationsListForTable?.length
        ? Object.keys(this.makeCompanyOrganizationsListForTable[0]).reduce(
            (acc, key) => {
              if (companyOrganizationsTableHeaders[key]) {
                acc.push({
                  value: key,
                  component: BaseText,
                  componentOptions: {
                    tag: "span",
                    text: companyOrganizationsTableHeaders[key],
                    size: typographySize.BODY_TEXT_BOLD,
                    styles: {
                      margin: "0"
                    }
                  },
                  styles: {
                    minWidth: "80px",
                    padding: "10px 15px",
                    textAlign: this.setEntityVerificationTableCellAlignment(key)
                  }
                });
              }
              return acc;
            },
            []
          )
        : [];
    },
    setEntityVerificationTableCellAlignment(key) {
      switch (key) {
        case companyOrganizationsKeys.SELECT:
        case companyOrganizationsKeys.OPERATING_STATUS:
        case companyOrganizationsKeys.MATCH_CONFIDENCE:
        case companyOrganizationsKeys.MORE_INFO:
          return "center";
        default:
          return "left";
      }
    },
    setEntityVerificationTableRows() {
      this.entityVerificationTableRows =
        this.makeCompanyOrganizationsListForTable?.map((action, index) =>
          Object.keys(action).reduce((acc, key) => {
            if (key === companyOrganizationsKeys.SELECT) {
              acc[key] = {
                component: Checkbox,
                componentOptions: {
                  label: "Select",
                  labelHidden: true,
                  modelValue: false,
                  "onUpdate:modelValue": () =>
                    this.handleEntityVerificationSelect(index)
                },
                styles: {
                  justifyContent: "center"
                },
                valueFormatter: (params) =>
                  params.value.componentOptions.modelValue
              };
            } else if (key === companyOrganizationsKeys.OPERATING_STATUS) {
              acc[key] = {
                component: BaseBadge,
                componentOptions: {
                  tag: "span",
                  theme:
                    action[key] === entityOperatingStatuses.ACTIVE
                      ? themes.SUCCESS_WHITE
                      : themes.LIGHT_GREY,
                  text: action[key],
                  style: {
                    width: "fit-content",
                    margin: "0 auto"
                  }
                }
              };
            } else if (key === companyOrganizationsKeys.MORE_INFO) {
              acc[key] = {
                component: CallToAction,
                componentOptions: {
                  icon: "chevron-down",
                  iconShape: shapes.CIRCLE,
                  iconSize: 12,
                  onClick: () => {
                    this.handleEntityVerificationMoreInfoClick(index);
                  }
                },
                styles: {
                  textAlign: this.setEntityVerificationTableCellAlignment(key)
                }
              };
            } else if (key === tableOptions.ACCORDION_OPTIONS) {
              acc[key] = {
                [tableOptions.IS_ACCORDION_VISIBLE]: false,
                component: TextDisplayList,
                componentOptions: {
                  list: this.makeEntityVerificationDropdownList(action[key])
                }
              };
            } else {
              acc[key] = {
                component: BaseText,
                componentOptions: {
                  tag: "span",
                  text: [action[key]]
                },
                styles: {
                  textAlign: this.setEntityVerificationTableCellAlignment(key)
                }
              };
            }
            return acc;
          }, {})
        ) || [];
    },
    makeEntityVerificationDropdownList(values) {
      return [
        {
          labelOptions: {
            text: "Key person"
          },
          valueOptions: {
            text: values?.[companyOrganizationsKeys.KEY_PERSON] || "-"
          }
        },
        {
          labelOptions: {
            text: "Company registration no."
          },
          valueOptions: {
            text:
              values?.[companyOrganizationsKeys.COMPANY_REGISTRATION_NUMBER] ||
              "-"
          }
        }
      ];
    },
    setEntityAddress({ street_address = "", postal_code = "", country = "" }) {
      return `${street_address}, ${postal_code}, ${country}.`;
    },
    handleShowMoreEntitiesClick() {
      const index = this.entityVerificationSelectedItemIndex;

      this.isEntityVerificationReducedView = false;
      this.setEntityVerificationTableHeaders();
      this.setEntityVerificationTableRows();

      if (index >= 0) this.handleEntityVerificationSelect(index);
    },
    handleEntityVerificationSelect(selectedIndex) {
      this.entityVerificationTableRows.forEach((row, index) => {
        row[companyOrganizationsKeys.SELECT].componentOptions.modelValue =
          index === selectedIndex
            ? !row[companyOrganizationsKeys.SELECT].componentOptions.modelValue
            : false;
      });
    },
    onCloseEntityUpdateModal() {
      this.isEntityUpdatePopUpVisible = false;
      this.selectedEntityName = null;
      this.isConfirmWithPremiumCorporateData = false;
    },
    toggleDiscardChangesModalVisibility() {
      this.isDiscardChangesModalVisible = !this.isDiscardChangesModalVisible;
    },
    handleEntityVerificationMoreInfoClick(selectedIndex) {
      const accordionVisibilityValue =
        !this.entityVerificationTableRows[selectedIndex][
          tableOptions.ACCORDION_OPTIONS
        ][tableOptions.IS_ACCORDION_VISIBLE];

      this.entityVerificationTableRows[selectedIndex][
        companyOrganizationsKeys.MORE_INFO
      ].componentOptions.icon = accordionVisibilityValue
        ? "chevron-up"
        : "chevron-down";

      this.entityVerificationTableRows[selectedIndex][
        tableOptions.ACCORDION_OPTIONS
      ][tableOptions.IS_ACCORDION_VISIBLE] = accordionVisibilityValue;
    },
    async getOpenCorporatesCompanyIndividual() {
      this.isLoadingForIndividual = true;
      let formData = {
        page: this.currentPageIndividual,
        per_page: this.perPageIndividual,
        search: this.form.firstName
      };
      let result = HTTP(
        "post",
        "thirdparty/getopencorporatesindiviual",
        formData,
        makeAuthorizationHeader()
      );

      return result
        .then((response) => {
          let returnTableItems = [];

          response.data.response.data.map((resultData) => {
            returnTableItems.push(resultData);
          });
          this.tableWithIndividualItems = [];
          this.fromRowsIndividual =
            returnTableItems.length == 0 ? 0 : response.data.response.from;
          this.toRowsIndividual =
            returnTableItems.length == 0 ? 0 : response.data.response.to;
          this.totalRowsIndividual = response.data.response.total;
          if (
            response.data.response.total_pages &&
            response.data.response.total_pages <= 2000
          ) {
            this.totalPaginationIndividual = response.data.response.total;
          } else {
            this.totalPaginationIndividual = 10000;
          }

          this.tableWithIndividualItems = returnTableItems.length
            ? returnTableItems
            : [];

          this.isOpenCorporatesDataFound = !!response.data.response.data.length;
          this.isLoadingForIndividual = false;
          return returnTableItems;
        })
        .catch((error) => {
          this.isLoadingForIndividual = false;
          return [error];
        });
    },
    async getOpenCorporatesCompany() {
      let formData = {
        page: this.currentPageOtherCountries,
        per_page: this.perPageOtherCountries,
        search: this.form.entityName
      };
      this.isLoadingForOthers = true;
      let result = HTTP(
        "post",
        "thirdparty/getopencorporatescompany",
        formData,
        makeAuthorizationHeader()
      );

      return result
        .then((response) => {
          let returnTableItems = [];

          response.data.response.data.map((resultData) => {
            returnTableItems.push(resultData);
          });
          this.tableWithoutCountryItems = [];
          this.fromRowsOtherCountries =
            returnTableItems.length == 0 ? 0 : response.data.response.from;
          if (this.recordsFoundInCountry == 0) {
            this.recordsFoundInCountry =
              response.data.response.records_in_country == 0
                ? 0
                : response.data.response.records_in_country;
          }
          this.toRowsOtherCountries =
            returnTableItems.length == 0 ? 0 : response.data.response.to;
          this.totalRowsOtherCountries = response.data.response.total;
          if (
            response.data.response.total_pages &&
            response.data.response.total_pages <= 2000
          ) {
            this.totalPaginationRowsOtherCountries =
              response.data.response.total;
          } else {
            this.totalPaginationRowsOtherCountries = 10000;
          }
          this.isLoadingForOthers = false;

          this.tableWithoutCountryItems = returnTableItems.length
            ? returnTableItems
            : [];

          this.isOpenCorporatesDataFound = !!response.data.response.data.length;
          return returnTableItems;
        })
        .catch((error) => {
          this.isLoadingForOthers = false;
          return [error];
        });
    },
    getAvailableExtraActions({ extraActions = [], erafActions = [] } = {}) {
      return [...extraActions, ...erafActions] || [];
    },
    modalShow(data) {
      this.selectedRiskRatingText = "Unclassified";
      this.riskRatingValue = 0;
      this.riskRatingComment = null;

      if (data) {
        const extraActions = this.getAvailableExtraActions(data);

        if (data.actionsOccur || extraActions) {
          this.actionsOccurBtns = data.actionsOccur ? data.actionsOccur : [];
          if (data.extraActions) {
            extraActions.map((items) => {
              if (!items.isHidden) {
                this.extraActionsBtn.push(items);
              }
            });
          }
        } else {
          const defaultExtraActions =
            this.getAvailableExtraActions(defaultActionJson);

          this.actionsOccurBtns = defaultActionJson.actionsOccur;
          defaultExtraActions.map((items) => {
            if (!items.isHidden) {
              this.extraActionsBtn = defaultExtraActions;
            }
          });
          this.getQuestionnariesData();
        }
      } else {
        const defaultExtraActions =
          this.getAvailableExtraActions(defaultActionJson);

        this.actionsOccurBtns = defaultActionJson.actionsOccur;
        defaultExtraActions.map((items) => {
          if (!items.isHidden) {
            this.extraActionsBtn = defaultExtraActions;
          }
        });
        this.getQuestionnariesData();
      }
      this.actionsOccurBtns.map((actions) => {
        actions.isActive = true;
        actions.imgName = "";
        actions.backgroundColor = "";
      });
      this.extraActionsBtn.map((actions) => {
        actions.imgName = "";
        actions.backgroundColor = "";
      });
    },
    async getQuestionnariesData() {
      try {
        let result = await HTTP(
          "get",
          `v1/getactiveformslist/${this.companyId}`,
          null,
          makeAuthorizationHeader()
        );
        if (result.data) {
          this.extraActionsBtn.push(...result.data.questionnaires);
          this.extraActionsBtn.push(...result.data.products);
        }
      } catch ({ status }) {
        Helper.makeToast(
          this.$bvToast,
          "danger",
          `There is an unknown error with status of ${status}. Please contact support`
        );
      }
    },
    handleEditModeDataDisplay(data, configuredActionData, value) {
      if (configuredActionData != null) {
        const extraActions =
          this.getAvailableExtraActions(configuredActionData);

        if (configuredActionData.actionsOccur || extraActions) {
          if (extraActions) {
            extraActions.map((items) => {
              if (!items.isHidden) {
                this.extraActionsBtn.push(items);
              }
            });
          }
          if (configuredActionData.actionsOccur) {
            this.extraActionsBtn.push(...configuredActionData.actionsOccur);
          }
        } else {
          const defaultExtraActions =
            this.getAvailableExtraActions(defaultActionJson);

          defaultExtraActions.map((items) => {
            if (!items.isHidden) {
              this.extraActionsBtn = defaultExtraActions;
            }
          });
          this.getQuestionnariesData();
        }
      } else {
        const defaultExtraActions =
          this.getAvailableExtraActions(defaultActionJson);

        defaultExtraActions.map((items) => {
          if (!items.isHidden) {
            this.extraActionsBtn = defaultExtraActions;
          }
        });
        this.getQuestionnariesData();
      }
      this.extraActionsBtn.map((actions) => {
        actions.imgName = "";
        actions.backgroundColor = "";
      });
      if (value != null && value != "") {
        if (typeof value == "string") {
          this.extraActionsBtn.map((actions) => {
            if (actions.actionType == value || actions.gridBtntext == value) {
              actions.isActive = true;
              actions.backgroundColor = "#d0f2d2";
            }
          });
        } else {
          this.extraActionsBtn.map((actions) => {
            value.map((data) => {
              if (actions.actionType == data || actions.gridBtntext == data) {
                actions.isActive = true;
                actions.backgroundColor = "#d0f2d2";
              }
            });
          });
        }
        this.disableUpdateBtn = false;
      }
      this.associatesUsers = [];
      this.orignalAssociatesUsers = [];
      // srch_id_enc
      if (data) {
        let actionData = data.meta_json ? JSON.parse(data.meta_json) : {};
        this.actionTakenData = actionData.action_taken
          ? actionData.action_taken
          : [];
        let removedActionsArray = actionData.remove_actions
          ? actionData.remove_actions
          : [];

        if (
          (data.negativeMediaKeywordTotalScoring &&
            data.negativeMediaKeywordTotalScoring != null) ||
          data.negativeMediaKeywordTotalScoring != "null" ||
          (data.futureRiskKeywordTotalScoring &&
            data.futureRiskKeywordTotalScoring != null) ||
          data.futureRiskKeywordTotalScoring != "null"
        ) {
          this.actionTakenData.map((action) => {
            if (action.actionType == "apri") {
              action.imgName = "";
            }
          });
        }
        if (data.risk_rating) {
          this.selectedRiskRatingText =
            this.riskRatingJsonData.myRiskRatingData.status[
              data.risk_rating
            ].text;
          this.selectedRiskRatingIcon = getRiskRatingIcon(
            data.risk_rating,
            iconSizes.DEFAULT
          );
        }

        this.thirdpartyid = data.thirdpartyid;
        localStorage.setItem("editThirdPartyId", this.thirdpartyid);
        this.associatesUsers = [];
        this.form.entityThirdPartyUrl = data.website;
        this.form.alias = data?.alias;
        this.form.entityName = data.comp_name_eng;
        this.originalEntityName = data.comp_name_eng;
        this.form.firstName = data.comp_name_eng;
        this.entityTypeSelect = data.tpi_type;
        this.form.country = data.country;
        this.selectedCountryName = data.country;
        let languageIndex = -1;
        if (
          data.third_party_primary_contact &&
          data.third_party_primary_contact.language
        ) {
          languageIndex = this.formsLanguagesList.findIndex(
            (x) => x.languageCode == data.third_party_primary_contact.language
          );
        }
        this.additionalDetailForm = {
          address: data.address,
          city: data.city,
          state: data.state,
          postal_code: data.postal_code,
          contactName: data.inf_contact_name,
          contactEmail: data.third_party_primary_contact
            ? data.third_party_primary_contact.email_address
              ? data.third_party_primary_contact.email_address
              : data.lg_email
            : data.lg_email,
          contactPhone: data.tel_no,
          contactLanguage:
            languageIndex != -1 && data.third_party_primary_contact
              ? data.third_party_primary_contact.language
                ? this.formsLanguagesList[languageIndex].languages
                : this.additionalDetailForm.contactLanguage
              : this.additionalDetailForm.contactLanguage,
          contactLanguageCode: data.third_party_primary_contact
            ? data.third_party_primary_contact.language
              ? data.third_party_primary_contact.language
              : this.additionalDetailForm.contactLanguageCode
            : this.additionalDetailForm.contactLanguageCode
        };
        this.systemOwnerId = parseInt(data.user_id) || null;
        this.setSelectedSystemOwner(data.user_id);
        this.userTyping = !!(
          this.additionalDetailForm.contactEmail != null &&
          this.additionalDetailForm.contactEmail
        );
        this.form.registrationNumber = data.registration_number;
        this.riskRatingValue = data.risk_rating;

        this.onEditRiskRatingValue = data.risk_rating;
        this.completedActions = this.makeActionTakenAllIsActive(
          this.actionTakenData
        );
        this.removedActions = removedActionsArray;
        this.removePerformedActions();

        if (this.entityTypeSelect === thirdPartyType.INDIVIDUAL) {
          let checkCompanyIncludes = data.comp_name_eng.includes("(");
          if (checkCompanyIncludes) {
            this.form.searchAssociatedCompaniesValue = "yes";
          } else {
            this.form.searchAssociatedCompaniesValue = "no";
          }
        }

        this.searchTpiId = data.srch_tpis_latest.id;
        data.srch_tpi_assos.forEach((element) => {
          if (element.asso_name) {
            this.associatesUsers.push({
              firstName: null,
              assoName: element.asso_name,
              entityType: element.srch_type,
              id: element.meta_data ? JSON.parse(element.meta_data).id : null,
              position: element.asso_posi,
              meta_data: element.meta_data
                ? JSON.parse(element.meta_data)
                : null,
              validationStateEntityType: null,
              validationStateAssosName: null
            });
            this.orignalAssociatesUsers.push({
              firstName: null,
              assoName: element.asso_name,
              entityType: element.srch_type,
              id: element.meta_data ? JSON.parse(element.meta_data).id : null,
              position: element.asso_posi,
              meta_data: element.meta_data
                ? JSON.parse(element.meta_data)
                : null,
              validationStateEntityType: null,
              validationStateAssosName: null
            });
          } else {
            this.associatesUsers = [
              {
                firstName: null,
                assoName: null,
                entityType: thirdPartyType.INDIVIDUAL,
                id: null,
                position: null,
                meta_data: null,
                validationStateEntityType: null,
                validationStateAssosName: null
              }
            ];
            this.orignalAssociatesUsers = [
              {
                firstName: null,
                assoName: null,
                entityType: thirdPartyType.INDIVIDUAL,
                id: null,
                position: null,
                meta_data: null,
                validationStateEntityType: null,
                validationStateAssosName: null
              }
            ];
          }
        });
        if (data.srch_tpi_assos.length == 0) {
          this.associatesUsers = [
            {
              firstName: null,
              assoName: null,
              entityType: thirdPartyType.INDIVIDUAL,
              id: null,
              position: null,
              meta_data: null,
              validationStateEntityType: null,
              validationStateAssosName: null
            }
          ];
          this.orignalAssociatesUsers = [
            {
              firstName: null,
              assoName: null,
              entityType: thirdPartyType.INDIVIDUAL,
              id: null,
              position: null,
              meta_data: null,
              validationStateEntityType: null,
              validationStateAssosName: null
            }
          ];
        }
        this.overwriteCustomAttributesValues(data.tp_custom_att_searches);
        this.getResultPreviewData(data);
      }
      this.showingReviewResult = true;
      this.isVerifiedEntity = data?.verified;
      this.isEnrichedEntity = data?.enriched;
      this.isEditModeEnabled = true;
      this.$store.state.isTpEditModal = true;
    },
    setSelectedSystemOwner(systemOwnerId) {
      const systemOwner = this.getSystemOwnerListOptions.find(
        ({ value }) => Number.parseInt(value) === Number.parseInt(systemOwnerId)
      ) || { text: null, value: null };
      this.selectedSystemOwner = systemOwner;
    },

    overwriteCustomAttributesValues(customAttributesList = []) {
      const customAttributesValues =
        customAttributesList?.reduce((acc, customAttribute) => {
          if (customAttribute.tp_type === attributeType.MULTISELECT_COUNTRIES) {
            customAttribute.tp_customatt_values = `${
              JSON.parse(customAttribute.tp_customatt_values).id
            }`;
          }
          if (acc[customAttribute.tp_customatt_id]) {
            acc[customAttribute.tp_customatt_id].value = acc[
              customAttribute.tp_customatt_id
            ].value.concat(", ", customAttribute.tp_customatt_values);
          } else {
            acc[customAttribute.tp_customatt_id] =
              this.makeCustomAttributesValueObj(customAttribute);
          }
          return acc;
        }, {}) || {};
      this.customAttributesValues = {
        ...this.customAttributesValues,
        ...customAttributesValues
      };
    },
    removePerformedActions() {
      this.completedActions.map((takkenActions) => {
        if (takkenActions.actionType == "idd") {
          let iddTypeIndex = this.extraActionsBtn.findIndex(
            (x) => x.actionType == "idd"
          );
          if (iddTypeIndex != -1) {
            this.extraActionsBtn.splice(iddTypeIndex, 1);
          }
        }
        if (takkenActions.actionType == "idd_plus") {
          let iddPlusTypeIndex = this.extraActionsBtn.findIndex(
            (x) => x.actionType == "idd_plus"
          );
          if (iddPlusTypeIndex != -1) {
            this.extraActionsBtn.splice(iddPlusTypeIndex, 1);
          }
        }
      });
    },
    onSelectReScreeningActionOccur(index, action) {
      if (action.actionType != "edd") {
        this.reScreeningActions.find((btn) => {
          if (btn.id == action.id) {
            btn.isActive = !btn.isActive;
          }
        });
      }
    },
    onSelectReScreeningActionOccurEdd(index, action) {
      this.reScreeningActions.find((btn) => {
        if (btn.id == action.id) {
          btn.isActive = !btn.isActive;
        }
      });
    },
    onSelectExtraActionOccur(action) {
      if (action.actionType != "edd") {
        this.extraActionsBtn.find((btn) => {
          if (btn.id == action.id) {
            btn.isActive = !btn.isActive;
          }
        });
        let activeActions = this.extraActionsBtn.filter((btn) => btn.isActive);
        if (activeActions.length && this.isEditModeEnabled) {
          this.disableUpdateBtn = false;
        } else {
          this.disableUpdateBtn = true;
        }
      }
    },
    onSelectExtraActionOccurEdd(action) {
      this.extraActionsBtn.find((btn) => {
        if (btn.id == action.id) {
          btn.isActive = !btn.isActive;
        }
        if (btn.isActive) {
          this.showDropDownModal = true;
        }
      });
      let activeActions = this.extraActionsBtn.filter((btn) => btn.isActive);
      if (activeActions.length && this.isEditModeEnabled) {
        this.disableUpdateBtn = false;
      } else {
        this.disableUpdateBtn = true;
      }
    },
    onSelectExtraActionOccurTCert(action) {
      action.isActive = !action.isActive;
      this.showDropDownModalForTCert = action.isActive;
    },
    onSelectExtraActionOccurEsgm(action) {
      action.isActive = !action.isActive;
      this.showDropDownModalForEsgm = action.isActive;
    },
    resetFormPostVerification() {
      this.resetForm();
      this.initializeData(this.$route?.params?.id);
      this.toggleDiscardChangesModalVisibility();
      this.$refs?.["entity-details-accordion"]?.$el?.scrollIntoView?.({
        behavior: "smooth"
      });
    },
    resetEntityDetails() {
      this.form = {
        alias: null,
        country: null,
        entityName: null,
        entityThirdPartyUrl: null,
        firstName: null,
        registrationNumber: null,
        searchAssociatedCompaniesValue: null,
        dunsNumber: null
      };
      this.existingItems = [];
      this.currentPageExistingItems = 1;
      this.perPageExistingItems = 5;
      this.fromRowsExistingItems = 0;
      this.toRowsExistingItems = 0;
      this.totalRowsExistingItems = 0;
      this.selectedThirdParty = null;
      this.v$.$reset();
    },
    resetForm() {
      this.clearOpenCorporateSectionData();
      this.isChangeInAssociates = false;
      this.isExistingContactEmail = false;
      this.existingContactEmailMessage = "";
      this.invalidUrlDomain = false;
      this.invalidUrlDomainValue = null;
      this.isFetchResultsDisabled = false;
      this.userTyping = false;
      this.isEntityVerificationPopupVisible = false;
      this.isEntityVerificationPerformed = false;
      this.isPremiumCorporateDataEnabled = false;
      this.associatesUsers = [
        {
          firstName: null,
          assoName: null,
          entityType: thirdPartyType.INDIVIDUAL,
          id: null,
          position: null,
          meta_data: null,
          validationStateEntityType: null,
          validationStateAssosName: null
        }
      ];
      this.orignalAssociatesUsers = [
        {
          firstName: null,
          assoName: null,
          entityType: thirdPartyType.INDIVIDUAL,
          id: null,
          position: null,
          meta_data: null,
          validationStateEntityType: null,
          validationStateAssosName: null
        }
      ];
      this.actionTakenData = [];
      this.form = {
        alias: null,
        country: null,
        entityName: null,
        entityThirdPartyUrl: null,
        firstName: null,
        registrationNumber: null,
        searchAssociatedCompaniesValue: null,
        dunsNumber: null
      };
      this.resetRiskRating();
      this.additionalDetailForm = {
        address: null,
        city: null,
        state: null,
        postal_code: null,
        contactName: null,
        contactEmail: null,
        contactPhone: null,
        contactLanguage: this.additionalDetailForm.contactLanguage,
        contactLanguageCode: this.additionalDetailForm.contactLanguageCode
      };
      this.actionsOccurBtns = [];
      this.extraActionsBtn = [];
      this.completedActions = [];
      this.showingReviewResult = false;
      this.entityTypeSelect = thirdPartyType.ORGANIZATION;
      this.isLoadingResultReview = false;
      this.hideSubmitButton = false;
      this.showCreditsAlert = false;
      this.isEditModeEnabled = false;
      this.$store.state.isTpEditModal = false;
      this.selecteditem = null;
      this.selectedItemArray = [];
      this.allElements = [];
      this.showDropDownModal = false;
      this.collapse = {
        EntityVerification: true,
        AssociateEntities: false,
        AdditionalDetails: false,
        CustomAttributes: true,
        isPremiumCorporateData: false,
        isEntityDetails: true,
        previousSavedEntities: true
      };
      this.existingItems = [];
      this.currentPageExistingItems = 1;
      this.perPageExistingItems = 5;
      this.fromRowsExistingItems = 0;
      this.toRowsExistingItems = 0;
      this.totalRowsExistingItems = 0;
      this.selectedThirdParty = null;
      this.disableUpdateBtn = true;
      this.setCustomAttributesValues();
      this.customAttributesValuesData = {};
      this.entityVerificationData = [];
      this.selectedTCertItem = this.makeOptionObj();
      this.selectedEsgmItem = this.makeOptionObj();
      this.makeTCertificationOptions();
      this.makeESGMetricsOptions();
      this.premiumCorporateData = {};
      this.isVerifiedEntity = false;
      this.isEnrichedEntity = false;
      this.showConfirmVerifiedNextStepsSection = false;
      this.confirmVerifiedNextStepsQuestionError = "";
      this.confirmVerifiedNextStepsQuestionOptions =
        this.confirmVerifiedNextStepsQuestionOptions.map((option) => {
          option.checked = false;
          return option;
        });
      this.v$.$reset();
    },
    exitDetailPage() {
      this.resetForm();
      this.$router.go(-1);
    },
    addAssociateRow() {
      this.disableUpdateBtn = false;

      this.associatesUsers.push({
        firstName: null,
        assoName: null,
        entityType: thirdPartyType.INDIVIDUAL,
        id: null,
        position: null,
        meta_data: null,
        validationStateEntityType: null,
        validationStateAssosName: null
      });
      this.checkAssociateChange();
    },
    removeFirstRowAssociateRow(index, users) {
      this.disableUpdateBtn = false;

      if (this.associatesUsers.length > 1) {
        this.removeAssociateRow(index, users);
      } else {
        let tableAltaresSuggestedAssociatesIndex = 0;
        tableAltaresSuggestedAssociatesIndex =
          this.openCorporatesCompanyOfficersItems.findIndex(
            (x) => x.fullName === users.assoName
          );
        this.selectedCompanyOfficers[
          tableAltaresSuggestedAssociatesIndex
        ] = false;
        this.associatesUsers = [
          {
            assoName: null,
            entityType: thirdPartyType.INDIVIDUAL,
            id: uid(),
            position: null,
            meta_data: null,
            validationStateEntityType: null,
            validationStateAssosName: null
          }
        ];
        this.checkAssociateChange();
      }
    },
    removeAssociateRow(index, users) {
      this.disableUpdateBtn = false;

      let tableAltaresSuggestedAssociatesIndex = 0;
      tableAltaresSuggestedAssociatesIndex =
        this.openCorporatesCompanyOfficersItems.findIndex(
          (x) => x.fullName === users.assoName
        );

      this.selectedCompanyOfficers[
        tableAltaresSuggestedAssociatesIndex
      ] = false;
      this.associatesUsers.splice(index, 1);
      this.checkAssociateChange();
    },
    resetPaginationIndividual() {
      this.selectedIndividualRowCountry = null;
    },
    async getTatCost() {
      this.isLoadingPackages = true;
      let formData = {
        country: this.selectedCountryName,
        client_id: this.companyId,
        edd_default_region: this.companyDetails.eddDefaultRegion
      };
      try {
        let result = await HTTP(
          "post",
          "thirdparty/edd/client/all/packages",
          formData,
          makeAuthorizationHeader()
        );
        if (result.status == 200 && result.data.status == 1) {
          result.data.data.map((data) => {
            if (data.packagesId == this.selectedId) {
              this.selectedItemArray = data;
            }
          });
          this.extraActionsBtn.find((btn) => {
            if (btn.actionType == "edd") {
              btn.isActive = true;
            }
          });
          this.isLoadingPackages = false;
        } else if (result.data.status == 0) {
          this.$bvToast.toast(result.data.message, {
            title: "Error",
            variant: "danger",
            autoHideDelay: 2000,
            appendToast: false
          });
          this.extraActionsBtn.find((btn) => {
            if (btn.actionType == "edd") {
              btn.isActive = false;
            }
          });
          this.isLoadingPackages = false;
        }
      } catch (error) {
        this.isLoadingPackages = false;
        return [error];
      }
    },
    checkValue() {
      if (this.selectedCountryName && this.selecteditem) {
        this.getTatCost();
      }
    },
    setSelectedItem(val) {
      this.selecteditem = val.name;
      this.selectedId = val.id;
      this.getElementsByType();
      this.checkValue();
    },
    async getElementsByType() {
      this.isLoadingElements = true;
      try {
        let result = await HTTP(
          "get",
          `thirdparty/edd/client/packages/selected/products/${this.companyId}/${this.selectedId}`,
          null,
          makeAuthorizationHeader()
        );
        if (result.status == 200) {
          this.allElements = result.data;
          this.isLoadingElements = false;
        }
      } catch (error) {
        this.isLoadingElements = false;
        return [error];
      }
    },
    showConfirmModal(id) {
      this.thirdPartyID = id;
      this.$refs["modal-switch-tp"].show();
    },
    hideSwitchModal() {
      this.selectedThirdParty = null;
      this.$refs["modal-switch-tp"].hide();
    },
    navigateToThirdPartyProfile(thirdPartyId = this.thirdPartyID) {
      this.$router.push(
        urls.THIRD_PARTY_PROFILE_COMPANY(thirdPartyId, this.companyId)
      );
    },
    checkAssociateChange() {
      let error = [];
      this.orignalAssociatesUsers.map((items) => {
        let index = this.associatesUsers.findIndex(
          (x) =>
            x.assoName == items.assoName && x.entityType == items.entityType
        );
        if (index != -1) {
          error.push(false);
        } else {
          error.push(true);
        }
      });
      let checker = error.every((v) => v == false);
      if (
        !checker ||
        this.associatesUsers.length != this.orignalAssociatesUsers.length
      ) {
        this.isChangeInAssociates = true;
        this.isChangeAssociateUpdate = true;
      } else {
        this.isChangeInAssociates = false;
        this.isChangeAssociateUpdate = false;
      }
    },
    makeOptionObj() {
      return {
        text: "",
        value: ""
      };
    },
    selectedActionTheme(isActive) {
      return isActive ? themes.SUCCESS_WHITE : themes.SUCCESS_INVERSE_WHITE;
    },
    getSelectedIcon(isActive) {
      return {
        icon: icons.PLUS,
        shape: shapes.SQUARE,
        theme: this.selectedActionTheme(isActive),
        border: this.isThirdPartyArchived ? border.DASHED : border.SOLID,
        size: iconSizes.DEFAULT
      };
    },
    onConfirmVerificationNextStepsQuestionChange({ index }) {
      this.confirmVerifiedNextStepsQuestionError = "";
      this.confirmVerifiedNextStepsQuestionOptions =
        this.confirmVerifiedNextStepsQuestionOptions.map(
          (option, optionIndex) => {
            option.checked = index === optionIndex;

            return option;
          }
        );
    }
  },
  beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      if (
        (to.name === routeNames[routeKeys.THIRD_PARTY_CREATE] ||
          to.name === routeNames[routeKeys.THIRD_PARTY_UPDATE]) &&
        (from.name === routeNames[routeKeys.THIRD_PARTY_CREATE] ||
          from.name === routeNames[routeKeys.THIRD_PARTY_UPDATE])
      ) {
        await vm.initializeData(vm.$route?.params?.id);
      }
    });
  },
  beforeRouteLeave(_, __, next) {
    this.v$.$reset();
    this.resetForm();
    this.resetThirdPartyDetails();
    this.setThirdPartyDetailsMessage("");
    next();
  }
};
</script>

<style lang="scss" scoped>
.input-select input,
input::-webkit-input-placeholder {
  font-size: 14px !important;
}

.cols-4-with-voucheralert-height-styles {
  overflow-y: auto;
  height: calc(100vh - 280px);
}

.d-flex {
  text-align: left;
}

.risk-rating-drop-down > li > .dropdown-item:focus {
  background-color: $white;
}

.risk-rating-drop-down > li > .dropdown-item:hover {
  background-color: $white;
}

.errorClass {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: $spanish-red;
}

.third-party-details {
  text-align: left;

  &__body--screen-height {
    overflow-y: auto;
    height: calc(100vh - 182px);
  }

  &__associate-entities {
    &-select {
      margin-top: 3px;
      width: fit-content;
    }
  }

  &__label-container {
    display: flex;
    align-items: flex-start;
    gap: $spacing4;
  }

  &__validation-message {
    display: flex;
    align-items: center;
    gap: $spacing4;
    margin-bottom: $spacing16;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid $grey;
    padding: 10px 0 0;

    &-section {
      display: flex;
      justify-content: flex-end;
      gap: $spacing16;
    }

    &-btn {
      display: flex;
      width: fit-content;
      gap: $spacing8;
      align-items: center;
    }
  }

  &__entity-details {
    &-header {
      display: flex;

      &-icon {
        display: flex;
        align-items: center;
      }

      &-title {
        margin-bottom: 0;
        margin-left: 7px;
      }

      &-questionmark {
        margin-left: auto;
      }
    }

    &-section {
      padding: 7px;
    }
  }

  &__entity-name {
    display: flex;
    flex-flow: wrap;
    gap: $spacing12;
    align-items: start;
    margin-bottom: $spacing8;
    &-label {
      margin: 0;
    }
  }

  &__previously-performed-actions {
    display: flex;
    align-items: center;

    &-completed {
      @include body-text-bold;
      font-size: 13px;
      padding: 4px 7px;
      line-height: 1;
      border-radius: 4px;
      background-color: $robin-egg-blue;
      color: $white;
    }
  }

  &__actions {
    display: flex;

    &-container {
      display: flex;
      flex-direction: column;
      gap: $spacing12;
      padding: 0 $spacing12 $spacing12;
    }

    &-list {
      gap: $spacing12;
      border-radius: 0;
    }

    &-item {
      padding: $spacing8;
      border: 0;

      &--clickable {
        cursor: pointer;
      }

      &--active {
        background-color: rgba($shiny-shamrock, 0.25);
      }

      &-container {
        display: flex;
        align-items: center;
        gap: $spacing12;
      }
    }

    &-card {
      border-radius: $spacing12;
      box-shadow: 0 2px 4px 0 #00000036;
      border: 1px solid $grey;

      &-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: $spacing12;
      }

      &-content {
        padding: $spacing12;
        border-top: 1px solid $non-photo-blue;
      }
    }
  }

  &__discard-changes-modal {
    text-align: center;

    &-text {
      padding: 0 $spacing16;
    }
  }

  &__tcertification-text {
    &--archivedClass {
      @include body-text-bold;
      color: $chinese-silver;
    }

    &--bold {
      @include body-text-bold;
    }
  }

  &__system-owner-label-wrapper {
    display: inline-flex;
  }

  &__system-owner-tooltip {
    margin-left: 8px;
  }

  &__contact-language {
    margin-bottom: 32px;
  }

  &__available-actions {
    display: inline-block;
    text-align: center;
    padding: 8px 0;
  }

  &__risk-rating {
    display: flex;
    align-items: center;
    gap: $spacing12;

    &-comment {
      margin-top: $spacing12;
    }
  }

  &__accordion-spacing {
    margin: $spacing12 0;
  }

  &__info-box-spacing {
    margin: $spacing12 0;
  }

  &__entity-verification {
    border: 1px solid $grey;
    border-radius: 12px;
    box-shadow: 0 2px 4px 0 #00000036;
    padding: $spacing20 $spacing12;

    &-heading {
      padding-bottom: $spacing20;
    }

    &-actions,
    &-footer {
      display: flex;
      justify-content: flex-end;
      gap: $spacing6;
      padding: $spacing20 0 0;
    }

    &-show-more {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: $spacing20;
      border-bottom: 1px solid $grey;
    }

    &-modal {
      text-align: center;

      &:deep(.modal) {
        max-width: 650px;
      }
    }
  }

  &__entity-update-confirmation-modal {
    text-align: center;

    &__footer-btn {
      border-radius: $spacing12;
    }
  }

  &__confirm-next-steps-option {
    display: flex;
    margin-bottom: 20px;
    gap: $spacing16;
  }

  &__previously-saved-entities {
    border-radius: $border-radius30;
    box-shadow: 0px 2px 4px 0px #00000036;
    border: 1px solid $grey;
    margin: $spacing24 0;

    &__header-title {
      padding: $spacing16 $spacing20;
    }

    &__content {
      padding: 0 $spacing20;
    }
  }
}

.form-control,
.custom-select {
  border-radius: 8px !important;

  &:disabled {
    background-color: $grey-light;
  }
}

:deep(.vs--disabled .vs__dropdown-toggle) {
  background-color: $grey-light;
}

:deep(.custom-dropdown-toggle-class .vs__dropdown-toggle) {
  border-radius: 8px !important;
  padding: 2px !important;
}

:deep(.vs__search) {
  margin: 4px 0;

  &:focus {
    margin: 4px 0;
  }
}

:deep(.vs__selected) {
  margin: 0 2px;
}

:deep(.vs__clear) {
  padding: 0 0 6px;
}
</style>
