import {
  customAttributesChartKeysAPIDTO,
  customAttributesChartKeysDTO,
  tasksChartKeysAPIDTO,
  trainingChartKeysAPIDTO
} from "@/constants";

const makeCustomAttributeChartDataDTO = (data = []) =>
  data?.map((item) => ({
    [customAttributesChartKeysDTO.VALUE]:
      item?.[customAttributesChartKeysAPIDTO.VALUE],
    [customAttributesChartKeysDTO.COUNT]:
      item?.[customAttributesChartKeysAPIDTO.COUNT],
    [customAttributesChartKeysDTO.LABEL]:
      item?.[customAttributesChartKeysAPIDTO.LABEL]
  })) || [];

const makeTasksStatusWidgetDataDTO = (data = {}) => ({
  [tasksChartKeysAPIDTO.STATUSES]:
    data?.[tasksChartKeysAPIDTO.STATUSES]?.map?.((status) => ({
      [tasksChartKeysAPIDTO.STATUS]:
        status?.[tasksChartKeysAPIDTO.STATUS]?.toLowerCase?.(),
      [tasksChartKeysAPIDTO.COUNT]: status?.[tasksChartKeysAPIDTO.COUNT]
    })) || [],
  [tasksChartKeysAPIDTO.TOTAL]: data?.[tasksChartKeysAPIDTO.TOTAL] || 0
});

const makeTasksAgeWidgetDataDTO = (data = {}) =>
  data?.[tasksChartKeysAPIDTO.DATA]?.map?.((item) => ({
    [tasksChartKeysAPIDTO.VALUE]: item?.[tasksChartKeysAPIDTO.DATE],
    [tasksChartKeysAPIDTO.COUNT]: item?.[tasksChartKeysAPIDTO.COUNT]
  })) || [];

const makeTasksOutcomesWidgetDataDTO = (data = {}) =>
  data?.[tasksChartKeysAPIDTO.TASKS]?.map?.((item) => ({
    [tasksChartKeysAPIDTO.LABEL]: item?.[tasksChartKeysAPIDTO.OUTCOME_NAME],
    [tasksChartKeysAPIDTO.VALUE]: item?.[tasksChartKeysAPIDTO.OUTCOME_ID],
    [tasksChartKeysAPIDTO.COUNT]: item?.[tasksChartKeysAPIDTO.COUNT]
  })) || [];

const makeTasksAssigneeWidgetDataDTO = (data = {}) =>
  data?.[tasksChartKeysAPIDTO.ASSIGNEES]?.map?.((item) => ({
    [tasksChartKeysAPIDTO.VALUE]: item?.[tasksChartKeysAPIDTO.ASSIGNEE_ID],
    [tasksChartKeysAPIDTO.COUNT]: item?.[tasksChartKeysAPIDTO.TASK_COUNT],
    [tasksChartKeysAPIDTO.LABEL]: item?.[tasksChartKeysAPIDTO.ASSIGNEE_NAME]
  })) || [];

const makeAllTrainingWidgetCountDTO = (data = {}) => ({
  [trainingChartKeysAPIDTO.TOTAL]:
    data?.[trainingChartKeysAPIDTO.TOTAL_COUNT] || 0
});

const makeTrainingStatusWidgetDataDTO = (data = {}) => ({
  [trainingChartKeysAPIDTO.STATUSES]:
    data?.[trainingChartKeysAPIDTO.STATUSES]?.map?.((status) => ({
      [trainingChartKeysAPIDTO.LABEL]:
        status?.[trainingChartKeysAPIDTO.STATUS]?.toLowerCase?.(),
      [trainingChartKeysAPIDTO.COUNT]: status?.[trainingChartKeysAPIDTO.COUNT]
    })) || [],
  [trainingChartKeysAPIDTO.TOTAL]: data?.[trainingChartKeysAPIDTO.TOTAL] || 0
});

export {
  makeCustomAttributeChartDataDTO,
  makeTasksStatusWidgetDataDTO,
  makeTasksAgeWidgetDataDTO,
  makeTasksOutcomesWidgetDataDTO,
  makeTasksAssigneeWidgetDataDTO,
  makeAllTrainingWidgetCountDTO,
  makeTrainingStatusWidgetDataDTO
};
