<template>
  <div>
    <BaseLoader
      v-if="isLoadingPlatformSettings"
      data-test-id="login__loader"
      class="login__loader"
    />
    <div v-else class="login" data-test-id="login">
      <div class="login__error-wrapper">
        <BaseInfoBox
          v-if="isErrorOnPage"
          class="login__error"
          data-test-id="alert-heading"
          :text="pageErrorMessages"
          :icon-options="defaultIcons.HIGH"
          :theme="themes.ERROR"
        />
      </div>

      <Logo />
      <form
        class="mt-4 login__form"
        data-test-id="login__form"
        @keyup.enter="onSubmit"
      >
        <Input
          id="username"
          name="username"
          placeholder="eg. username@ethixbase.com"
          class="login__form-input"
          data-test-id="login__form-input"
          :value="username"
          :error="validationMessage.username"
          @change="onChangeInput('username', $event)"
        />
        <Input
          type="password"
          id="password"
          name="password"
          placeholder="**********"
          class="login__form-input"
          data-test-id="login__form-input"
          :value="password"
          :error="validationMessage.password"
          @change="onChangeInput('password', $event)"
        />
      </form>
      <div
        class="mt-3 login__button-container"
        data-test-id="login__button-container"
      >
        <b-button
          class="login__button-container-button"
          data-test-id="login__button-container-button"
          @click="onSubmit"
        >
          <b-spinner
            small
            v-if="isAuthenticating"
            type="grow"
            label="Loading..."
          />
          Login
        </b-button>
        <b-button
          class="login__button-container-button"
          data-tets-id="login__button-container-button"
          @click="onOrganizationLogin"
        >
          Organization Login
        </b-button>
      </div>
      <div class="mt-4 login__links" data-test-id="login__links">
        <div class="login__links-forgot-password">
          <router-link :to="urls.FORGOT_PASSWORD" class="mx-2 float-left">
            Forgot Password
          </router-link>
        </div>
        <div class="mt-4">
          <p v-if="isAnyLinkAvailable">
            By logging in to the platform I agree to the
            <a
              v-if="platformTermsAndConditionsLink"
              :href="platformTermsAndConditionsLink"
              target="_blank"
            >
              Terms & Conditions
            </a>
            {{ isAnyLinkAvailable ? "and" : "" }}
            <a
              v-if="isPrivacyPolicyLinkAvailable"
              :href="platformPrivacyPolicyLink"
              target="_blank"
            >
              Privacy & Cookie Policy
            </a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import {
  actionName,
  languages,
  languagesInitial,
  localStorageItem,
  privacyPolicy,
  regex,
  urls,
  validationKeys,
  getterName,
  defaultIcons,
  errorMessages,
  customErrorMessages,
  themes
} from "@/constants";
import Input from "@/molecules/Input/Input.vue";
import Logo from "@/organisms/Logo/Logo";
import BaseLoader from "@/atoms/BaseLoader/BaseLoader.vue";
import BaseInfoBox from "@/atoms/BaseInfoBox/BaseInfoBox.vue";
import { isRequired } from "@/utils";

export default {
  name: "LoginPage",
  components: {
    BaseInfoBox,
    BaseLoader,
    Logo,
    Input
  },
  data() {
    return {
      username: null,
      password: null,
      isAuthenticating: false,
      validationMessage: this.makeValidationMessages(),
      pageErrorMessages: "",
      inputModelList: ["username", "password"],
      urls,
      themes,
      defaultIcons
    };
  },
  computed: {
    ...mapState({
      platformTermsAndConditionsLink: (state) =>
        state.platformSettings.platformTermsAndConditionsLink,
      platformPrivacyPolicyLink: (state) =>
        state.platformSettings.platformPrivacyPolicyLink,
      disablePrivacyPolicy: (state) =>
        state.platformSettings.disablePrivacyPolicy,
      platformSSOURL: (state) => state.platformSettings.platformSSOURL,
      isLoadingPlatformSettings: (state) =>
        state.platformSettings.isLoadingPlatformSettings
    }),
    ...mapGetters({
      permittedLandingPage: getterName.USER.PERMITTED_LANDING_PAGE,
      isUserThirdParty: getterName.USER.IS_USER_THIRD_PARTY,
      isUserNotUpdated: getterName.USER.IS_USER_NOT_UPDATED
    }),
    isErrorOnPage() {
      return !!this.pageErrorMessages;
    }
  },
  methods: {
    isRequired,
    ...mapActions({
      logIn: actionName.AUTH.LOG_IN
    }),
    isAnyLinkAvailable() {
      return !!(
        this.platformTermsAndConditionsLink ||
        this.isPrivacyPolicyLinkAvailable()
      );
    },
    isPrivacyPolicyLinkAvailable() {
      return !!(
        this.platformPrivacyPolicyLink &&
        parseInt(this.disablePrivacyPolicy) === privacyPolicy.IS_DISABLED
      );
    },
    onChangeInput(inputName, val) {
      this[inputName] = val;
      this.validationMessage[inputName] = "";
    },
    validateInputs() {
      this.clearValidationMessage();
      let isValid = true;

      if (!this.isRequired(this.username)) {
        this.validationMessage.username =
          errorMessages.username[validationKeys.REQUIRED];
        isValid = false;
      }

      if (!this.isRequired(this.password)) {
        this.validationMessage.password =
          errorMessages.password[validationKeys.REQUIRED];
        isValid = false;
      }

      return isValid;
    },
    makeValidationMessages() {
      return {
        username: "",
        password: ""
      };
    },
    onSubmit() {
      this.clearValidationMessage();
      if (this.validateInputs()) {
        this.authenticate();
      }
    },
    clearValidationMessage() {
      this.validationMessage = this.makeValidationMessages();
      this.pageErrorMessages = "";
    },
    authenticationFailed({ status } = {}) {
      if (status === 401) {
        this.pageErrorMessages = customErrorMessages.generic[validationKeys.INVALID_EMAIL_PASSWORD]
      } else if (status === 403) {
        this.handleNavigation(status);
      } else {
        this.pageErrorMessages = "Unknown error.";
      }
    },
    async authenticate() {
      try {
        this.pageErrorMessages = "";
        this.isAuthenticating = true;
        const { data = {}, status } = await this.logIn({
          username: this.username,
          password: this.password
        });
        this.authenticationSucceeded(data, status);
      } catch ({ response }) {
        this.authenticationFailed(response);
      } finally {
        this.isAuthenticating = false;
        this.username = null;
        this.password = null;
      }
    },
    authenticationSucceeded(data, status) {
      this.setValuesInLocalStorage(data);
      this.handleNavigation(status);
    },
    handleNavigation(authStatus) {
      if (authStatus === 200) {
        if (this.isUserThirdParty) {
          const path = this.isUserNotUpdated
            ? urls.CREATE_PROFILE
            : urls.DASHBOARD;
          this.$router.push(path);
        } else {
          this.$router.push(this.permittedLandingPage);
        }
      } else if (authStatus === 403) {
        this.$router.push(urls.PLATFORM_SWITCHER);
      }
    },
    setValuesInLocalStorage(data) {
      const { formTranslations, contact } = data;

      localStorage.setItem(
        localStorageItem.FORM_DEFAULT_LANGUAGE,
        this.contactLanguage(contact)
      );
      localStorage.setItem(
        localStorageItem.FORM_TRANSLATIONS,
        Array.isArray(formTranslations) && formTranslations?.length
          ? formTranslations
          : [languages[languagesInitial.ENGLISH]]
      );
    },
    contactLanguage({ language }) {
      if (languages[language]) {
        return languages[language];
      } else {
        return languages[languagesInitial.ENGLISH];
      }
    },
    onOrganizationLogin() {
      window.location = `${
        this.platformSSOURL
      }/landing?tenant=${this.getSubDomain(window.location.href)}`;
    },
    getSubDomain(url) {
      if (regex.CHECK_URL.test(url)) {
        return url.match(regex.CHECK_URL)[2].split(".")[0];
      } else {
        return null;
      }
    }
  }
};
</script>

<style scoped lang="scss">
.login {
  margin-top: 50px;

  &__form {
    display: inline-block;
    width: 250px;
    text-align: left;
  }

  &__button-container {
    display: flex;
    justify-content: space-between;
    width: 250px;
    margin: 0 auto;

    &-button {
      background-color: var(--primary-color);
      color: var(--navigation-bar-text-color);

      &:focus,
      &:hover {
        background-color: $spanish-gray;
      }
    }
  }

  &__links {
    display: flex;
    flex-direction: column;

    &-forgot-password {
      display: contents;
    }
  }

  &:deep(.logo__image) {
    max-width: 260px;
    max-height: 260px;
  }

  &__loader {
    margin-top: 150px;
  }

  &__error {
    max-width: 700px;
    margin: 0 auto;

    &-wrapper {
      min-height: 100px;
    }
  }
}
</style>
