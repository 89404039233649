const triggerStepKeys = {
  ACTIONS: "actions",
  EXPECTED_DATA_MAPPING: "expectedDataMapping",
  SUBMISSIONS_PERMISSIONS: "submissionsPermissions"
};

const greenliteColumnIdKeys = {
  NAME: "name",
  OVERALL_RISK_SEVERITY: "overallRiskSeverity",
  ENVIRONMENTAL_RISK_SEVERITY: "environmentRiskSeverity",
  GOVERNANCE_RISK_SEVERITY: "governanceRiskSeverity",
  ENVIRONMENTAL_RATING: "environmentRating",
  ENVIRONMENT_RESILIENCE_STRENGTH: "environmentResilienceStrength",
  ENVIRONMENTAL_PRACTICES_STRENGTH: "environmentalPracticesStrength",
  SOCIAL_RISK_SEVERITY: "socialRiskSeverity",
  SOCIAL_RATING: "socialRating",
  SOCIAL_RESILIENCE_STRENGTH: "socialResilienceStrength",
  GOVERNANCE_RATING: "governanceRating",
  GOVERNANCE_RESILIENCE_STRENGTH: "governanceResilienceStrength",
  GREENLITE_EXPLORE_RATING: "greenliteExploreRating",
  RESILIENCE_STRENGTH: "resilienceStrength",
  CARBON_NEUTRAL_STRENGTH: "carbonNeutralStrength",
  HUMAN_RIGHTS_AND_MODERN_SLAVERY_STRENGTH:
    "humanRightsAndModernSlaveryStrength",
  LABOUR_STRENGTH: "labourStrength",
  DIVERSITY_AND_INCLUSION_STRENGTH: "diversityAndInclusionStrength",
  HEALTH_AND_SAFETY_STRENGTH: "healthAndSafetyStrength",
  ANTI_BRIBERY_CORRUPTION_STRENGTH: "antiBriberyAndCorruptionStrength",
  WHISTLEBLOWING_STRENGTH: "whistleblowingStrength",
  SUPPLY_CHAIN_STRENGTH: "supplyChainStrength"
};

const greenliteThirdPartyKeys = {
  ...greenliteColumnIdKeys,
  ID: "id",
  DATA: "data",
  PAGE_NUMBER: "pageNumber",
  PER_PAGE: "perPage",
  TOTAL: "total",
  SORT: "sort",
  SEARCH: "search"
};

const greenliteKeySections = {
  ratingKeys: [
    greenliteThirdPartyKeys.GREENLITE_EXPLORE_RATING,
    greenliteThirdPartyKeys.ENVIRONMENTAL_RATING,
    greenliteThirdPartyKeys.SOCIAL_RATING,
    greenliteThirdPartyKeys.GOVERNANCE_RATING
  ],
  severityKeys: [
    greenliteThirdPartyKeys.ENVIRONMENTAL_RISK_SEVERITY,
    greenliteThirdPartyKeys.SOCIAL_RISK_SEVERITY,
    greenliteThirdPartyKeys.GOVERNANCE_RISK_SEVERITY,
    greenliteThirdPartyKeys.OVERALL_RISK_SEVERITY
  ],
  strengthKeys: [
    greenliteThirdPartyKeys.ENVIRONMENT_RESILIENCE_STRENGTH,
    greenliteThirdPartyKeys.CARBON_NEUTRAL_STRENGTH,
    greenliteThirdPartyKeys.ENVIRONMENTAL_PRACTICES_STRENGTH,
    greenliteThirdPartyKeys.SOCIAL_RESILIENCE_STRENGTH,
    greenliteThirdPartyKeys.HUMAN_RIGHTS_AND_MODERN_SLAVERY_STRENGTH,
    greenliteThirdPartyKeys.LABOUR_STRENGTH,
    greenliteThirdPartyKeys.DIVERSITY_AND_INCLUSION_STRENGTH,
    greenliteThirdPartyKeys.HEALTH_AND_SAFETY_STRENGTH,
    greenliteThirdPartyKeys.GOVERNANCE_RESILIENCE_STRENGTH,
    greenliteThirdPartyKeys.ANTI_BRIBERY_CORRUPTION_STRENGTH,
    greenliteThirdPartyKeys.WHISTLEBLOWING_STRENGTH,
    greenliteThirdPartyKeys.SUPPLY_CHAIN_STRENGTH,
    greenliteThirdPartyKeys.RESILIENCE_STRENGTH
  ]
};

const registrationNumbersKeys = {
  TYPE: "type",
  NUMBER: "number"
};

const numberOfEmployeesKeys = {
  DESCRIPTION: "description",
  VALUE: "value"
};

const seniorPrincipalsKeys = {
  TITLE: "jobTitles",
  NAME_PREFIX: "namePrefix",
  FULL_NAME: "fullName",
  NAME_SUFFIX: "nameSuffix",
  GENDER: "gender",
  SELECTED: "selected"
};

const companyOrganizationsKeys = {
  SELECT: "select",
  COMPANY_NAME: "companyName",
  ADDRESS: "registeredCountry",
  OPERATING_STATUS: "operatingStatus",
  CORPORATE_LINKAGE: "corporateLinkage",
  MATCH_CONFIDENCE: "matchConfidence",
  MORE_INFO: "moreInfo",
  KEY_PERSON: "keyPerson",
  COMPANY_REGISTRATION_NUMBER: "companyRegistrationNumber"
};

const automationsKeys = {
  ID: "id",
  NAME: "name",
  DESCRIPTION: "description",
  ACTIONS: "actions",
  IS_ACTIVE: "isActive",
  CONDITION: "condition",
  CREATED_AT: "createdAt",
  UPDATED_AT: "updatedAt",
  CREATED_BY: "createdBy",
  PARAMETERS: "parameters",
  PARAMETER: "parameter",
  TYPE: "type",
  EXPECTED_DATA_MAPPING: "expectedDataMapping",
  ACTION_VARIABLE_NAME: "actionVariableName",
  CONDITION_PARAMETER_NAME: "conditionParameterName",
  REQUIRES_MATCH: "requiresMatch",
  IS_MAPPABLE: "isMappable",
  CRITERIA: "criteria",
  COMPARATOR: "comparator",
  VALUE: "value",
  CUSTOM_ATTRIBUTE_ID: "customAttributeId",
  COMPANY_ID: "companyId",
  TRIGGERED_EXPECTED_DATA_VARIABLE_NAME: "triggeredExpectedDataVariableName",
  TRIGGERING_DATA: "triggeringData",
  DATA: "data",
  DDQ_ID: "ddqId",
  EVENT: "event",
  CUSTOM_ATTRIBUTE: "custom-attribute",
  PERMISSIONS: "permissions",
  PERMISSION: "permission",
  ROLES: "roles",
  MESSAGE: "message",
  COURSE_ID: "courseId"
};

const automationConditionKeys = {
  MANUAL_TRIGGER: "eraf-automation-manual-trigger",
  ACT_ON_ALL: "eraf-automation-act-on-all",
  ACT_ON_SELECTED: "eraf-automation-act-on-selected"
};

const automationsAPIKeys = {
  ID: "id",
  NAME: "name",
  DESCRIPTION: "description",
  ACTIONS: "actions",
  IS_ACTIVE: "is_active",
  CONDITION: "condition",
  CREATED_AT: "created_at",
  UPDATED_AT: "updated_at",
  CREATED_BY: "created_by",
  PARAMETERS: "parameters",
  TYPE: "type",
  EXPECTED_DATA_MAPPING: "expected_data_mapping",
  ACTION_VARIABLE_NAME: "action_variable_name",
  CONDITION_PARAMETER_NAME: "condition_parameter_name",
  REQUIRES_MATCH: "requires_match",
  IS_MAPPABLE: "is_mappable",
  CRITERIA: "criteria",
  PARAMETER: "parameter",
  COMPARATOR: "comparator",
  VALUE: "value",
  CUSTOM_ATTRIBUTE_ID: "custom_attribute_id",
  COMPANY_ID: "company_id",
  TRIGGERED_EXPECTED_DATA_VARIABLE_NAME:
    "triggered_expected_data_variable_name",
  TRIGGERING_DATA: "triggering_data",
  DATA: "data",
  DDQ_ID: "ddq_id",
  EVENT: "event",
  PERMISSIONS: "permissions",
  PERMISSION: "permission",
  ROLES: "roles",
  MESSAGE: "message",
  COURSE_ID: "course_id"
};

const riskAlertKeysDTO = {
  PILLAR: "pillar",
  SEVERITY: "severity",
  CATEGORY: "category",
  DATE: "date",
  RECEIVED_DATE: "receivedDate",
  DESCRIPTION: "description"
};

const riskAlertKeysAPIDTO = {
  PILLAR: "pillar",
  SEVERITY: "severity",
  CATEGORY: "category",
  DATE: "date",
  RECEIVED_DATE: "received_date",
  DESCRIPTION: "description"
};

const multiSelectSearchKeys = {
  ID: "id",
  TEXT: "text",
  VALUE: "value",
  SELECTED: "selected",
  HIDDEN: "hidden",
  AMENDABLE: "amendable",
  IS_REMOVED_AT_DESELECTION: "isRemovedAtDeselection"
};

const ESGReportsKeys = {
  VERSION: "version",
  TRIGGER_DATE: "triggerDate",
  REPORT_ID: "reportId",
  ENVIRONMENT: "environment",
  ESG: "esg",
  GOVERNANCE: "governance",
  RESILIENCE: "resilience",
  RISK: "risk",
  SOCIAL: "social",
  COMPONENTS: "components",
  COMPONENT: "component",
  RATING: "rating",
  SCORE: "score",
  STRENGTH: "strength",
  SEVERITY: "severity",
  PILLAR: "pillar",
  NAME: "name",
  RESILIENCE_INDICATORS: "resilienceIndicators",
  RESILIENCE_STRENGTH: "resilienceStrength",
  RISK_SEVERITY: "riskSeverity",
  ENVIRONMENT_RESILIENCE: "environmentResilience",
  ENVIRONMENT_RISK: "environmentRisk",
  GOVERNANCE_RESILIENCE: "governanceResilience",
  GOVERNANCE_RISK: "governanceRisk",
  SOCIAL_RESILIENCE: "socialResilience",
  SOCIAL_RISK: "socialRisk",
  URL: "url"
};

const ESGReportsAPIKeys = {
  TRIGGER_DATE: "trigger_date",
  REPORT_ID: "report_id",
  ENVIRONMENT: "environment",
  ESG: "esg",
  GOVERNANCE: "governance",
  RESILIENCE: "resilience",
  RISK: "risk",
  SOCIAL: "social",
  COMPONENTS: "components",
  COMPONENT: "component",
  RATING: "rating",
  SCORE: "score",
  STRENGTH: "strength",
  SEVERITY: "severity",
  PILLAR: "pillar",
  NAME: "name",
  RESILIENCE_INDICATORS: "resilience_indicators",
  RESILIENCE_STRENGTH: "resilience_strength",
  RISK_SEVERITY: "risk_severity",
  ENVIRONMENT_RESILIENCE: "environment_resilience",
  ENVIRONMENT_RISK: "environment_risk",
  GOVERNANCE_RESILIENCE: "governance_resilience",
  GOVERNANCE_RISK: "governance_risk",
  SOCIAL_RESILIENCE: "social_resilience",
  SOCIAL_RISK: "social_risk",
  URL: "url"
};

const esgExportReportKeys = {
  COMPANY_ID: "companyId",
  COMPANY_NAME: "companyName",
  EMAIL: "email",
  USER_ID: "userId"
};

const esgExportReportAPIKeys = {
  COMPANY_ID: "company_id",
  COMPANY_NAME: "company_name",
  EMAIL: "email",
  USER_ID: "user_id"
};

const userRolesKeysDTO = {
  ID: "id",
  NAME: "name",
  DESCRIPTION: "description",
  IS_SYSTEM: "isSystem",
  ACTIONS: "actions"
};

const userRolesKeysAPIDTO = {
  ID: "id",
  NAME: "name",
  DESCRIPTION: "description",
  IS_SYSTEM: "is_system",
  TRIGGER: "trigger",
  PERMISSIONS: "permissions",
  PERMISSION: "permission",
  ROLES: "roles",
  EDIT_VALUE: "edit-custom-attribute-value"
};

const userPermissionKeysDTO = {
  ID: "id",
  NAME: "name",
  ACTION: "action",
  SUBJECT: "subject",
  DESCRIPTION: "description",
  CONTEXTS: "contexts",
  COMPANY_ID: "companyId",
  CUSTOM_ATTRIBUTE_ID: "customAttributeId",
  USER_VIEW_ID: "userViewId",
  TASK_ID: "taskId",
  ACTION_ID: "actionId",
  FILTER: "filter",
  PERMISSIONS: "permissions"
};

const userPermissionKeysAPIDTO = {
  ID: "id",
  NAME: "name",
  ACTION: "action",
  SUBJECT: "subject",
  DESCRIPTION: "description",
  CONTEXTS: "contexts",
  COMPANY_ID: "company_id",
  CUSTOM_ATTRIBUTE_ID: "custom_attribute_id",
  USER_VIEW_ID: "user_view_id",
  TASK_ID: "task_id",
  ACTION_ID: "action_id",
  FILTER: "filter",
  PERMISSIONS: "permissions"
};

const authUserKeysDTO = {
  AUTH_TOEKN: "authToken",
  MEMBER_ID: "memberId",
  MEMBER_GROUP_ID: "memberGroupId",
  USER_NAME: "userName",
  USER_FULL_NAME: "userFullName",
  EMAIL: "email",
  USER_REGISTRATION_STATUS: "userRegistrationStatus",
  USER_TOUR_STATUS: "userTourStatus",
  LANDING_PAGE: "landingPage",
  USER_FIRST_NAME: "userFirstName",
  USER_LAST_NAME: "userLastName",
  COMPANY_ID: "companyId",
  COMPANY_NAME: "companyName",
  LOGIN_TYPE: "loginType",
  PLATFORM_VERSION: "platformVersion",
  PLATFORM_VIEWS: "platformViews",
  FORM_TRANSLATIONS: "formTranslations",
  CONTACT: "contact",
  ROLES: "roles",
  PERMISSIONS: "permissions",
  PASSWORD_CREATED: "passwordCreated",
  TENANT_ID: "tenantId",
  THIRD_PARTY_ID: "thirdPartyId"
};

const authUserKeysAPIDTO = {
  AUTH_TOEKN: "authToken",
  MEMBER_ID: "memberId",
  MEMBER_GROUP_ID: "memberGroupId",
  USER_NAME: "userName",
  USER_FULL_NAME: "userFullname",
  EMAIL: "email",
  USER_REGISTRATION_STATUS: "userRegistrationStatus",
  USER_TOUR_STATUS: "userTourStatus",
  LANDING_PAGE: "landingPage",
  USER_FIRST_NAME: "userFirstname",
  USER_LAST_NAME: "userLastname",
  COMPANY_ID: "companyId",
  COMPANY_NAME: "companyName",
  LOGIN_TYPE: "loginType",
  PLATFORM_VERSION: "platform_version",
  PLATFORM_VIEWS: "platformViews",
  FORM_TRANSLATIONS: "formTranslations",
  CONTACT: "contact",
  ROLES: "roles",
  PERMISSIONS: "permissions",
  PASSWORD_CREATED: "password_created",
  TENANT_ID: "tenantId",
  THIRD_PARTY_ID: "thirdPartyId"
};

const companyKeysDTO = {
  IBF_MEMBER_LOG: "ibfMemberLog",
  COMPANY_ID: "companyId",
  ID: "id",
  MEMBER_ID: "memberId",
  UPDATED_ON: "updatedOn",
  FAKE_HTTP_STATUS: "fakeHttpStatus",
  ROLES: "roles",
  PERMISSIONS: "permissions",
  COMPANY_NAME: "companyName",
  COMPANY_LIST: "companyList",
  TENANT_ID: "tenantId"
};

const companyKeysAPIDTO = {
  IBF_MEMBER_LOG: "IbfMemberLog",
  COMPANY_ID: "company_id",
  ID: "id",
  MEMBER_ID: "member_id",
  UPDATED_ON: "updated_on",
  FAKE_HTTP_STATUS: "success",
  ROLES: "roles",
  PERMISSIONS: "permissions",
  COMPANY_NAME: "company_name",
  COMPANY_LIST: "companyList",
  TENANT_ID: "tenantId"
};

const companyUserListKeysDTO = {
  COMPANY_USERS: "companyUsers",
  DATA: "data",
  LAST_PAGE: "lastPage",
  FROM: "from",
  TO: "to",
  CC: "cc",
  BCC: "bcc",
  TOTAL: "total",
  EMAIL: "email",
  FIRST_NAME: "firstName",
  JOB_TITLE: "jobTitle",
  LAST_NAME: "lastName",
  MEMBER_GROUP_ID: "memberGroupId",
  MEMBER_ID: "memberId",
  NAME: "name",
  ROLES: "roles",
  COUNTRY_ID: "countryId"
};

const companyUserListKeysAPIDTO = {
  DATA: "data",
  LAST_PAGE: "last_page",
  FROM: "from",
  TO: "to",
  CC: "cc",
  BCC: "bcc",
  TOTAL: "total",
  EMAIL: "email",
  FIRST_NAME: "firstname",
  JOB_TITLE: "jobTitle",
  LAST_NAME: "lastname",
  MEMBER_GROUP_ID: "member_group_id",
  MEMBER_ID: "member_id",
  NAME: "name",
  ROLES: "roles",
  COUNTRY_ID: "country_id"
};

const deleteCompanyUserKeysDTO = {
  MESSAGE: "message",
  FAKE_HTTP_STATUS: "fakeHttpStatus"
};

const deleteCompanyUserKeysAPIDTO = {
  MESSAGE: "message",
  FAKE_HTTP_STATUS: "status"
};

const additionalVouchersKeysDTO = {
  FAKE_HTTP_STATUS: "fakeHttpStatus",
  REMAINING_VOUCHERS: "remainingVouchers",
  COMPANY_DETAILS: "companyDetails",
  COMPANY_VIEWS: "companyViews",
  ID: "id",
  TITLE: "title",
  COMPANY_ID: "companyId",
  VIEW_TYPE: "viewType",
  ADDIONAL_DATA: "additionalData",
  COMPANY_NAME: "companyName",
  AUTO_FILL_MSQ_V2: "autoFillMsqV2",
  COMPANY_APPROVER: "companyApprover",
  ONBOARDING_FORMS: "onboardingForms",
  BILL_NAME: "billName",
  BILL_EMAIL: "billEmail",
  BILL_COUNTRY: "billCountry",
  BILL_ADD: "billingAdd",
  EDD_CONTRACT: "eddContract",
  EDD_DEFAULT_REGION: "eddDefaultRegion"
};

const additionalVouchersKeysAPIDTO = {
  FAKE_HTTP_STATUS: "success",
  REMAINING_VOUCHERS: "remaining_vouchers",
  COMPANY_DETAILS: "vendor_edu_data",
  COMPANY_VIEWS: "platform_views",
  ID: "id",
  TITLE: "title",
  COMPANY_ID: "company_id",
  VIEW_TYPE: "view_type",
  ADDIONAL_DATA: "additional_data",
  COMPANY_NAME: "company_name",
  AUTO_FILL_MSQ_V2: "is_auto_full_msq_v2",
  COMPANY_APPROVER: "company_approver",
  ONBOARDING_FORMS: "onboarding_forms",
  BILL_NAME: "bill_name",
  BILL_EMAIL: "bill_email",
  BILL_COUNTRY: "bill_country",
  BILL_ADD: "billing_add",
  EDD_CONTRACT: "edd_contract",
  EDD_DEFAULT_REGION: "edd_default_region"
};

const platformSettingsKeysDTO = {
  DISABLE_PRIVACY_POLICY: "disablePrivacyPolicy",
  PLATFORM_SSO_URL: "platformSSOURL",
  PLATFORM_LOGO: "platformLogo",
  PLATFORM_PRIMARY_COLOR: "platformPrimaryColor",
  PLATFORM_SECONDARY_COLOR: "platformSecondaryColor",
  PLATFORM_TERMS_AND_CONDITIONS_LINK: "platformTermsAndConditionsLink",
  PLATFORM_PRIVACY_POLICY_LINK: "platformPrivacyPolicyLink",
  PLATFORM_BUSINESS_ADDRESS: "platformBusinessAddress",
  PLATFORM_COPYRIGHT_NOTICE: "platformCopyrightNotice",
  PLATFORM_FAQ_LINK: "platformFaqLink",
  PLATFORM_FAV_ICON: "platformFavIcon",
  PLATFORM_KNOWLEDGE_CENTRE_LINK: "platformKnowledgeCentreLink",
  PLATFORM_LEGAL_DISCLAIMER: "platformLegalDisclaimer",
  PLATFORM_MAIN_HEADER_LOGO: "platformMainHeaderLogo",
  PLATFORM_NAME: "platformName",
  PLATFORM_REGISTERED_BUSINESS_NAME: "platformRegisteredBusinessName",
  PLATFORM_RELEASE_NOTE_LINK: "platformReleaseNoteLink",
  PLATFORM_REQUEST_SUPPORT_LINK: "platformRequestSupportLink",
  PLATFORM_SUPPORT_EMAIL: "platformSupportEmail",
  PLATFORM_WEB_URL: "platformWebUrl",
  SINGLE_TP_MODAL_WORDING: "singleTpModalWording",
  POSTHOG_API_KEY: "posthogAPIKey"
};

const platformSettingsKeysAPIDTO = {
  DISABLE_PRIVACY_POLICY: "disable_privacy_policy",
  PLATFORM_FAV_ICON: "platform_favicon_icon",
  PLATFORM_LOGO: "platform_logo",
  PLATFORM_PRIMARY_COLOR: "platform_primary_color",
  PLATFORM_PRIVACY_POLICY_LINK: "platform_privacy_cookie_policy",
  PLATFORM_REGISTERED_BUSINESS_NAME: "platform_registered_business_name",
  PLATFORM_SECONDARY_COLOR: "platform_secondary_color",
  PLATFORM_SSO_URL: "platform_sso_url",
  PLATFORM_TERMS_AND_CONDITIONS_LINK: "platform_term_and_condition_link",
  PLATFORM_BUSINESS_ADDRESS: "platform_business_address",
  PLATFORM_COPYRIGHT_NOTICE: "platform_copyright_notice",
  PLATFORM_LEGAL_DISCLAIMER: "platform_legal_disclaimer",
  PLATFORM_MAIN_HEADER_LOGO: "platform_main_header_logo",
  PLATFORM_NAME: "platform_name",
  PLATFORM_RELEASE_NOTE_LINK: "platform_release_note_link",
  PLATFORM_KNOWLEDGE_CENTRE_LINK: "platform_knowledge_centre_link",
  PLATFORM_FAQ_LINK: "platform_faq_link",
  PLATFORM_SUPPORT_EMAIL: "platform_support_email",
  PLATFORM_WEB_URL: "platform_weburl",
  PLATFORM_REQUEST_SUPPORT_LINK: "platform_request_support_link",
  SINGLE_TP_MODAL_WORDING: "single_tp_modal_wording",
  POSTHOG_API_KEY: "posthog_api_key"
};

const thirdPartyFormsLanguagesKeysDTO = {
  ID: "id",
  LANGUAGES: "languages",
  LANGUAGES_CODE: "languageCode",
  STATUS: "status",
  CREATED_AT: "createdAt",
  UPDATED_AT: "updatedAt",
  CREATED_BY_ID: "createdById"
};

const thirdPartyFormsLanguagesAPIDTO = {
  ID: "id",
  LANGUAGES: "languages",
  LANGUAGES_CODE: "language_code",
  STATUS: "status",
  CREATED_AT: "created_at",
  UPDATED_AT: "updated_at",
  CREATED_BY_ID: "created_by_id"
};

const ultimateBeneficialOwnersKeysAPIDTO = {
  BENEFICIARY_OWNERS: "beneficialOwners",
  RELATIONSHIPS: "relationships",
  BENEFICIARY_NAME: "name",
  BENEFICIARY_TYPE: "beneficiaryType",
  BUSINESS_OWNERSHIP_TYPE: "businessEntityType",
  BENEFICIARY_OWNERSHIP_PERCENTAGE: "beneficialOwnershipPercentage",
  DIRECT_OWNERSHIP_PERCENTAGE: "directOwnershipPercentage",
  INDIRECT_OWNERSHIP_PERCENTAGE: "indirectOwnershipPercentage",
  PERSON_OF_SIGNIFICANT_CONTROL: "isPSC",
  DESCRIPTION: "description",
  MEMBER_ID: "memberID",
  MEMBER_IDS: "member_ids",
  ACTION_IDS: "action_ids",
  THIRD_PARTY_ID: "third_party_id",
  COMPANY_ID: "company_id",
  MEMBER_ID_UNDERSCORED: "member_id",
  SOURCE_MEMBER_ID: "sourceMemberID",
  TARGET_MEMBER_ID: "targetMemberID",
  RELATIONSHIP_ID: "relationshipID",
  SHARE_PERCENTAGE: "sharePercentage",
  IS_BENEFICIARY: "isBeneficiary",
  RISK_STATUS_IDD: "risk_status_idd",
  RISK_STATUS_IDD_PLUS: "risk_status_idd_plus",
  DEGREE_OF_SEPARATION: "degreeOfSeparation",
  OWNED_ENTITY: "ownedEntity",
  ADDRESS: "address",
  ADDRESS_COUNTRY: "addressCountry",
  NAME: "name"
};

const ultimateBeneficialOwnersKeysDTO = {
  BENEFICIARY_OWNERS: "beneficialOwners",
  RELATIONSHIPS: "relationships",
  BENEFICIARY_NAME: "name",
  BENEFICIARY_TYPE: "beneficiaryType",
  BUSINESS_OWNERSHIP_TYPE: "businessEntityType",
  BENEFICIARY_OWNERSHIP_PERCENTAGE: "beneficialOwnershipPercentage",
  DIRECT_OWNERSHIP_PERCENTAGE: "directOwnershipPercentage",
  INDIRECT_OWNERSHIP_PERCENTAGE: "indirectOwnershipPercentage",
  PERSON_OF_SIGNIFICANT_CONTROL: "isPSC",
  MEMBER_ID: "memberId",
  SOURCE_MEMBER_ID: "sourceMemberId",
  TARGET_MEMBER_ID: "targetMemberId",
  RELATIONSHIP_ID: "relationshipId",
  SHARE_PERCENTAGE: "sharePercentage",
  IS_BENEFICIARY: "isBeneficiary",
  RISK_STATUS_IDD: "riskStatusIdd",
  RISK_STATUS_IDD_PLUS: "riskStatusIddPlus",
  DEGREE_OF_SEPARATION: "degreeOfSeparation",
  OWNED_ENTITY: "ownedEntity",
  COUNTRY: "country"
};

const ultimateBeneficialOwnersTableColumnKeys = {
  BENEFICIARY_NAME: "name",
  BENEFICIARY_TYPE: "beneficiaryType",
  BUSINESS_OWNERSHIP_TYPE: "businessEntityType",
  BENEFICIARY_OWNERSHIP_PERCENTAGE: "beneficialOwnershipPercentage",
  ENTITY_OWNERSHIP_PERCENTAGE: "relationshipSharePercentage",
  DIRECT_OWNERSHIP_PERCENTAGE: "directOwnershipPercentage",
  INDIRECT_OWNERSHIP_PERCENTAGE: "indirectOwnershipPercentage",
  PERSON_OF_SIGNIFICANT_CONTROL: "isPSC",
  MEMBER_ID: "memberId",
  RISK_STATUS_IDD: "riskStatusIdd",
  RISK_STATUS_IDD_PLUS: "riskStatusIddPlus",
  VIEW_PROFILE: "viewProfile",
  SELECT: "select",
  ID: "id",
  DEGREE_OF_SEPARATION: "degreeOfSeparation",
  OWNED_ENTITY: "ownedEntity",
  COUNTRY: "country"
};

const ultimateBeneficialOwnerEntityKeysAPIDTO = {
  BENEFICIARY_NAME: "name",
  BENEFICIARY_TYPE: "beneficiaryType",
  BIRTH_DATA: "birthDate",
  NATIONALITY: "nationality",
  ADDRESS_COUNTRY: "addressCountry",
  BUSINESS_OWNERSHIP_TYPE: "businessEntityType",
  BUSINESS_OWNERSHIP_ADDRESS: "address",
  BUSINESS_OWNERSHIP_STREET_ADDRESS: "streetAddress",
  BUSINESS_OWNERSHIP_ADDRESS_LOCALITY: "addressLocality",
  PERSON_OF_SIGNIFICANT_CONTROL: "isPSC",
  DIRECT_OWNERSHIP_PERCENTAGE: "directOwnershipPercentage",
  INDIRECT_OWNERSHIP_PERCENTAGE: "indirectOwnershipPercentage",
  BENEFICIARY_OWNERSHIP_PERCENTAGE: "beneficialOwnershipPercentage",
  OUT_OF_BUSINESS: "isOutOfBusiness",
  CONTROL_OWNERSHIP_TYPE: "controlOwnershipType",
  COUNTRY_NAME: "name",
  ADDRESS_LANE: "line1",
  ADDRESS_LOCALITY: "addressLocality",
  DESCRIPTION: "description",
  DEGREE_OF_SEPARATION: "degreeOfSeparation",
  OWNED_ENTITY: "ownedEntity",
  UBO_THIRD_PARTY_ID: "ubo_third_party_id",
  IS_ROOT_THIRD_PARTY: "isRootTp",
  COMPANY_NAME: "company_name"
};
const ultimateBeneficialOwnerEntityKeysDTO = {
  BENEFICIARY_NAME: "name",
  BENEFICIARY_TYPE: "beneficiaryType",
  BIRTH_DATA: "birthDate",
  NATIONALITY: "nationality",
  ADDRESS_COUNTRY: "addressCountry",
  BUSINESS_OWNERSHIP_TYPE: "businessEntityType",
  BUSINESS_OWNERSHIP_ADDRESS: "address",
  BUSINESS_OWNERSHIP_ADDRESS_LOCALITY: "addressLocality",
  PERSON_OF_SIGNIFICANT_CONTROL: "isPSC",
  DIRECT_OWNERSHIP_PERCENTAGE: "directOwnershipPercentage",
  INDIRECT_OWNERSHIP_PERCENTAGE: "indirectOwnershipPercentage",
  BENEFICIARY_OWNERSHIP_PERCENTAGE: "beneficialOwnershipPercentage",
  OUT_OF_BUSINESS: "isOutOfBusiness",
  CONTROL_OWNERSHIP_TYPE: "controlOwnershipType",
  COUNTRY_NAME: "name",
  ADDRESS_LOCALITY: "addressLocality",
  DEGREE_OF_SEPARATION: "degreeOfSeparation",
  OWNED_ENTITY: "ownedEntity",
  UBO_THIRD_PARTY_ID: "uboThirdPartyId",
  IS_ROOT_THIRD_PARTY: "isRootThirdParty",
  COMPANY_NAME: "companyName"
};
const thirdPartyOCStatesListKeysDTO = {
  JURISDICTION_CODE: "jurisdictionCode",
  CODE: "code",
  COUNTRY_CODE: "countryCode",
  ID: "id",
  STATE: "state",
  STATE_CODE: "stateCode"
};

const thirdPartyOCStatesListAPIDTO = {
  JURISDICTION_CODE: "jurisdiction_code",
  CODE: "code",
  COUNTRY_CODE: "country_code",
  ID: "id",
  STATE: "state"
};

const thirdPartyHeaderConfigKeysDTO = {
  MAXIMUM_ASSOCIATE: "maximumAssociate",
  SPECIAL_FEATURE: "specialFeature"
};

const thirdPartyHeaderConfigAPIDTO = {
  MAXIMUM_ASSOCIATE: "max_assoc",
  SPECIAL_FEATURE: "specialFeature"
};

const platformUserAPIDTO = {
  MEMBER_ID: "member_id",
  EMAIL: "email",
  FIRST_NAME: "firstname",
  LAST_NAME: "lastname",
  NAME: "name",
  MEMBER_GROUP_ID: "member_group_id",
  JOB_TITLE: "jobTitle",
  COUNTRY_ID: "country_id"
};

const platformUserDTO = {
  MEMBER_ID: "memberId",
  EMAIL: "email",
  FIRST_NAME: "firstName",
  LAST_NAME: "lastName",
  NAME: "name",
  MEMBER_GROUP_ID: "memberGroupId",
  JOB_TITLE: "jobTitle",
  COUNTRY_ID: "countryId"
};

const pyramidTokenAPIDTO = {
  TOKEN: "token",
  DOMAIN: "domain"
};

const pyramidTokenDTO = {
  TOKEN: "token",
  DOMAIN: "domain"
};

const productVersionsKeysDTO = {
  EB360: "eb360",
  ERAF_ACTIONS: "erafActions",
  ERAF_AUTOMATIONS: "erafAutomations",
  ERAF_TASKS: "erafTasks",
  CORPORATE_DATA: "corporateData",
  GREENLITE_ANALYTICS: "greenliteAnalytics",
  GREENLITE_SCORING: "greenliteScoring",
  ROLES_AND_PERMISSIONS: "rolesAndPermissions",
  SERVICE: "service",
  VERSION: "version",
  TRAINING: "training",
  NOTIFICATIONS: "notifications",
  PRODUCT_AND_PLATFORM: "productAndPlatform"
};

const productVersionsKeysAPIDTO = {
  EB360: "eb360",
  ERAF_ACTIONS: "eraf_actions",
  ERAF_AUTOMATIONS: "eraf_automations",
  ERAF_TASKS: "eraf_tasks",
  CORPORATE_DATA: "corporate_data",
  GREENLITE_ANALYTICS: "greenlite_analytics",
  GREENLITE_SCORING: "greenlite_scoring",
  ROLES_AND_PERMISSIONS: "roles_and_permissions",
  SERVICE: "service",
  VERSION: "version",
  TRAINING: "training",
  NOTIFICATIONS: "notifications",
  PRODUCT_AND_PLATFORM: "product_and_platform"
};

const dateFormatOptionsTypes = {
  LONG: "long",
  NUMERIC: "numeric",
  SHORT: "short"
};

const customAttributesWidgetListKeysDTO = {
  TYPE: "type",
  TITLE: "title",
  WIDGETS: "widgets",
  LABEL: "label",
  DESCRIPTION: "description",
  ID: "id",
  IS_ACTIVE: "isActive"
};

const customAttributesWidgetListKeysAPIDTO = {
  TYPE: "type",
  TITLE: "title",
  WIDGETS: "widgets",
  LABEL: "label",
  DESCRIPTION: "description",
  ID: "id",
  IS_ACTIVE: "is_active"
};

const customAttributesChartKeysDTO = {
  LABEL: "label",
  VALUE: "value",
  COUNT: "count"
};

const customAttributesChartKeysAPIDTO = {
  LABEL: "label",
  VALUE: "value",
  COUNT: "count"
};

const tasksChartKeysAPIDTO = {
  STATUS: "status",
  STATUSES: "statuses",
  COUNT: "count",
  TOTAL: "total",
  DATE: "date",
  DATA: "data",
  VALUE: "value",
  LABEL: "label",
  TASKS: "tasks",
  OUTCOME_NAME: "outcome_name",
  OUTCOME_ID: "outcome_id",
  ASSIGNEES: "assignees",
  ASSIGNEE_NAME: "assignee_name",
  ASSIGNEE_ID: "assignee_id",
  TASK_COUNT: "task_count"
};

const customWidgetKeys = {
  IS_LOADING: "isLoading",
  DATA: "data"
};
const userEmailTemplateKeysDTO = {
  ID: "id",
  NAME: "name",
  SUBJECT: "subject",
  DESCRIPTION: "description",
  EXPECTED_DATA: "expectedData",
  VARIABLE: "variable",
  TYPE: "type",
  BODY: "body",
  TO: "to",
  CC: "cc",
  BCC: "bcc",
  VALUE: "value",
  EMAIL: "email",
  COMPANY_ID: "companyId",
  ROLE_ID: "roleId",
  USER_ID: "userId",
  ACTION: "ACTION"
};

const userEmailTemplateKeysAPIDTO = {
  ID: "id",
  NAME: "name",
  SUBJECT: "subject",
  DESCRIPTION: "description",
  EXPECTED_DATA: "expected_data",
  VARIABLE: "variable",
  TYPE: "type",
  BODY: "body",
  TO: "to",
  CC: "cc",
  BCC: "bcc",
  VALUE: "value",
  EMAIL: "email",
  COMPANY_ID: "company_id",
  ROLE_ID: "role_id",
  USER_ID: "user_id",
  ACTION: "action"
};

const ultimateBeneficialOwnersProfileDueDiligenceKeysDTO = {
  CREATE_DATE: "createdAt",
  TYPE: "type",
  RISK_STATUS: "riskStatus",
  REMEDIATED_DATE: "remediatedAt",
  REMEDIATED_STATUS: "remediatedRiskStatus",
  VIEW_REPORT: "viewReport",
  DOWNLOAD_REPORT: "downloadReport",
  DOWNLOAD_REMEDIATED_REPORT: "downloadRemediatedReport"
};

const ultimateBeneficialOwnersProfileDueDiligenceKeysAPIDTO = {
  CREATE_DATE: "created_at",
  TYPE: "type",
  RISK_STATUS: "risk_status",
  REMEDIATED_DATE: "remediated_at",
  REMEDIATED_STATUS: "remediated_risk_status",
  VIEW_REPORT: "view_report",
  DOWNLOAD_REPORT: "download_report",
  DOWNLOAD_REMEDIATED_REPORT: "download_remediated_report"
};

const onboardingTasksKeysDTO = {
  TASK_ID: "taskId",
  TASK_STATUS: "taskStatus",
  TASK_TITLE: "taskTitle",
  TASK_TYPE: "taskType",
  TASK_DESCRIPTION: "taskDescription",
  LANGUAGE: "language",
  USER_ID: "userId",
  THIRD_PARTY_ID: "thirdPartyId",
  COMP_NAME_ENG: "companyEnglishName",
  COUNTRY: "country",
  ONBOARDING_STAGE_TASKS_CREATED_AT: "onboardingStageTasksCreatedAt",
  FORM_ID: "formId",
  SCHEMA: "schema",
  FORM: "form",
  FORM_DATA: "formData"
};

const onboardingTasksKeysAPIDTO = {
  TASK_ID: "id",
  TASK_STATUS: "task_status",
  TASK_TITLE: "task_title",
  TASK_TYPE: "task_type",
  TASK_DESCRIPTION: "task_description",
  LANGUAGE: "language",
  USER_ID: "user_id",
  THIRD_PARTY_ID: "third_party_id",
  COMP_NAME_ENG: "comp_name_eng",
  COUNTRY: "country",
  ONBOARDING_STAGE_TASKS_CREATED_AT: "onboarding_stage_tasks_created_at",
  FORM_ID: "form_id",
  SCHEMA: "schema",
  FORM: "form",
  FORM_DATA: "form_data"
};

const companyFeaturesKeysDTO = {
  ID: "id",
  SETTING_ID: "settingId",
  SETTING_OPTION: "settingOption",
  SEQUENCE: "sequence",
  NAME: "name",
  TYPE: "type",
  VALUE: "value",
  TEXT: "text"
};

const companyFeaturesKeysAPIDTO = {
  ID: "id",
  SETTING_ID: "setting_id",
  SETTING_OPTION: "setting_option",
  SEQUENCE: "sequence",
  NAME: "name",
  TYPE: "type",
  VALUE: "value",
  TEXT: "text"
};

const featurePermissionKeysDTO = {
  ID: "id",
  NAME: "name",
  DESCRIPTION: "description",
  ROLE_IDS: "roleIds",
  PERMISSION: "permission"
};

const featurePermissionKeysAPIDTO = {
  ID: "id",
  NAME: "name",
  DESCRIPTION: "description",
  ROLE_IDS: "role_ids",
  PERMISSION: "permission"
};

const featurePermissionsKeys = {
  VIEW_ANALYTICS: "view-analytics-page",
  VIEW_MANAGE_THIRD_PARTIES: "view-manage-third-parties-page",
  VIEW_ESG_THIRD_PARTIES: "view-esg-explore-page",
  VIEW_EVENT_LOG: "view-event-log-page",
  ACCESS_TO_DOCUMENTS: "access-third-party-documents",
  ADD_THIRD_PARTIES: "add-third-parties",
  EDIT_THIRD_PARTIES: "edit-third-parties",
  ARCHIVE_THIRD_PARTIES: "archive-third-parties",
  BULK_UPLOAD: "import-third-parties",
  CREATE_AND_REMOVE_USER_VIEWS: "add-delete-user-view",
  ESG_EXPORT: "export-esg-explore",
  EXPORT: "export-third-party",
  TRIGGER_AUTOMATION: "trigger-automation",
  VIEW_USER_VIEW: "view-user-view",
  EDIT_USER_VIEW: "edit-user-view",
  MODIFY_COLUMNS_USER_VIEW: "edit-user-view-columns",
  CHANGE_FILTERS_USER_VIEW: "edit-user-view-filters",
  REOPEN_TASK: "reopen-task",
  EDIT_TASK: "edit-task",
  PICK_TASK_OUTCOME: "pick-task-outcome",
  NAVIGATE_TO_MATCHED_THIRD_PARTY: "navigate-to-matched-third-party",
  BULK_UPDATE_THIRD_PARTIES: "bulk-update-third-parties"
};

export {
  riskAlertKeysAPIDTO,
  riskAlertKeysDTO,
  automationsAPIKeys,
  automationsKeys,
  companyOrganizationsKeys,
  seniorPrincipalsKeys,
  numberOfEmployeesKeys,
  registrationNumbersKeys,
  greenliteKeySections,
  greenliteThirdPartyKeys,
  greenliteColumnIdKeys,
  triggerStepKeys,
  multiSelectSearchKeys,
  ESGReportsKeys,
  ESGReportsAPIKeys,
  userPermissionKeysAPIDTO,
  userPermissionKeysDTO,
  userRolesKeysAPIDTO,
  userRolesKeysDTO,
  authUserKeysDTO,
  authUserKeysAPIDTO,
  companyKeysDTO,
  companyKeysAPIDTO,
  companyUserListKeysDTO,
  companyUserListKeysAPIDTO,
  deleteCompanyUserKeysDTO,
  deleteCompanyUserKeysAPIDTO,
  automationConditionKeys,
  additionalVouchersKeysDTO,
  additionalVouchersKeysAPIDTO,
  platformSettingsKeysDTO,
  platformSettingsKeysAPIDTO,
  thirdPartyFormsLanguagesAPIDTO,
  thirdPartyFormsLanguagesKeysDTO,
  ultimateBeneficialOwnersKeysAPIDTO,
  ultimateBeneficialOwnersKeysDTO,
  ultimateBeneficialOwnerEntityKeysAPIDTO,
  ultimateBeneficialOwnerEntityKeysDTO,
  ultimateBeneficialOwnersTableColumnKeys,
  platformUserAPIDTO,
  platformUserDTO,
  thirdPartyOCStatesListKeysDTO,
  thirdPartyOCStatesListAPIDTO,
  thirdPartyHeaderConfigKeysDTO,
  thirdPartyHeaderConfigAPIDTO,
  productVersionsKeysDTO,
  productVersionsKeysAPIDTO,
  dateFormatOptionsTypes,
  customAttributesWidgetListKeysDTO,
  customAttributesWidgetListKeysAPIDTO,
  customAttributesChartKeysDTO,
  customAttributesChartKeysAPIDTO,
  customWidgetKeys,
  esgExportReportKeys,
  esgExportReportAPIKeys,
  userEmailTemplateKeysDTO,
  userEmailTemplateKeysAPIDTO,
  ultimateBeneficialOwnersProfileDueDiligenceKeysAPIDTO,
  ultimateBeneficialOwnersProfileDueDiligenceKeysDTO,
  onboardingTasksKeysDTO,
  onboardingTasksKeysAPIDTO,
  companyFeaturesKeysDTO,
  companyFeaturesKeysAPIDTO,
  featurePermissionKeysDTO,
  featurePermissionKeysAPIDTO,
  featurePermissionsKeys,
  pyramidTokenAPIDTO,
  pyramidTokenDTO,
  tasksChartKeysAPIDTO
};
