import { tasksKeysDTO } from "@/constants/keys-constant/tasks.keys.constant";

const taskKeyMapper = {
  [tasksKeysDTO.COMPANY_TASK_ID]: "Id",
  [tasksKeysDTO.TEMPLATE_ID]: "Created from template",
  [tasksKeysDTO.CREATED_AT]: "Date created",
  [tasksKeysDTO.UPDATED_AT]: "Date updated",
  [tasksKeysDTO.DESCRIPTION]: "Description"
};

const taskStateKeys = {
  CLOSED: "CLOSED",
  TO_DO: "TO_DO",
  IN_PROGRESS: "IN_PROGRESS"
};

const taskStateValue = {
  [taskStateKeys.CLOSED]: "closed",
  [taskStateKeys.TO_DO]: "todo",
  [taskStateKeys.IN_PROGRESS]: "in progress"
};

const taskStateText = {
  [taskStateKeys.CLOSED]: "closed",
  [taskStateKeys.TO_DO]: "to do",
  [taskStateKeys.IN_PROGRESS]: "in progress"
};

const taskWidgetTypes = {
  TASK_TEMPLATE: "task_template",
  ALL_TASKS: "task"
};

const taskTemplateWidgetKeys = {
  OPEN_TASK_COUNT: "open_task_count",
  IN_PROGRESS_TASK: "in_progress_task",
  COMPLETED_TASK_COUNT: "completed_task_count",
  COMPLETED_TASK_SELECTED_OUTCOMES: "completed_task_selected_outcomes",
  DISTRIBUTION_COMPLETED_TASKS: "distribution_completed_tasks",
  AGE_NOT_COMPLETED_TASKS: "age_not_completed_tasks",
  CREATED_DATE_TASKS: "created_date_tasks",
  UPDATED_DATE_TASKS: "updated_date_tasks",
  OPEN_TASKS_ASSIGNEE: "open_tasks_assignee",
  IN_PROGRESS_TASKS_ASSIGNEE: "in_progress_tasks_assignee"
};

const allTasksWidgetKeys = {
  ALL_TASKS_OPEN_STATUS: "all_tasks_open_status",
  ALL_TASKS_IN_PROGRESS_STATUS: "all_tasks_in_progress_status",
  ALL_TASKS_COMPLETED_STATUS: "all_tasks_completed_status"
};

const taskWidgetStatusValue = {
  COMPLETED: "completed",
  OPEN: "open",
  IN_PROGRESS: "in_progress"
};

export {
  taskKeyMapper,
  taskStateValue,
  taskStateText,
  taskStateKeys,
  taskWidgetTypes,
  taskTemplateWidgetKeys,
  allTasksWidgetKeys,
  taskWidgetStatusValue
};
