<template>
  <aside class="app-sidebar">
    <component
      v-for="item in sidebarItems"
      :key="item.id"
      :is="item.component"
      v-bind="item.options"
    />
  </aside>
</template>

<script>
import {
  urls,
  esgTexts,
  routeNames,
  analyticsConfigurationViews,
  userViewType,
  themes,
  getterName,
  typographySize
} from "@/constants";
import { hasAccessToRoute } from "@/utils";
import { mapGetters, mapState } from "vuex";
import SidebarMenuItem from "@/molecules/SidebarMenuItem/SidebarMenuItem";
import SidebarMenuList from "@/molecules/SidebarMenuList/SidebarMenuList";

export default {
  name: "AppSidebar",
  components: {
    SidebarMenuItem,
    SidebarMenuList
  },
  data() {
    return {
      themes,
      typographySize
    };
  },
  computed: {
    ...mapState({
      newSidebarData: (state) => state.newSidebarData,
      companyName: (state) => state.company.companyName
    }),
    ...mapGetters({
      getCompanyViewsWithPermission:
        getterName.COMPANY.GET_COMPANY_VIEWS_WITH_PERMISSION,
      permittedLandingPage: getterName.USER.PERMITTED_LANDING_PAGE
    }),
    sidebarItems() {
      return [
        {
          component: SidebarMenuItem,
          id: 0,
          options: {
            textOptions: {
              text: this.companyName,
              size: typographySize.BODY_TEXT_BOLD
            },
            iconOptions: {
              icon: "home"
            },
            path: this.permittedLandingPage,
            onClick: () => {
              this.navigateTo(this.permittedLandingPage);
            }
          }
        },
        ...(hasAccessToRoute(routeNames.ANALYTICS_OVERVIEW) &&
        this.analyticsMenuItemOptions?.length
          ? [
              {
                component: SidebarMenuList,
                id: 1,
                options: {
                  headingOptions: {
                    textOptions: {
                      text: "Analytics"
                    },
                    iconOptions: {
                      icon: "chart-bar"
                    },
                    isActive: this.isChildRouteActive(
                      this.analyticsMenuItemOptions
                    )
                  },
                  listOptions: this.analyticsMenuItemOptions
                }
              }
            ]
          : []),
        ...(hasAccessToRoute(routeNames.MANAGE_THIRD_PARTY)
          ? [
              {
                component: SidebarMenuItem,
                id: 2,
                options: {
                  textOptions: {
                    text: "Manage third parties"
                  },
                  iconOptions: {
                    icon: "list-alt"
                  },
                  isActive: this.$route.path === urls.MANAGE_THIRD_PARTY,
                  path: urls.MANAGE_THIRD_PARTY,
                  onClick: () => {
                    this.navigateTo(urls.MANAGE_THIRD_PARTY);
                  }
                }
              }
            ]
          : []),
        ...(hasAccessToRoute(routeNames.ESG_THIRD_PARTIES)
          ? [
              {
                component: SidebarMenuItem,
                id: 3,
                options: {
                  textOptions: {
                    text: esgTexts.ESG_EXPLORE
                  },
                  iconOptions: {
                    icon: "leaf"
                  },
                  path: urls.ESG_THIRD_PARTIES,
                  isActive: this.$route.path === urls.ESG_THIRD_PARTIES,
                  onClick: () => {
                    this.navigateTo(urls.ESG_THIRD_PARTIES);
                  }
                }
              }
            ]
          : []),
        ...(hasAccessToRoute(routeNames.EVENT_LOG)
          ? [
              {
                component: SidebarMenuItem,
                id: 4,
                options: {
                  textOptions: {
                    text: "Event log"
                  },
                  iconOptions: {
                    icon: "calendar-plus"
                  },
                  path: urls.EVENT_LOG,
                  isActive: this.$route.path === urls.EVENT_LOG,
                  onClick: () => {
                    this.navigateTo(urls.EVENT_LOG);
                  }
                }
              }
            ]
          : []),
        ...(hasAccessToRoute(routeNames.TASKS)
          ? [
              {
                component: SidebarMenuItem,
                id: 5,
                options: {
                  textOptions: {
                    text: "Tasks"
                  },
                  iconOptions: {
                    icon: "tasks"
                  },
                  path: urls.TASKS,
                  isActive: this.$route.path === urls.TASKS,
                  onClick: () => {
                    this.navigateTo(urls.TASKS);
                  }
                }
              }
            ]
          : []),
        ...(this.getCompanyViewsWithPermission?.length
          ? [
              {
                component: SidebarMenuList,
                id: 6,
                options: {
                  headingOptions: {
                    textOptions: {
                      text: "User views"
                    },
                    iconOptions: {
                      icon: "user"
                    },
                    isActive: this.isChildRouteActive(this.companyViewMenuItems)
                  },
                  listOptions: this.companyViewMenuItems
                }
              }
            ]
          : []),
        ...(hasAccessToRoute(routeNames.PLATFORM_SETTINGS)
          ? [
              {
                component: SidebarMenuList,
                id: 7,
                options: {
                  headingOptions: {
                    textOptions: {
                      text: "Platform settings"
                    },
                    iconOptions: {
                      icon: "cog"
                    },
                    isActive: this.isChildRouteActive(
                      this.platformSettingsItemOptions
                    )
                  },
                  listOptions: this.platformSettingsItemOptions
                }
              }
            ]
          : [])
      ];
    },
    platformSettingsItemOptions() {
      return [
        {
          component: SidebarMenuItem,
          options: {
            textOptions: {
              text: "Users"
            },
            path: urls.USER_SETTINGS,
            isActive: this.$route.path === urls.USER_SETTINGS,
            onClick: () => {
              this.navigateTo(urls.USER_SETTINGS);
            }
          }
        },
        {
          component: SidebarMenuItem,
          options: {
            textOptions: {
              text: "Roles"
            },
            path: urls.ROLES,
            isActive: this.$route.path === urls.ROLES,
            onClick: () => {
              this.navigateTo(urls.ROLES);
            }
          }
        },
        {
          component: SidebarMenuItem,
          options: {
            textOptions: {
              text: "Permissions"
            },
            path: urls.PERMISSIONS_PLATFORM_FEATURES,
            isActive: this.$route.path === urls.PERMISSIONS_PLATFORM_FEATURES,
            onClick: () => {
              this.navigateTo(urls.PERMISSIONS_PLATFORM_FEATURES);
            }
          }
        },
        {
          component: SidebarMenuItem,
          options: {
            textOptions: {
              text: "Notification schemes"
            },
            path: urls.NOTIFICATION_SETTINGS,
            isActive: this.$route.path === urls.NOTIFICATION_SETTINGS,
            onClick: () => {
              this.navigateTo(urls.NOTIFICATION_SETTINGS);
            }
          }
        },
        {
          component: SidebarMenuItem,
          options: {
            textOptions: {
              text: "Branding and links"
            },
            path: urls.BRANDING_AND_LINKS_SETTINGS,
            isActive: this.$route.path === urls.BRANDING_AND_LINKS_SETTINGS,
            onClick: () => {
              this.navigateTo(urls.BRANDING_AND_LINKS_SETTINGS);
            }
          }
        },
        {
          component: SidebarMenuItem,
          options: {
            textOptions: {
              text: "General"
            },
            path: urls.GENERAL_SETTINGS,
            isActive: this.$route.path === urls.GENERAL_SETTINGS,
            onClick: () => {
              this.navigateTo(urls.GENERAL_SETTINGS);
            }
          }
        },
        ...(hasAccessToRoute(routeNames.TASK_TEMPLATES)
          ? [
              {
                component: SidebarMenuItem,
                options: {
                  textOptions: {
                    text: "Task templates"
                  },
                  path: urls.TASK_TEMPLATES,
                  isActive: this.$route.path === urls.TASK_TEMPLATES,
                  onClick: () => {
                    this.navigateTo(urls.TASK_TEMPLATES);
                  }
                }
              }
            ]
          : []),
        ...(hasAccessToRoute(routeNames.ACTIONS)
          ? [
              {
                component: SidebarMenuItem,
                options: {
                  textOptions: {
                    text: "Actions"
                  },
                  path: urls.ACTIONS,
                  isActive: this.$route.path === urls.ACTIONS,
                  onClick: () => {
                    this.navigateTo(urls.ACTIONS);
                  }
                }
              }
            ]
          : []),
        ...(hasAccessToRoute(routeNames.AUTOMATIONS)
          ? [
              {
                component: SidebarMenuItem,
                options: {
                  textOptions: {
                    text: "Automations"
                  },
                  path: urls.AUTOMATIONS,
                  isActive: this.$route.path === urls.AUTOMATIONS,
                  onClick: () => {
                    this.navigateTo(urls.AUTOMATIONS);
                  }
                }
              }
            ]
          : []),
        {
          component: SidebarMenuItem,
          options: {
            textOptions: {
              text: "Custom attributes"
            },
            path: urls.CUSTOM_ATTRIBUTES,
            isActive: this.$route.path === urls.CUSTOM_ATTRIBUTES,
            onClick: () => {
              this.navigateTo(urls.CUSTOM_ATTRIBUTES);
            }
          }
        },
        {
          component: SidebarMenuItem,
          options: {
            textOptions: {
              text: "Email templates"
            },
            path: urls.EMAIL_TEMPLATE,
            isActive: this.$route.path === urls.EMAIL_TEMPLATE,
            onClick: () => {
              this.navigateTo(urls.EMAIL_TEMPLATE);
            }
          }
        },
        {
          component: SidebarMenuItem,
          options: {
            textOptions: {
              text: "User email templates"
            },
            path: urls.USER_EMAIL_TEMPLATES,
            isActive: this.$route.path === urls.USER_EMAIL_TEMPLATES,
            onClick: () => {
              this.navigateTo(urls.USER_EMAIL_TEMPLATES);
            }
          }
        },
        ...(hasAccessToRoute(routeNames.PRODUCT_VERSION)
          ? [
              {
                component: SidebarMenuItem,
                options: {
                  textOptions: {
                    text: "Product version"
                  },
                  path: urls.PRODUCT_VERSION,
                  isActive: this.$route.path === urls.PRODUCT_VERSION,
                  onClick: () => {
                    this.navigateTo(urls.PRODUCT_VERSION);
                  }
                }
              }
            ]
          : [])
      ];
    },
    analyticsMenuItemOptions() {
      const filteredMenu = this.newSidebarData?.analyticsConfig?.filter(
        (menu) =>
          parseInt(menu.isActive) === 1 &&
          menu.title !== analyticsConfigurationViews.APRI
      );
      const mappedMenu =
        filteredMenu?.map((item) => {
          const listOptions = item?.items
            ?.filter((childItem) => parseInt(childItem?.isActive) === 1)
            ?.map((childItem) =>
              this.setAnalyticsMenuItemOptions({
                menuTitle: item?.title,
                childItem
              })
            );

          return {
            component: SidebarMenuList,
            options: {
              headingOptions: {
                textOptions: {
                  text: item?.title
                },
                iconOptions: {
                  icon: this.getAnalyticsIcon(item?.title)
                },
                isActive: this.isChildRouteActive(listOptions)
              },
              listOptions
            }
          };
        }) || [];

      return mappedMenu;
    },
    companyViewMenuItems() {
      return (
        this.getCompanyViewsWithPermission?.map((companyView) => {
          const viewType = companyView?.viewType || companyView?.view_type;

          switch (parseInt(viewType)) {
            case userViewType.THIRD_PARTY:
              return {
                component: SidebarMenuItem,
                options: {
                  textOptions: {
                    text: companyView?.title
                  },
                  iconOptions: {
                    icon: "tasks"
                  },
                  path: urls.MANAGE_THIRD_PARTY_ID(companyView?.id),
                  isActive:
                    this.$route.path ===
                    urls.MANAGE_THIRD_PARTY_ID(companyView?.id),
                  onClick: () => {
                    this.navigateTo(
                      urls.MANAGE_THIRD_PARTY_ID(companyView?.id)
                    );
                  }
                }
              };
            case userViewType.EVENT_LOG:
              return {
                component: SidebarMenuItem,
                options: {
                  textOptions: {
                    text: companyView?.title
                  },
                  iconOptions: {
                    icon: "calendar-plus"
                  },
                  path: urls.EVENT_LOG_ID(companyView?.id),
                  isActive:
                    this.$route.path === urls.EVENT_LOG_ID(companyView?.id),
                  onClick: () => {
                    this.navigateTo(urls.EVENT_LOG_ID(companyView?.id));
                  }
                }
              };
            case userViewType.USER_VIEW_ANALYTICS:
              return {
                component: SidebarMenuItem,
                options: {
                  textOptions: {
                    text: companyView?.title
                  },
                  iconOptions: {
                    icon: "chart-bar"
                  },
                  path: urls.ANALYTICS_USER_VIEW_ID(companyView?.id),
                  isActive:
                    this.$route.path ===
                    urls.ANALYTICS_USER_VIEW_ID(companyView?.id),
                  onClick: () => {
                    this.navigateTo(
                      urls.ANALYTICS_USER_VIEW_ID(companyView?.id)
                    );
                  }
                }
              };
            case userViewType.BI_DASHBOARD:
              return {
                component: SidebarMenuItem,
                options: {
                  textOptions: {
                    text: companyView?.title
                  },
                  iconOptions: {
                    icon: "cubes"
                  },
                  path: urls.BI_DASHBOARD(companyView?.id),
                  isActive:
                    this.$route.path === urls.BI_DASHBOARD(companyView?.id),
                  onClick: () => {
                    this.navigateTo(urls.BI_DASHBOARD(companyView?.id));
                  }
                }
              };
          }
        }) || []
      );
    }
  },
  methods: {
    setAnalyticsMenuItemOptions({ menuTitle, childItem }) {
      switch (menuTitle) {
        case analyticsConfigurationViews.APRI:
          return {
            component: SidebarMenuItem,
            options: {
              textOptions: {
                text: childItem?.title
              },
              iconOptions: {
                icon: "chart-pie"
              },
              path: urls.ANALYTICS_APRI,
              isActive: this.$route.path === urls.ANALYTICS_APRI,
              onClick: () => {
                this.navigateTo(urls.ANALYTICS_APRI);
              }
            }
          };
        case analyticsConfigurationViews.OVERVIEW:
          return {
            component: SidebarMenuItem,
            options: {
              textOptions: {
                text: childItem?.title
              },
              iconOptions: {
                icon: "chart-line"
              },
              path: urls.ANALYTICS_OVERVIEW,
              isActive: this.$route.path === urls.ANALYTICS_OVERVIEW,
              onClick: () => {
                this.navigateTo(urls.ANALYTICS_OVERVIEW);
              }
            }
          };
        case analyticsConfigurationViews.QUESTIONNAIRES:
          return {
            component: SidebarMenuItem,
            options: {
              textOptions: {
                text: childItem?.title
              },
              iconOptions: {
                icon: "clipboard"
              },
              path: this.getQuestionnairePath(childItem),
              isActive:
                this.$route.path === this.getQuestionnairePath(childItem),
              onClick: () => {
                this.navigateTo(this.getQuestionnairePath(childItem));
              }
            }
          };
      }
    },
    getAnalyticsIcon(title) {
      switch (title) {
        case "Overview":
          return "list";
        case "Questionnaires":
          return "clipboard";
        default:
          return "";
      }
    },
    getQuestionnairePath(item) {
      return item?.title?.includes("Comparative")
        ? urls.ANALYTICS_QUESTIONNAIRES_COMPARATIVE(item?.id)
        : urls.ANALYTICS_QUESTIONNAIRES_ID(item?.id);
    },
    isChildRouteActive(options) {
      return options?.some(
        (option) =>
          option?.options?.isActive || option?.options?.headingOptions?.isActive
      );
    },
    navigateTo(route) {
      this.$router.push(route);
    }
  }
};
</script>

<style lang="scss" scoped>
.app-sidebar {
  position: fixed;
  z-index: 99;
  transition: $transition-duration;
  overflow: visible;
  border-right: 1px solid $grey;
  background-color: $white;
  width: 292px;
  height: calc(100vh - 60px);
  overflow: auto;
}
</style>
