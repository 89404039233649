<template>
  <div class="product-widget">
    <div
      v-observe-visibility="
        makeObserveVisibility(fetchProductWidgetDataWrapper, widget)
      "
    >
      <BaseText :size="typographySize.BODY_TEXT_BOLD">{{
        widget.label
      }}</BaseText>

      <BaseLoader
        v-if="isLoading"
        data-test-id="product-widget__loader"
        class="product-widget__loader"
      />
      <div v-else class="product-widget__status-wrapper">
        <div
          v-for="(status, index) in widgetData"
          :key="index"
          class="product-widget__status-card"
        >
          <BaseChartWidget
            :chart-title="getStatusLabel(status)"
            :chart-options="chartOptions"
            class="product-widget__status-card__title"
          >
            <PointValue v-bind="getPointValueOptions(status)" />
          </BaseChartWidget>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseChartWidget from "@/atoms/BaseChartWidget/BaseChartWidget";
import PointValue from "@/molecules/PointValue/PointValue";
import BaseText from "@/atoms/BaseText/BaseText";
import { makeObserveVisibility } from "@/utils";
import BaseLoader from "@/atoms/BaseLoader/BaseLoader";
import {
  customWidgetKeys,
  typographySize,
  customErrorMessages,
  toastPropFailure
} from "@/constants";
import { chartsService } from "@/services";
import { mapState } from "vuex";

export default {
  name: "ProductWidget",
  components: { BaseChartWidget, PointValue, BaseText, BaseLoader },
  props: {
    widget: {
      type: Object,
      required: true,
      default: () => ({})
    }
  },

  data() {
    return {
      typographySize,
      activeProductWidgets: {},
      chartOptions: {}
    };
  },

  computed: {
    ...mapState({
      tenantId: (state) => state.company.tenantId
    }),
    isLoading() {
      return !!this.activeProductWidgets[this.widgetKey]?.[
        customWidgetKeys.IS_LOADING
      ];
    },
    widgetKey() {
      return this.combineCategoryAndId(this.widget);
    },
    widgetData() {
      return (
        this.activeProductWidgets[this.widgetKey]?.[customWidgetKeys.DATA] || []
      );
    }
  },

  methods: {
    makeObserveVisibility,
    fetchProductWidgetDataWrapper(isVisible, widget) {
      if (
        isVisible &&
        !this.activeProductWidgets[this.combineCategoryAndId(widget)]
      ) {
        this.activeProductWidgets[this.widgetKey] =
          this.makeDefaultCustomWidgetDataStructure();

        this.fetchProductStatusWidgetData(widget);
      }
    },
    async fetchProductStatusWidgetData(widget) {
      if (!this.activeProductWidgets?.[this.combineCategoryAndId(widget)]) {
        return;
      }

      this.activeProductWidgets[this.widgetKey][
        customWidgetKeys.IS_LOADING
      ] = true;

      try {
        const { data = {} } = await chartsService.fetchTrainingStatusWidgetData(
          {
            tenantId: this.tenantId,
            courseId: widget?.id
          }
        );

        this.activeProductWidgets[this.widgetKey][customWidgetKeys.DATA] =
          data?.statuses;
      } catch {
        this.$bvToast.toast(
          customErrorMessages.generic.fetchDataError,
          toastPropFailure
        );
      } finally {
        this.activeProductWidgets[this.widgetKey][
          customWidgetKeys.IS_LOADING
        ] = false;
      }
    },
    combineCategoryAndId({ category = "", id = "" }) {
      return `${category}_${id}`;
    },
    makeDefaultCustomWidgetDataStructure() {
      return {
        [customWidgetKeys.IS_LOADING]: false,
        [customWidgetKeys.DATA]: null
      };
    },
    getStatusLabel(status) {
      return status?.label || "";
    },
    getPointValueOptions(status) {
      return {
        value: status?.count || 0
      };
    }
  }
};
</script>
<style lang="scss" scoped>
.product-widget {
  padding: $spacing12;
  border: 1px solid rgba($black, 0.125);
  border-radius: $spacing4;
  text-align: center;
  &__loader {
    font-size: $spacing24;
  }
  &__status-wrapper {
    display: flex;
    justify-content: space-evenly;
  }
  &__status-card {
    &__title {
      text-transform: capitalize;
    }
  }
}
</style>
