import Vue, { createApp } from "vue";
import App from "./App.vue";
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import "bootstrap-vue/dist/bootstrap-vue-icons.min.css";
import { dom, library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import axios from "axios";
import VueAxios from "vue-axios";
import vSelect from "vue-select";
import router from "@/routes";
import CountryFlag from "vue-country-flag";
import VueSidebarMenu from "vue-sidebar-menu";
import "vue-sidebar-menu/dist/vue-sidebar-menu.css";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.css";
import store from "@/store";
import "vue-select/dist/vue-select.css";
import i18n from "./i18n";
import HighchartsVue from "highcharts-vue";
import Highcharts from "highcharts";
import VueHighcharts from "vue-highcharts";
import highchartsMore from "highcharts/highcharts-more";
import highcharts3d from "highcharts/highcharts-3d";
import highchartsFunnel from "highcharts/modules/funnel";
import networkGraphInit from "highcharts/modules/networkgraph";
import stockInit from "highcharts/modules/stock";
import highmaps from "highcharts/modules/map";
import solidGaugeInit from "highcharts/modules/solid-gauge";
import exportingInit from "highcharts/modules/exporting";
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import VueObserveVisibility from "vue3-observe-visibility";
import InputColorPicker from "vue-native-color-picker";
import VueNumberInput from "@chenfengyuan/vue-number-input";
import "vue-sidebar-menu/src/scss/vue-sidebar-menu.scss";
import posthog from "posthog-js";
import "./assets/style.scss";

dom.watch(); // This will kick of the initial replacement of i to svg tags and configure a MutationObserver
library.add(fas);

Vue.component("quill-editor", quillEditor);
Vue.component("multiselect", Multiselect);
Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.component("v-select", vSelect);
Vue.component("country-flag", CountryFlag);
Vue.component("vue-number-input", VueNumberInput);
Vue.use(VueAxios, axios);
Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(HighchartsVue);
highmaps(Highcharts);
highchartsMore(Highcharts);
highchartsFunnel(Highcharts);
stockInit(Highcharts);
highcharts3d(Highcharts);
solidGaugeInit(Highcharts);
networkGraphInit(Highcharts);
exportingInit(Highcharts);
Vue.use(VueHighcharts, { Highcharts });
Vue.use(InputColorPicker);
const eb360App = createApp({
  ...App
});
eb360App.provide("$posthog", posthog);
eb360App.use(store);
eb360App.use(router);
eb360App.use(i18n);
eb360App.use(VueSidebarMenu);
eb360App.use(VueObserveVisibility);
eb360App.directive(
  "observe-visibility",
  VueObserveVisibility.ObserveVisibility
);
eb360App.mount("#app");
