<template>
  <div class="base-text__wrapper">
    <template v-if="processedTextList.length">
      <component
        v-bind="$attrs"
        :is="tag"
        v-for="(textLine, index) in processedTextList"
        :key="index"
        :class="[
          'base-text',
          `base-text__${getSize}`,
          `base-text__theme--${getTheme}`,
          { 'base-text__no-spacing': hasDefaultSpacingRemoved }
        ]"
        data-test-id="base-text"
      >
        {{ textLine }}
      </component>
    </template>
    <template v-else>
      <component
        :is="tag"
        :class="[
          'base-text',
          `base-text__${getSize}`,
          `base-text__theme--${getTheme}`,
          { 'base-text__no-spacing': hasDefaultSpacingRemoved }
        ]"
        v-bind="$attrs"
        data-test-id="base-text"
      >
        <slot />
      </component>
    </template>
  </div>
</template>

<script>
import { themes, typographySize } from "@/constants";
import {
  isValidTheme,
  isValidTypographySize
} from "@/utils/typography/typography.utils";

export default {
  name: "BaseText",
  props: {
    text: {
      type: [Array, String, Number],
      default: () => []
    },
    tag: {
      type: String,
      default: "p",
      validator: (value) =>
        ["p", "li", "span", "h1", "h2", "h3", "h4"].includes(value)
    },
    size: {
      type: String,
      default: typographySize.BODY_TEXT,
      validator: (value) => Object.values(typographySize).includes(value)
    },
    theme: {
      type: String,
      default: themes.NONE,
      validator: (value) => Object.values(themes).includes(value)
    },
    hasDefaultSpacingRemoved: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    processedTextList() {
      if (Array.isArray(this.text)) return this.text;
      return [this.text];
    },
    getSize() {
      return isValidTypographySize(this.size)
        ? this.size
        : typographySize.BODY_TEXT;
    },
    getTheme() {
      return isValidTheme(this.theme) ? this.theme : themes.NONE;
    }
  }
};
</script>

<style lang="scss" scoped>
.base-text {
  @include all-typography-options;

  &__no-spacing {
    margin: 0;
    padding: 0;
  }

  @each $theme, $primary-color, $secondary-color in $themes {
    &__theme--#{$theme} {
      @include get-color($secondary-color);
    }
  }
}
</style>
