<template>
  <div bg-light>
    <b-container class="ml-3 third-party-entity" fluid>
      <b-row>
        <b-col cols="12" class="text-left mt-3">
          <b-overlay :show="isLoading" rounded="sm">
            <ErrorPageTemplate
              class="third-party-details__body--screen-height"
              v-if="isErrorPresent"
              v-bind="errorPageOptions"
            />
            <div v-else>
              <ThirdPartyProfileHeader
                ref="profileHeader"
                :is-disabled="isThirdPartyArchived"
                :third-party-id="thirdPartyId"
                :third-party-data="thirdPartyDetails"
                :company-id="customCompanyId"
                :is-dnb-modal="isDNBFeatureEnabled"
              />
              <b-tabs v-model="$store.state.tabIndexProfilePage" small>
                <b-tab title="RISK SUMMARY" class="my-4">
                  <RiskSummaryTabComponent
                    :company-id="customCompanyId"
                    :is-disabled="isThirdPartyArchived"
                    :third-party-id="thirdPartyId"
                    :third-party-data="thirdPartyDetails"
                    :is-dnb-modal="isDNBFeatureEnabled"
                    :custom-attributes="customAttributes"
                    :has-u-b-o-feature="hasUBOFeature"
                    @click="setProfilePageTab($event)"
                  />
                </b-tab>
                <b-tab
                  v-if="hasESGExploreFeature"
                  :title="esgTexts.ESG_EXPLORE.toUpperCase()"
                  class="my-4"
                  lazy
                >
                  <ThirdPartyESGTemplate
                    :company-id="customCompanyId"
                    :third-party-id="thirdPartyId"
                  />
                </b-tab>
                <b-tab
                  title="DUE DILIGENCE"
                  :lazy="
                    $store.state.dueDiligenceRemediationMonitoringTabLazyLoad
                  "
                >
                  <DueDiligenceMonitoringTabComponent
                    :company-id="customCompanyId"
                    :is-disabled="isThirdPartyArchived"
                    :third-party-id="thirdPartyId"
                    :third-party-data="thirdPartyDetails"
                  />
                </b-tab>
                <b-tab
                  class="my-4"
                  title="QUESTIONNAIRES, POLICIES & COMMS"
                  lazy
                >
                  <QuestionnairesPoliciesTrainingTabComponent
                    :company-id="customCompanyId"
                    :is-disabled="isThirdPartyArchived"
                    :third-party-id="thirdPartyId"
                    :third-party-data="thirdPartyDetails"
                  />
                </b-tab>
                <b-tab class="my-4" title="TRAINING" lazy>
                  <ThirdPartyTrainingWrapper
                    :company-id="customCompanyId"
                    :third-party-id="thirdPartyId"
                  />
                </b-tab>
                <b-tab
                  v-if="hasDocumentAccess"
                  class="my-4"
                  title="DOCUMENTS"
                  :lazy="$store.state.documentsTabLazyLoad"
                >
                  <DocumentsTabComponent
                    :is-disabled="isThirdPartyArchived"
                    :company-id="customCompanyId"
                    :third-party-id="thirdPartyId"
                  />
                </b-tab>
                <b-tab
                  class="my-4"
                  title="DETAILS"
                  :lazy="$store.state.thirdPartyDetailsAssocTabLazyLoad"
                  data-test-id="third-party-profile__details-tab"
                >
                  <ThirdPartyDetails
                    :third-party-data="thirdPartyDetails"
                    :company-id="customCompanyId"
                    :is-disabled="isThirdPartyArchived"
                    :third-party-id="thirdPartyId"
                    :lazy-load="true"
                    :custom-attributes="customAttributes"
                    @get-my-details-third-party="fetchThirdPartyDetailWrapper"
                  />
                </b-tab>
                <b-tab
                  class="my-4"
                  title="CORPORATE DATA"
                  lazy
                  style="padding-right: 80px"
                >
                  <PremiumCorporateDataTemplate
                    :premium-corporate-data="premiumCorporateData"
                    :organisations-change-log-data="organisationsChangeLogData"
                    :is-dnb-disabled="!isDNBFeatureEnabled"
                    :is-dnb-verified="isThirdPartyDnbEnriched"
                    :third-party-data="thirdPartyDetails"
                  />
                </b-tab>
                <b-tab class="my-4" title="ULTIMATE BENEFICIAL OWNER" lazy>
                  <ThirdPartyUltimateBeneficialOwnersTab
                    :third-party="thirdPartyDetails"
                    :company-id="customCompanyId"
                  />
                </b-tab>
                <b-tab
                  class="my-4"
                  title="AUDIT TRAIL"
                  :lazy="$store.state.auditTrailTabLazyLoad"
                >
                  <AuditTrailTabComponent
                    :is-disabled="isThirdPartyArchived"
                    :company-id="customCompanyId"
                    :third-party-id="thirdPartyId"
                    :audit-trail-type="1"
                  />
                </b-tab>
              </b-tabs>
            </div>
          </b-overlay>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { hasPermission } from "@/utils";
import { thirdPartyService } from "@/services";
import { EventBus } from "@/event-bus";
import ThirdPartyProfileHeader from "@/organisms/ThirdPartyProfileHeader/ThirdPartyProfileHeader";
import ThirdPartyDetails from "@/organisms/ThirdPartyDetails/ThirdPartyDetails";
import RiskSummaryTabComponent from "@/components/eb2/thirdpartyProfilePageComponents/riskSummaryTab/riskSummaryTabComponent";
import DueDiligenceMonitoringTabComponent from "@/components/eb2/thirdpartyProfilePageComponents/dueDiligenceRemediation/dueDiligenceRemediationMonitoringTabComponent";
import QuestionnairesPoliciesTrainingTabComponent from "@/components/eb2/thirdpartyProfilePageComponents/questionnairesPoliciesTraining/questionnairesPoliciesTrainingTabComponent";
import DocumentsTabComponent from "@/components/eb2/thirdpartyProfilePageComponents/documentsTab/documentsTabComponent";
import AuditTrailTabComponent from "@/components/eb2/thirdpartyProfilePageComponents/auditTrailTab/auditTrailTabComponent";
import ThirdPartyESGTemplate from "@/templates/ThirdPartyESGTemplate/ThirdPartyESGTemplate";
import { mapActions, mapMutations, mapState, mapGetters } from "vuex";
import ThirdPartyTrainingWrapper from "@/organisms/ThirdPartyTrainingWrapper/ThirdPartyTrainingWrapper";
import {
  actionName,
  esgTexts,
  mutationName,
  thirdPartyTabHash,
  urls,
  getterName,
  featurePermissionsKeys
} from "@/constants";
import { Helper } from "@/helpers";
import PremiumCorporateDataTemplate from "@/templates/PremiumCorporateDataTemplate/PremiumCorporateDataTemplate";
import ThirdPartyUltimateBeneficialOwnersTab from "@/organisms/ThirdPartyUltimateBeneficialOwnersTab/ThirdPartyUltimateBeneficialOwnersTab";
import ErrorPageTemplate from "@/templates/ErrorPageTemplate/ErrorPageTemplate";
import { isEmpty } from "lodash";

export default {
  name: "ThirdPartyEntityPage",
  components: {
    ThirdPartyUltimateBeneficialOwnersTab,
    ThirdPartyProfileHeader,
    RiskSummaryTabComponent,
    DueDiligenceMonitoringTabComponent,
    QuestionnairesPoliciesTrainingTabComponent,
    DocumentsTabComponent,
    ThirdPartyDetails,
    AuditTrailTabComponent,
    ThirdPartyESGTemplate,
    PremiumCorporateDataTemplate,
    ThirdPartyTrainingWrapper,
    ErrorPageTemplate
  },
  data() {
    return {
      esgTexts,
      isLoadingDetail: false,
      thirdPartyId: null,
      customCompanyId: null,
      customAttributes: [],
      premiumCorporateData: {},
      isCorporateDataLoading: false,
      organisationsChangeLogData: [],
      isOrganisationsChangeLogDataLoading: false
    };
  },
  computed: {
    ...mapState({
      companyId: (state) => state.company.companyId,
      userId: (state) => state.user.userId,
      isAddThirdPartyModalNewVisible: (state) =>
        state.thirdParty.isAddThirdPartyModalNewVisible,
      companyFeatures: (state) => state.company.companyFeatures,
      isLoadingThirdPartyDetails: (state) =>
        state.thirdParty.isLoadingThirdPartyDetails,
      thirdPartyDetails: (state) => state.thirdParty.thirdPartyDetails,
      thirdPartyDetailsMessage: (state) =>
        state.thirdParty.thirdPartyDetailsMessage,
      platformRequestSupportLink: (state) =>
        state.platformSettings.platformRequestSupportLink
    }),
    ...mapGetters({
      hasUBOFeature: getterName.COMPANY.HAS_UBO_FEATURE,
      isDNBFeatureEnabled: getterName.COMPANY.IS_DNB_FEATURE_ENABLED,
      isThirdPartyDnbEnriched:
        getterName.THIRD_PARTY.IS_THIRD_PARTY_DNB_ENRICHED,
      isThirdPartyArchived: getterName.THIRD_PARTY.IS_THIRD_PARTY_ARCHIVED
    }),
    hasESGExploreFeature() {
      return hasPermission({
        key: featurePermissionsKeys.VIEW_ESG_THIRD_PARTIES
      });
    },
    hasDocumentAccess() {
      return hasPermission({ key: featurePermissionsKeys.ACCESS_TO_DOCUMENTS });
    },
    isLoading() {
      return this.isLoadingThirdPartyDetails || this.isLoadingDetail;
    },
    isErrorPresent() {
      return !!this.thirdPartyDetailsMessage;
    },
    getErrorMessages() {
      return [this.thirdPartyDetailsMessage];
    },
    errorPageOptions() {
      return {
        headingOptions: {
          text: "Incomplete data"
        },
        contentOptions: {
          text: this.getErrorMessages
        },
        iconOptions: {
          icon: "exclamation"
        }
      };
    }
  },
  watch: {
    isAddThirdPartyModalNewVisible(newValue) {
      if (!newValue) this.fetchThirdPartyDetailWrapper();
    }
  },
  created() {
    EventBus.on("reloadThirdPartyDetailProfile", () => {
      this.fetchThirdPartyDetailWrapper();
    });
  },
  mounted() {
    this.setProfilePageTab();
    this.setStoreProperties();

    const { compId, id } = this.$route.params;
    this.thirdPartyId = parseInt(id);

    this.customCompanyId = compId ? parseInt(compId) : this.companyId;

    this.fetchCustomAttributes();
    this.fetchThirdPartyDetailWrapper();
    this.fetchThirdPartyTrainingList(this.thirdPartyId);
  },
  beforeRouteLeave(_, __, next) {
    this.setThirdPartyUBOList([]);
    next();
  },
  beforeDestroy() {
    this.resetThirdPartyTrainingList();
    this.resetThirdPartyTrainingSummaryList();
    this.resetThirdPartyDetails();
    this.setThirdPartyDetailsMessage("");
  },
  methods: {
    isEmpty,
    ...mapActions({
      fetchCustomAttributesList:
        actionName.CUSTOM_ATTRIBUTES.FETCH_CUSTOM_ATTRIBUTES_LIST,
      fetchThirdPartyTrainingList:
        actionName.THIRD_PARTY.FETCH_THIRD_PARTY_TRAINING_LIST,
      fetchThirdPartyDetails: actionName.THIRD_PARTY.FETCH_THIRD_PARTY_DETAILS
    }),
    ...mapMutations({
      setTabIndexProfilePage: "setTabIndexProfilePage",
      setThirdPartyUBOList: mutationName.THIRD_PARTY.SET_THIRD_PARTY_UBO_LIST,
      resetThirdPartyTrainingList:
        mutationName.THIRD_PARTY.RESET_THIRD_PARTY_TRAINING_LIST,
      resetThirdPartyTrainingSummaryList:
        mutationName.THIRD_PARTY.RESET_THIRD_PARTY_TRAINING_SUMMARY_LIST,
      resetThirdPartyDetails:
        mutationName.THIRD_PARTY.RESET_THIRD_PARTY_DETAILS,
      setThirdPartyDetailsMessage:
        mutationName.THIRD_PARTY.RESET_THIRD_PARTY_DETAILS
    }),
    displayErrorToastMessage(status) {
      Helper.makeToast(
        this.$bvToast,
        "danger",
        `There is an unknown error with status of ${status}. Please contact support`
      );
    },
    async fetchCompanyCorporateData() {
      this.isCorporateDataLoading = true;
      try {
        const { data = {} } = await thirdPartyService.fetchCompanyCorporateData(
          this.customCompanyId,
          this.thirdPartyDetails?.duns_number
        );
        this.premiumCorporateData = data.response.data;
      } catch ({ status }) {
        this.displayErrorToastMessage(status);
      } finally {
        this.isCorporateDataLoading = false;
      }
    },
    async fetchOrganisationsChangeLogData() {
      this.isOrganisationsChangeLogDataLoading = true;
      try {
        const response =
          await thirdPartyService.fetchOrganisationsChangeLogData(
            this.thirdPartyDetails?.duns_number
          );

        this.organisationsChangeLogData = response?.data;
      } catch ({ status }) {
        this.displayErrorToastMessage(status);
      } finally {
        this.isOrganisationsChangeLogDataLoading = false;
      }
    },
    setProfilePageTab(hashTab = this.$route.hash) {
      if (hashTab === thirdPartyTabHash.ESG) {
        this.setTabIndexProfilePage(1);
      } else if (hashTab === thirdPartyTabHash.DUE_DILIGENCE) {
        this.setTabIndexProfilePage(2);
      } else if (hashTab === thirdPartyTabHash.QUESTIONNAIRE) {
        this.setTabIndexProfilePage(3);
      } else if (hashTab === thirdPartyTabHash.DETAILS) {
        this.setTabIndexProfilePage(5);
      } else if (hashTab === thirdPartyTabHash.UBO) {
        this.setTabIndexProfilePage(7);
      }
    },
    setStoreProperties() {
      this.$store.state.riskSummaryTabLazyLoad = true;
      this.$store.state.dueDiligenceRemediationMonitoringTabLazyLoad = true;
      this.$store.state.questionnairesPoliciesTrainingTabLazyLoad = true;
      this.$store.state.documentsTabLazyLoad = true;
      this.$store.state.thirdPartyDetailsAssocTabLazyLoad = true;
      this.$store.state.auditTrailTabLazyLoad = true;
      this.$store.state.contactsTabLazyLoad = true;
      this.$store.state.associatesTabLazyLoad = true;
    },
    async fetchThirdPartyDetailWrapper() {
      await this.fetchThirdPartyDetails(this.thirdPartyId);
      if (!this.isEmpty(this.thirdPartyDetails)) {
        return false;
      }

      if (
        !!parseInt(this.thirdPartyDetails.is_ubo) &&
        this.thirdPartyDetails?.parent_third_party_id &&
        this.thirdPartyDetails?.member_id
      ) {
        this.$router.push(
          urls.ULTIMATE_BENEFICIAL_OWNERS_PROFILE(
            this.thirdPartyDetails?.parent_third_party_id,
            this.thirdPartyDetails?.member_id
          )
        );
      } else if (this.isThirdPartyDnbEnriched) {
        this.fetchCompanyCorporateData();
        this.fetchOrganisationsChangeLogData();
      }
    },
    async fetchCustomAttributes() {
      try {
        this.customAttributes = [];
        this.isLoadingDetail = true;

        const { response = [] } = await this.fetchCustomAttributesList(
          this.makeCustomAttributesPostObject()
        );

        this.customAttributes = response;
      } catch (error) {
        return [error];
      } finally {
        this.isLoadingDetail = false;
      }
    },
    makeCustomAttributesPostObject() {
      return {
        sort: `tp_sequence|asc`,
        comp_id: this.companyId,
        is_active: 1
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.third-party-entity {
  height: calc(100vh - 128px);
  overflow: hidden auto;
}

:deep(.nav-tabs) {
  border: none;
}
</style>
