const countries = {
  CANADA: {
    name: "Canada",
    code: "ca"
  },
  UNITED_STATES: {
    name: "United States",
    code: "us"
  },
  UNITED_ARAB_EMIRATES: {
    name: "United Arab Emirates",
    code: "ae"
  }
};

const countriesWithPrefix = [
  "Bahamas",
  "Central African Republic",
  "Comoros",
  "Czech Republic",
  "Democratic Republic of the Congo",
  "Dominican Republic",
  "Gambia",
  "Maldives",
  "Marshall Islands",
  "Netherlands",
  "Philippines",
  "Solomon Islands",
  "United Arab Emirates",
  "United Kingdom",
  "United States"
];

export { countries, countriesWithPrefix };
