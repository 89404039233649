<template>
  <div class="white-label">
    <div>
      <b-overlay :show="isLoading" rounded="sm">
        <div class="white-label__holder">
          <div class="text-left pl-3">
            <BaseText
              :text="operationFeedback"
              theme="success-inverse"
              data-test-id="white-label__operations-feedback"
            />
            <InputErrorMessage :error="errorMessagesApi" />
            <h3 class="text-left white-label__title">System Branding</h3>
            <h5 class="mb-5 white-label__sub-title">Logo's</h5>
            <p class="mb-3"><b>Login page & report logo: </b></p>
            <p class="mb-0">This will appear on a white background.</p>
            <p class="mb-0">
              Dimensions: We recommended a logo size of 250px width by 75px
              height:
            </p>
            <p class="mb-0">File size: 200KB</p>
            <p class="mb-0">File formats: .jpg .png .jpeg</p>
            <ImageUpload
              :image-type="imageType.PLATFORM_LOGO"
              :path="platformLogo"
              @update-image="onUpdateLogo"
            />

            <p class="mb-3"><b> Main header logo: </b></p>
            <p class="mb-0">
              This logo will appear in the header and appear over your primary
              colour (set below)
            </p>
            <p class="mb-0">
              Dimensions: We recommended a logo size of 250px width by 75px
              height:
            </p>
            <p class="mb-0">File size: 200KB</p>
            <p class="mb-0">File formats: .jpg .png .jpeg</p>
            <ImageUpload
              :image-type="imageType.HEADER_LOGO"
              :path="headerLogo"
              @update-image="onUpdateLogo"
            />
          </div>
          <hr class="white-label__separator" />
          <div class="text-left pl-3">
            <h5 class="mb-5 white-label__sub-title">Favicon</h5>
            <p class="mb-0">
              Dimensions: We recommended a logo size of 32px width by 32px
              height:
            </p>
            <p>File formats: .png .gif .ico .svg</p>
            <ImageUpload
              :image-type="imageType.FAV_ICON"
              :path="favicon"
              @update-image="onUpdateLogo"
            />
          </div>
          <hr class="white-label__separator" />
          <div class="text-left pl-3">
            <h5 class="mb-5 white-label__sub-title">Colours</h5>
            <p class="mb-3 white-label__text">
              Here you can set the main colour scheme for the system.
            </p>
            <div class="white-label__colors">
              <p class="white-label__colors-type"><b>Primary colour:</b></p>
              <div class="white-label__colors-holder">
                <p class="white-label__colors-text">
                  {{ primaryColor }}
                </p>
                <InputColorPicker
                  class="white-label__color-picker"
                  :value="primaryColor"
                  @change="onChangePrimaryColor"
                />
              </div>
            </div>
            <div class="mb-3 white-label__colors-instructions">
              <p class="mb-0">
                This will be used for the background colour of the main
                navigation bar as well as buttons and underlines.
              </p>
              <p>This will also be used in your reports.</p>
            </div>
            <div class="mb-3 white-label__colors-example">
              <div class="white-label__colors-example-holder">
                <p class="white-label__colors-example-text">
                  Example Navigation Bar
                </p>
                <div class="white-label__colors-example-navigation-bar">
                  <b-img
                    class="mt-2 ml-2 white-label__colors-example-image"
                    :src="headerLogo"
                  />
                </div>
              </div>
              <div class="white-label__colors-example-holder">
                <p class="white-label__colors-example-text">Example Button</p>
                <div>
                  <b-button class="white-label__colors-example-button">
                    Login
                  </b-button>
                </div>
              </div>
              <div class="white-label__colors-example-holder mr-4">
                <p class="white-label__colors-example-text">Example Report</p>
                <div>
                  <b class="white-label__colors-example-report-text">
                    EXECUTIVE SUMMARY
                  </b>
                  <hr class="white-label__colors-example-report" />
                </div>
              </div>
            </div>
            <div class="white-label__colors">
              <p class="white-label__colors-type">
                <b>Navigation bar text and main button text colour:</b>
              </p>
              <div class="white-label__colors-holder">
                <p class="white-label__colors-text">
                  {{ navigationBarTextColor }}
                </p>
                <InputColorPicker
                  class="white-label__color-picker"
                  :value="navigationBarTextColor"
                  @change="onChangeTextColor"
                />
              </div>
            </div>
            <p class="mb-3 white-label__text">
              This is the colour of the text in your navigation bar and on your
              main buttons.
            </p>
          </div>
          <hr class="white-label__separator" />
          <div class="white-label__links text-left pl-3">
            <h5 class="white-label__font-weight_600">Configure Links</h5>
            <p class="mb-5 white-label__font-weight_400">
              Here you can configure a number of links on the platform
            </p>
            <template v-if="!isLoading">
              <Input
                id="tncLink"
                label="Terms and conditions link"
                name="tncLink"
                placeholder="Update Link"
                :value="tncLink"
                :error="validationMessage.tncLink"
                @change="onChangeInput('tncLink', $event)"
              />
              <Input
                id="privacyCookieLink"
                label="Privacy and Cookie Policy"
                name="privacyCookieLink"
                placeholder="Update Link"
                :value="privacyCookieLink"
                :error="validationMessage.privacyCookieLink"
                @change="onChangeInput('privacyCookieLink', $event)"
              />
              <Input
                id="supportEmailLink"
                label="Support Email"
                name="supportEmailLink"
                placeholder="Email address"
                :value="supportEmailLink"
                :error="validationMessage.supportEmailLink"
                @change="onChangeInput('supportEmailLink', $event)"
              />
              <Input
                id="releaseNoteLink"
                label="Release Note link"
                name="releaseNoteLink"
                placeholder="Update link"
                :value="releaseNoteLink"
                :error="validationMessage.releaseNoteLink"
                @change="onChangeInput('releaseNoteLink', $event)"
              >
                <template v-slot:description>
                  <span> (available from the main menu) </span>
                </template>
              </Input>
              <Input
                id="knowledgeCenterLink"
                label="Knowledge Center link"
                name="knowledgeCenterLink"
                placeholder="Update link"
                :value="knowledgeCenterLink"
                :error="validationMessage.knowledgeCenterLink"
                @change="onChangeInput('knowledgeCenterLink', $event)"
              >
                <template v-slot:description>
                  <span>
                    (available from the main menu & also used for contextual
                    help in the application)
                  </span>
                  <b-img
                    src="/images/questionmarkimage.png"
                    class="label__label-image"
                  />
                </template>
              </Input>
              <Input
                id="requestSupportLink"
                label="Request Support link"
                name="requestSupportLink"
                placeholder="Update link"
                :value="requestSupportLink"
                :error="validationMessage.requestSupportLink"
                @change="onChangeInput('requestSupportLink', $event)"
              >
                <template v-slot:description>
                  <span>(available from the main menu)</span>
                </template>
              </Input>
            </template>
          </div>
          <hr class="white-label__separator" />
          <div class="white-label__configuration text-left pl-3">
            <h5 class="white-label__sub-title">Report Configuration</h5>
            <template v-if="!isLoading">
              <Input
                id="companyName"
                label="Legal Company Name"
                name="companyName"
                placeholder="Add text"
                :value="companyName"
                :error="validationMessage.companyName"
                @change="onChangeInput('companyName', $event)"
              >
                <template v-slot:details>
                  <p>
                    The name of the company that will be referenced to the brand
                    in the report
                  </p>
                  <p>
                    <span class="white-label__configuration-example">
                      example:
                    </span>
                    ethiXbase 360 Pte.Ltd
                  </p>
                </template>
              </Input>
              <Input
                id="companyNameAbbreviation"
                label="Abbreviated Company Name"
                name="companyNameAbbreviation"
                placeholder="Add text"
                :value="companyNameAbbreviation"
                :error="validationMessage.companyNameAbbreviation"
                @change="onChangeInput('companyNameAbbreviation', $event)"
              >
                <template v-slot:details>
                  <p>
                    The name of the company that will be referenced when
                    referring to the brand in the report.
                  </p>
                  <p>
                    <span class="white-label__configuration-example">
                      example:
                    </span>
                    ethiXbase
                  </p>
                </template>
              </Input>
              <Textarea
                id="companyAddress"
                label="Company Address"
                name="companyAddress"
                placeholder="Add text"
                :value="companyAddress"
                :error="validationMessage.companyAddress"
                @change="(_, $event) => onChangeInput('companyAddress', $event)"
              >
                <template v-slot:details>
                  <p>The company address that will be used in the report</p>
                </template>
              </Textarea>
              <Input
                id="companyWebsite"
                label="Company Website"
                name="companyWebsite"
                placeholder="Add link"
                :value="companyWebsite"
                :error="validationMessage.companyWebsite"
                @change="onChangeInput('companyWebsite', $event)"
              >
                <template v-slot:details>
                  <p>
                    The link to the company website that will be referenced in
                    the report
                  </p>
                </template>
              </Input>
              <Textarea
                id="legalDisclaimer"
                label="Legal disclaimer"
                name="legalDisclaimer"
                placeholder="Add text"
                :value="legalDisclaimer"
                :error="validationMessage.legalDisclaimer"
                @change="
                  (_, $event) => onChangeInput('legalDisclaimer', $event)
                "
              >
                <template v-slot:details>
                  <p>
                    The legal disclaimer that will appear in the report where
                    applicable.
                  </p>
                </template>
              </Textarea>
              <Input
                id="copyrightNotice"
                label="Copyright notice"
                name="copyrightNotice"
                placeholder="Add text"
                :value="copyrightNotice"
                :error="validationMessage.copyrightNotice"
                @change="onChangeInput('copyrightNotice', $event)"
              >
                <template v-slot:details>
                  <p>
                    The text that will appear where a copyright notice is
                    required
                  </p>
                </template>
              </Input>
            </template>
          </div>
        </div>
        <div class="white-label__submit">
          <b-btn
            class="mb-2 white-label__submit-button"
            variant="outline-danger"
            @click="onResetPlatformSettings"
          >
            Reset
          </b-btn>
          <b-btn
            class="mb-2 white-label__submit-button"
            variant="outline-success"
            @click="onUpdatePlatformSettingsWrapper"
          >
            Save
          </b-btn>
        </div>
      </b-overlay>
    </div>
  </div>
</template>

<script>
import InputColorPicker from "vue-native-color-picker";
import ImageUpload from "@/components/eb2/brandingAndLinks/ImageUpload";
import Input from "@/molecules/Input/Input";
import Textarea from "@/molecules/Textarea/Textarea";
import InputErrorMessage from "@/molecules/InputErrorMessage/InputErrorMessage";
import BaseText from "@/atoms/BaseText/BaseText";
import {
  isValidResponse,
  isValidUrl,
  isValidMailToLink,
  isValidLink,
  isValidEmail,
  isRequired
} from "@/utils";
import {
  imageType,
  validationKeys,
  errorMessages,
  errorLinkMessages,
  actionName,
  platformSettingsKeysAPIDTO
} from "@/constants";
import { mapState, mapActions } from "vuex";

export default {
  name: "BrandingAndLinksSettings",
  components: {
    InputColorPicker,
    ImageUpload,
    Input,
    Textarea,
    InputErrorMessage,
    BaseText
  },
  data() {
    return {
      imageType,
      inputModelList: [
        "releaseNoteLink",
        "privacyCookieLink",
        "supportEmailLink",
        "knowledgeCenterLink",
        "requestSupportLink",
        "tncLink",
        "companyWebsite",
        "companyName",
        "companyAddress",
        "legalDisclaimer",
        "copyrightNotice",
        "companyNameAbbreviation"
      ],
      operationFeedback: "",
      errorMessagesApi: "",
      platformLogo: "",
      headerLogo: "",
      favicon: "",
      primaryColor: "#42dc98",
      navigationBarTextColor: "#ffffff",
      releaseNoteLink: null,
      requestSupportLink: null,
      knowledgeCenterLink: null,
      privacyCookieLink: null,
      supportEmailLink: null,
      tncLink: null,
      companyName: null,
      companyNameAbbreviation: null,
      companyAddress: null,
      companyWebsite: null,
      legalDisclaimer: null,
      copyrightNotice: null,
      isLoading: false,
      validationMessage: this.makeValidationMessages()
    };
  },
  computed: {
    ...mapState({
      platformSettings: (state) => state.platformSettings
    })
  },
  watch: {
    platformSettings() {
      this.setPlatformSettings(this.platformSettings);
    }
  },
  mounted() {
    this.setPlatformSettings(this.platformSettings);
  },
  methods: {
    ...mapActions({
      updatePlatformSettings:
        actionName.PLATFORM_SETTINGS.UPDATE_PLATFORM_SETTINGS
    }),
    onChangeInput(inputName, val) {
      this[inputName] = val;
      this.validationMessage[inputName] = "";
    },
    onUpdateLogo(logoTypeVal, image) {
      this[logoTypeVal] = image;
    },
    setPlatformSettings(platformSettings) {
      this.primaryColor = platformSettings.platformPrimaryColor;
      this.navigationBarTextColor = platformSettings.platformSecondaryColor;
      this.releaseNoteLink = platformSettings.platformReleaseNoteLink;
      this.privacyCookieLink = platformSettings.platformPrivacyPolicyLink;
      this.supportEmailLink = platformSettings.platformSupportEmail;
      this.knowledgeCenterLink = platformSettings.platformKnowledgeCentreLink;
      this.requestSupportLink = platformSettings.platformRequestSupportLink;
      this.tncLink = platformSettings.platformTermsAndConditionsLink;
      this.platformLogo = platformSettings.platformLogo;
      this.headerLogo = platformSettings.platformMainHeaderLogo;
      this.favicon = platformSettings.platformFavIcon;
      this.companyName = platformSettings.platformName;
      this.companyAddress = platformSettings.platformBusinessAddress;
      this.companyWebsite = platformSettings.platformWebUrl;
      this.legalDisclaimer = platformSettings.platformLegalDisclaimer;
      this.copyrightNotice = platformSettings.platformCopyrightNotice;
      this.companyNameAbbreviation =
        platformSettings.platformRegisteredBusinessName;
      document.documentElement.style.setProperty(
        "--demo-primary-color",
        this.primaryColor
      );
      document.documentElement.style.setProperty(
        "--demo-navigation-bar-text-color",
        this.navigationBarTextColor
      );
    },
    onChangePrimaryColor(val) {
      this.primaryColor = val;
      document.documentElement.style.setProperty(
        "--demo-primary-color",
        this.primaryColor
      );
    },
    onChangeTextColor(val) {
      this.navigationBarTextColor = val;
      document.documentElement.style.setProperty(
        "--demo-navigation-bar-text-color",
        this.navigationBarTextColor
      );
    },
    makePlatformSettingsPostObj() {
      return {
        [platformSettingsKeysAPIDTO.PLATFORM_LOGO]: this.platformLogo,
        [platformSettingsKeysAPIDTO.PLATFORM_MAIN_HEADER_LOGO]: this.headerLogo,
        [platformSettingsKeysAPIDTO.PLATFORM_FAV_ICON]: this.favicon,
        [platformSettingsKeysAPIDTO.PLATFORM_PRIMARY_COLOR]: this.primaryColor,
        [platformSettingsKeysAPIDTO.PLATFORM_SECONDARY_COLOR]:
          this.navigationBarTextColor,
        [platformSettingsKeysAPIDTO.PLATFORM_RELEASE_NOTE_LINK]:
          this.releaseNoteLink,
        [platformSettingsKeysAPIDTO.PLATFORM_PRIVACY_POLICY_LINK]:
          this.privacyCookieLink,
        [platformSettingsKeysAPIDTO.PLATFORM_SUPPORT_EMAIL]:
          this.supportEmailLink,
        [platformSettingsKeysAPIDTO.PLATFORM_KNOWLEDGE_CENTRE_LINK]:
          this.knowledgeCenterLink,
        [platformSettingsKeysAPIDTO.PLATFORM_REQUEST_SUPPORT_LINK]:
          this.requestSupportLink,
        [platformSettingsKeysAPIDTO.PLATFORM_TERMS_AND_CONDITIONS_LINK]:
          this.tncLink,
        [platformSettingsKeysAPIDTO.PLATFORM_NAME]: this.companyName,
        [platformSettingsKeysAPIDTO.PLATFORM_REGISTERED_BUSINESS_NAME]:
          this.companyNameAbbreviation,
        [platformSettingsKeysAPIDTO.PLATFORM_BUSINESS_ADDRESS]:
          this.companyAddress,
        [platformSettingsKeysAPIDTO.PLATFORM_WEB_URL]: this.companyWebsite,
        [platformSettingsKeysAPIDTO.PLATFORM_LEGAL_DISCLAIMER]:
          this.legalDisclaimer,
        [platformSettingsKeysAPIDTO.PLATFORM_COPYRIGHT_NOTICE]:
          this.copyrightNotice
      };
    },
    onUpdatePlatformSettingsWrapperFailure({ status = "" } = {}) {
      const description = status
        ? ` There is an error with status ${status}. `
        : "";
      this.errorMessagesApi = `Failed to update platform settings.${description}Please try again or contact support.`;
    },
    async onUpdatePlatformSettingsWrapper() {
      this.clearValidationMessages();
      if (this.validateInputs()) {
        try {
          this.isLoading = true;
          this.operationFeedback = "";
          this.errorMessagesApi = "";
          const response = await this.updatePlatformSettings(
            this.makePlatformSettingsPostObj()
          );
          if (isValidResponse(response)) {
            this.operationFeedback = "Platform settings updated successfuly.";
          } else {
            this.onUpdatePlatformSettingsWrapperFailure();
          }
        } catch (error) {
          this.onUpdatePlatformSettingsWrapperFailure(error?.response);
        } finally {
          this.isLoading = false;
        }
      }
    },
    onResetPlatformSettings() {
      this.setPlatformSettings(this.platformSettings);
    },
    validateInputs() {
      let isValid = true;

      if (!isRequired(this.tncLink)) {
        this.validationMessage.tncLink = errorLinkMessages[validationKeys.REQUIRED];
        isValid = false;
      } else if (!isValidMailToLink(this.tncLink) || !isValidUrl(this.tncLink) || !isValidLink(this.tncLink)) {
        this.validationMessage.tncLink = errorLinkMessages[validationKeys.IS_VALID_URL];
        isValid = false;
      }

      if (!isRequired(this.privacyCookieLink)) {
        this.validationMessage.privacyCookieLink = errorLinkMessages[validationKeys.REQUIRED];
        isValid = false;
      } else if (!isValidMailToLink(this.privacyCookieLink) || !isValidUrl(this.privacyCookieLink) || !isValidLink(this.privacyCookieLink)) {
        this.validationMessage.privacyCookieLink = errorLinkMessages[validationKeys.IS_VALID_URL];
        isValid = false;
      }

      if (!isRequired(this.supportEmailLink)) {
        this.validationMessage.supportEmailLink = errorLinkMessages[validationKeys.REQUIRED];
        isValid = false;
      } else if (!isValidEmail(this.supportEmailLink)) {
        this.validationMessage.supportEmailLink = errorLinkMessages[validationKeys.IS_VALID_MAIL_TO_LINK];
        isValid = false;
      }

      if (!isRequired(this.releaseNoteLink)) {
        this.validationMessage.releaseNoteLink = errorLinkMessages[validationKeys.REQUIRED];
        isValid = false;
      } else if (!isValidMailToLink(this.releaseNoteLink) || !isValidUrl(this.releaseNoteLink) || !isValidLink(this.releaseNoteLink)) {
        this.validationMessage.releaseNoteLink = errorLinkMessages[validationKeys.IS_VALID_URL];
        isValid = false;
      }

      if (!isRequired(this.knowledgeCenterLink)) {
        this.validationMessage.knowledgeCenterLink = errorLinkMessages[validationKeys.REQUIRED];
        isValid = false;
      } else if (!isValidMailToLink(this.knowledgeCenterLink) || !isValidUrl(this.knowledgeCenterLink) || !isValidLink(this.knowledgeCenterLink)) {
        this.validationMessage.knowledgeCenterLink = errorLinkMessages[validationKeys.IS_VALID_URL];
        isValid = false;
      }

      if (!isRequired(this.requestSupportLink)) {
        this.validationMessage.requestSupportLink = errorLinkMessages[validationKeys.REQUIRED];
        isValid = false;
      } else if (!isValidUrl(this.requestSupportLink) || !isValidLink(this.requestSupportLink) || !isValidMailToLink(this.requestSupportLink)) {
        this.validationMessage.requestSupportLink = errorLinkMessages[validationKeys.IS_VALID_URL];
        isValid = false;
      }

      if (!isRequired(this.companyName)) {
        this.validationMessage.companyName = errorMessages.companyName[validationKeys.REQUIRED];
        isValid = false;
      }

      if (!isRequired(this.companyAddress)) {
        this.validationMessage.companyAddress = errorMessages.companyAddress[validationKeys.REQUIRED];
        isValid = false;
      }

      if (!isRequired(this.companyNameAbbreviation)) {
        this.validationMessage.companyNameAbbreviation = errorMessages.companyNameAbbreviation[validationKeys.REQUIRED];
        isValid = false;
      }

      if (!isRequired(this.companyWebsite)) {
        this.validationMessage.companyWebsite = errorMessages.companyWebsite[validationKeys.REQUIRED];
        isValid = false;
      } else if (!isValidLink(this.companyWebsite)) {
        this.validationMessage.companyWebsite = errorMessages.companyWebsite[validationKeys.IS_VALID_LINK];
        isValid = false;
      }

      if (!isRequired(this.legalDisclaimer)) {
        this.validationMessage.legalDisclaimer = errorMessages.legalDisclaimer[validationKeys.REQUIRED];
        isValid = false;
      }

      if (!isRequired(this.copyrightNotice)) {
        this.validationMessage.copyrightNotice = errorMessages.copyrightNotice[validationKeys.REQUIRED];
        isValid = false;
      }

      return isValid;
    },
    makeValidationMessages() {
      return {
        privacyCookieLink: "",
        releaseNoteLink: "",
        supportEmailLink: "",
        knowledgeCenterLink: "",
        requestSupportLink: "",
        tncLink: "",
        companyName: "",
        companyAddress: "",
        companyNameAbbreviation: "",
        companyWebsite: "",
        legalDisclaimer: "",
        copyrightNotice: ""
      }
    },
    clearValidationMessages() {
      this.validationMessage = this.makeValidationMessages();
    },
}
};
</script>

<style scoped lang="scss">
.white-label {
  .breadcrumb {
    margin-bottom: 0;
  }

  &__separator {
    border: 1px solid $light-silver;
  }

  & .white-label__color-picker {
    border: 1px solid;
  }

  &__holder {
    overflow-y: auto;
    padding-right: 20px;
    height: calc(100vh - 225px);
  }

  &__title {
    font-weight: 700;
  }

  &__sub-title {
    font-weight: 600;
  }

  &__text {
    font-weight: 400;
  }

  &__colors {
    display: flex;
    justify-content: space-between;

    &-holder {
      display: flex;
      margin-right: 10px;
    }

    &-text {
      margin-right: 50px;
      font-weight: 600;
    }

    &-instructions {
      display: flex;
      flex-direction: column;
      font-weight: 400;
    }
    &-example {
      display: flex;
      justify-content: flex-start;

      > div {
        margin-left: 50px;

        &:first-child {
          margin-left: 0;
        }
      }

      &-text {
        font-weight: 700;
        font-size: 20px;
      }

      &-type {
        font-weight: 600;
      }

      &-holder {
        display: flex;
        flex-direction: column;
        margin-right: 10px;
        align-items: flex-start;
        margin-bottom: 25px;
      }

      &-image {
        max-width: 120px;
        max-height: 40px;
      }

      &-button {
        width: 100px;
        background-color: var(--demo-primary-color);
        color: var(--demo-navigation-bar-text-color);
      }

      &-report {
        border: 2px solid var(--demo-primary-color);
        width: 200px;

        &-text {
          font-size: 12px;
        }
      }

      &-navigation-bar {
        background-color: var(--demo-primary-color);
        height: 50px;
        width: 255px;
      }
    }
  }

  &__configuration {
    &-example {
      font-weight: 600;
    }

    &-text {
      font-weight: 400;
    }

    &-holder {
      display: flex;
      align-items: baseline;
      justify-content: space-between;
    }

    &-form {
      width: 50%;
      padding-right: 30px;
    }

    & .textarea,
    & .input {
      display: flex;
    }
  }

  &__submit {
    border-top: 2px solid $black;
    text-align: right;
    padding: 20px 33px 20px 0;

    &-button {
      width: 80px;
      margin: 0 5px;
    }
  }
}
</style>
