import { widgetsService } from "@/services";
import { mutationName } from "@/constants";

export const actions = {
  async fetchWidgetList({ commit, rootState }) {
    const { tenantId, companyId } = rootState.company;
    try {
      commit(mutationName.WIDGET.SET_IS_WIDGET_LIST_LOADING, true, {
        root: true
      });

      const data = await widgetsService.fetchWidgetList({
        tenantId,
        companyId
      });

      commit(mutationName.WIDGET.SET_WIDGET_LIST, data, {
        root: true
      });

      return data;
    } catch (error) {
      return error;
    } finally {
      commit(mutationName.WIDGET.SET_IS_WIDGET_LIST_LOADING, false, {
        root: true
      });
    }
  }
};
